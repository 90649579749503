import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const PROJECT_SHEET_LISTING = gql`
  query ProjectResourceSheet($filters: ProjectsFilterInput) {
    projectResourceSheet(filters: $filters) {
      count
      projects {
        id
        name
        clientName
        integrations {
          referenceId
          referenceName
        }
        tags
        createdOn
        creator {
          firstName
          lastName
        }
        status
        endDate
        description
        projectExcel {
          title
          excelSheetUrl
        }
      }
    }
  }
`;
