import { React } from 'react';

function LogDescription({ data, item }) {
  const getData = () => {
    switch (item) {
      case 'tags':
        return data?.join(', ');
      case 'integrations':
        return data?.map((items, index) => (
          <div key={`key=${index + 1}`}>
            <span>Reference Id: {items?.referenceId}</span>
            <br />
            <span>Reference Name: {items?.referenceName}</span>
          </div>
        ));
      case 'skills':
        return data?.map((items, index) => (
          <div key={`key=${index + 1}`}>
            <span>SkillName: {items?.skillName}</span>
            <br />
            <span>SkillValue: {items?.skillValue?.join(', ')}</span>
          </div>
        ));
      case 'creator':
        return Object?.keys(data)?.map((label, index) => (
          <div className="d-flex" key={`record-${index + 1}`}>
            <span>
              {label}: {data?.[label]}
            </span>
          </div>
        ));
      default:
        return typeof data === 'string' ? data : '-';
    }
  };
  return <div>{getData()}</div>;
}

export default LogDescription;
