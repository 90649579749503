import { Button, Form, Modal } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import CommonSelect from '../../../components/CommonSelect';
import { USERS } from '../../assetsManagement/graphql/Queries';
import { ACTIVE_PROJECTS } from '../graphql/Queries';

const UserModal = ({
  userModalVisible,
  handleOk,
  handleCancel,
  onFinish,
  form,
  project,
}) => {
  const [searchStrings, setSearchStrings] = useState([]);
  const [projectSearch, setProjectSearch] = useState('');

  useEffect(() => {
    if (project) {
      form?.setFieldsValue({
        projectName: project?.title,
      });
    }
  }, [project]);

  const handleSearch = debounce((value) => {
    setSearchStrings(value?.toLowerCase());
  }, 500);

  const handleProjectSearch = debounce((value) => {
    setProjectSearch(value?.toLowerCase());
  }, 500);

  const handleCategoryChange = () => {
    setSearchStrings([]);
  };

  const handleProjectChange = () => {
    setProjectSearch('');
  };

  return (
    <Modal
      title="Add User"
      open={userModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      maskClosable={false}
    >
      <Form onFinish={onFinish} form={form}>
        <Form.Item
          label="Select Project"
          name="projectName"
          rules={[{ required: true, message: 'Please Select The Root Title!' }]}
        >
          <CommonSelect
            placeholder="Select Project"
            showSearch
            query={ACTIVE_PROJECTS}
            onSearch={handleProjectSearch}
            onChange={handleProjectChange}
            responsePath="projectsQuarterView.projects"
            valuePath="id"
            labelPath="name"
            fetchPolicy="network-only"
            value={projectSearch}
            variables={{ filters: { searchString: projectSearch } }}
            useEffectDeps={[projectSearch]}
            virtual={false}
            disabled={project?.title}
          />
        </Form.Item>
        <Form.Item
          name="userName"
          label="Select User"
          rules={[
            {
              required: true,
              message: 'Please Choose Employee Name!',
            },
          ]}
        >
          <CommonSelect
            mode="tags"
            placeholder="Select User"
            showSearch
            query={USERS}
            onSearch={handleSearch}
            onChange={handleCategoryChange}
            responsePath="usersAdminAssetsListing.users"
            valuePath="id"
            labelPath="displayName"
            fetchPolicy="network-only"
            value={searchStrings}
            variables={{ filters: { searchStrings } }}
            useEffectDeps={[searchStrings]}
            virtual={false}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add User
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserModal;
