import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import {
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import Events from './Events';
import EventAddEdit from './components/EventAddEdit';
import './event.less';

const EventWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.EVENT_MANAGEMENT}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <Events {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}/create`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.EVENT_MANAGEMENT}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <EventAddEdit {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}/edit/:eventId`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.EVENT_MANAGEMENT}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <EventAddEdit {...props} isEdit />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}/duplicate/:eventId`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.EVENT_MANAGEMENT}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <EventAddEdit {...props} isDuplicate />
        </AccessControl>
      )}
    />
  </Switch>
);

export default EventWrapper;
