import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Switch,
  Tag,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  COLORS,
  DEFAULT_DATE_FORMAT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import LoaderComponent from '../../components/LoaderComponent';
import VerifyPermissions from '../../components/VerifyPermissions';
import history from '../../historyData';
import { ALL_TAGS } from '../users/graphql/Queries';
import AddMembers from './components/AddMembers';
import { EDIT_PROJECT } from './graphql/Mutations';
import { PROJECT } from './graphql/Queries';

function ViewProject(props) {
  const {
    location: { state: propsState },
  } = props;
  const { initialDateFilter, initialFilter, paginationFilter } =
    propsState || {};

  const pathName = initialDateFilter?.pathname;
  const [form] = Form?.useForm();
  const { projectId } = useParams();
  const [editable, setEditable] = useState(true);
  const [editableButton, setEditableButton] = useState(false);
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const [search, setSearch] = useState('');
  const [tagSearch, setTagSearch] = useState('');
  const isUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.PROJECTS,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });
  const isDeletePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.PROJECTS,
    allowedPermissions: [PERMISSION_OBJECT?.delete],
  });
  const inputTagWidth =
    inputValue?.length > 80 ? 'expanding-tag-width' : 'default-tag-width';

  const handleRedirect = () => {
    if (pathName) {
      history?.push({
        pathname: pathName,
        state: {
          ...initialDateFilter,
        },
      });
    } else {
      history?.push({
        pathname: `${ROUTES_MODULES_KEY?.PROJECTS}`,
        state: {
          ...initialFilter,
          ...paginationFilter,
        },
      });
    }
  };

  const [executeQuery, { loading, data }] = useLazyQuery(PROJECT, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [editProject] = useMutation(EDIT_PROJECT, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      handleRedirect();
    },
    onError() {},
    refetchQueries: [
      {
        query: PROJECT,
        variables: {
          id: projectId,
        },
      },
    ],
  });

  useEffect(() => {
    executeQuery({
      variables: {
        id: projectId,
      },
    });
  }, [projectId]);

  useEffect(() => {
    if (data?.project) {
      form?.setFieldsValue({
        name: data?.project?.name,
        clientName: data?.project?.clientName,
        status: data?.project?.status === 'ACTIVE',
        createdOn: moment(data?.project?.createdOn)?.format(
          DEFAULT_DATE_FORMAT,
        ),
        integrations: data?.project?.integrations,
        projectExcel: data?.project?.projectExcel,
        endDate: data?.project?.endDate ? dayjs(data?.project?.endDate) : null,
        description: data?.project?.description,
      });
      setTags(data?.project?.tags ? [...data?.project?.tags] : []);
    }
  }, [data]);

  const debouncedUpdateValue = useCallback(
    debounce((newValue) => {
      setTagSearch(newValue?.toLowerCase());
    }, 500),
    [],
  );

  const updateSearchValue = (newValue) => {
    setSearch(newValue?.toLowerCase());
  };

  const handleTagSearch = (searchText) => {
    debouncedUpdateValue(searchText);
    updateSearchValue(searchText);
  };

  const onFinish = async (values) => {
    await form?.validateFields();
    await editProject({
      variables: {
        input: {
          name: values?.name,
          clientName: values?.clientName,
          status: values?.status ? 'ACTIVE' : 'INACTIVE',
          integrations: values?.integrations,
          projectExcel: values?.projectExcel,
          tags:
            selectedItems?.length > 0 ? [...tags, ...selectedItems] : [...tags],
          endDate: values?.endDate,
          description: values?.description,
        },
        where: {
          id: projectId,
        },
      },
    });
    setEditableButton(false);
    setEditable(false);
  };

  const handleBack = () => {
    if (pathName) {
      history?.push({
        pathname: pathName,
        state: {
          ...initialDateFilter,
        },
      });
    } else {
      history?.push({
        pathname: `${ROUTES_MODULES_KEY?.PROJECTS}`,
        state: {
          ...initialFilter,
          ...paginationFilter,
        },
      });
    }
  };

  const handleSave = async () => {
    form?.submit();
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef?.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef?.current?.focus();
  }, [inputValue]);

  const handleClose = (removedTag) => {
    const newTags = tags?.filter((tag) => tag !== removedTag);
    setEditableButton(true);
    setTags(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
    inputRef?.current?.focus();
  };

  const handleTagChange = (selectedTags) => {
    setSearch('');
    setTagSearch('');
    setEditableButton(true);
    const duplicateTags = selectedTags?.filter((tag) => !tags?.includes(tag));
    setSelectedItems(duplicateTags);
  };

  const handleEditInputChange = (e) => {
    const editInputValues = e?.target?.value;
    setEditInputValue(editInputValues?.toLowerCase());
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue('');
  };

  const disabledDate = (current) =>
    current && current < dayjs()?.startOf('day');

  const isEndDatePast = dayjs(data?.project?.endDate)?.isBefore(dayjs());

  return (
    <>
      <Card
        size="small"
        className="ant-body-scroll"
        title={
          <>
            <div className="users-sidebar">
              <div className="project-filter-left">
                <Button
                  type="text"
                  shape="square"
                  onClick={handleBack}
                  icon={<ArrowLeftOutlined />}
                />
                <Divider type="vertical" />
                {data?.project?.name}
              </div>
              <div className="project-filter-right">
                <Button
                  type="primary"
                  onClick={handleSave}
                  disabled={!editableButton}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        }
      >
        {loading && <LoaderComponent />}
        <div className="card-body-wrapper">
          <div>
            <Form
              name="control-ref"
              form={form}
              onFinish={onFinish}
              onFieldsChange={() => setEditableButton(true)}
              layout="vertical"
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="name"
                    label="Project Name:"
                    rules={[
                      {
                        required: true,
                        message: 'Please Input Project Name!',
                      },
                    ]}
                  >
                    <Input disabled={!isUpdatePermission} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="clientName"
                    label="Client Name:"
                    rules={[
                      {
                        required: true,
                        message: 'Please Input Client Name!',
                      },
                    ]}
                  >
                    <Input disabled={!isUpdatePermission} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name="createdOn" label="Created On:">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} xl={8}>
                  <Form.Item
                    name="status"
                    label="Status"
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      disabled={!isUpdatePermission}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="endDate"
                    label="Date Of Project Completion"
                    className="project-completion"
                  >
                    <DatePicker
                      format={DEFAULT_DATE_FORMAT}
                      disabledDate={disabledDate}
                      disabled={!isUpdatePermission}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="description"
                    label="Project Description"
                    className="Project-description"
                  >
                    <TextArea
                      rows={3}
                      placeholder="Add Project Description"
                      disabled={!isUpdatePermission}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Descriptions title="External Reference">
                <Descriptions.Item>
                  <Space direction="vertical">
                    <Form.List name="integrations">
                      {(fields, { add, remove }) => (
                        <>
                          {fields?.map((field, index) => (
                            <Space key={field?.key} align="baseline">
                              <Form.Item
                                key={field?.index}
                                name={[field?.name, 'referenceName']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please Add Reference Name',
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={`Reference Name ${index + 1}:`}
                                  disabled={!isUpdatePermission}
                                />
                              </Form.Item>
                              <Form.Item
                                key={field?.index}
                                name={[field?.name, 'referenceId']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please Add Reference Id',
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={`Reference Id ${index + 1}:`}
                                  disabled={!isUpdatePermission}
                                />
                              </Form.Item>
                              {editable && isUpdatePermission && (
                                <MinusCircleOutlined
                                  onClick={() => remove(field?.name)}
                                />
                              )}
                            </Space>
                          ))}
                          {editable && isUpdatePermission && (
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              >
                                Add field
                              </Button>
                            </Form.Item>
                          )}
                        </>
                      )}
                    </Form.List>
                  </Space>
                </Descriptions.Item>
              </Descriptions>
              <Divider />
              <Descriptions title="Tags">
                <Descriptions.Item>
                  {editable ? (
                    <Form.Item name="tags">
                      <Space size={[0, 8]} wrap>
                        <Space size={[0, 8]} wrap>
                          {tags?.map((tag, index) => {
                            if (editInputIndex === index) {
                              return (
                                <Input
                                  ref={editInputRef}
                                  key={tag}
                                  size="small"
                                  className={inputTagWidth}
                                  value={editInputValue}
                                  onChange={handleEditInputChange}
                                  onBlur={handleEditInputConfirm}
                                  onPressEnter={handleEditInputConfirm}
                                  autoFocus
                                />
                              );
                            }
                            const tagElem = (
                              <Tag
                                key={tag}
                                closeIcon={isUpdatePermission}
                                onClose={() => handleClose(tag)}
                              >
                                <span
                                  onDoubleClick={
                                    !isUpdatePermission
                                      ? undefined
                                      : (e) => {
                                          e?.preventDefault();
                                          setEditInputIndex(index);
                                          setEditInputValue(tag);
                                        }
                                  }
                                >
                                  {tag}
                                </span>
                              </Tag>
                            );
                            return tagElem;
                          })}
                        </Space>
                        {inputVisible && (
                          <CommonSelect
                            getPopupContainer={(trigger) => trigger?.parentNode}
                            inputRef={inputRef}
                            mode="tags"
                            placeholder="Search Tags"
                            className="user-tags"
                            defaultOpen
                            query={ALL_TAGS}
                            onChange={handleTagChange}
                            onSearch={(searchText) => {
                              handleTagSearch(searchText);
                            }}
                            variables={{
                              searchString: tagSearch,
                              filters: {
                                tagsToExclude: tags,
                              },
                            }}
                            useEffectDeps={[tagSearch]}
                            responsePath="searchTags.tags"
                            valuePath="name"
                            labelPath="name"
                            fetchPolicy="network-only"
                            searchValue={search}
                            virtual={false}
                          />
                        )}
                        {isUpdatePermission && !inputVisible && (
                          <Tag onClick={showInput} className="add-new-tag">
                            <PlusOutlined /> New Tag
                          </Tag>
                        )}
                      </Space>
                    </Form.Item>
                  ) : (
                    <div>
                      {tags?.map((tag) => (
                        <Tag
                          color={
                            COLORS[Math.floor(Math.random() * COLORS.length)]
                          }
                          key={tag}
                        >
                          <span>{tag}</span>
                        </Tag>
                      ))}
                    </div>
                  )}
                </Descriptions.Item>
              </Descriptions>
              <Divider />
              <Descriptions title="Project Resource Sheet">
                <Descriptions.Item>
                  <Space direction="vertical">
                    <Form.List name="projectExcel">
                      {(fields, { add, remove }) => (
                        <>
                          {fields?.map((field, index) => (
                            <Space key={field?.key} align="baseline">
                              <Form.Item
                                key={field?.index}
                                name={[field?.name, 'title']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please Add Title',
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={`Title ${index + 1}:`}
                                  disabled={!isUpdatePermission}
                                />
                              </Form.Item>
                              <Form.Item
                                key={field?.index}
                                name={[field?.name, 'excelSheetUrl']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please Add Excel Sheet Url',
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={`Excel Sheet Url ${index + 1}:`}
                                  disabled={!isUpdatePermission}
                                />
                              </Form.Item>
                              {editable && isUpdatePermission && (
                                <MinusCircleOutlined
                                  onClick={() => remove(field?.name)}
                                />
                              )}
                            </Space>
                          ))}
                          {editable && isUpdatePermission && (
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              >
                                Add sheet
                              </Button>
                            </Form.Item>
                          )}
                        </>
                      )}
                    </Form.List>
                  </Space>
                </Descriptions.Item>
              </Descriptions>
              <AddMembers
                projectId={projectId}
                isActive={data?.project?.status === 'ACTIVE'}
                isEndDatePast={isEndDatePast}
                isUpdatePermission={isUpdatePermission}
                isDeletePermission={isDeletePermission}
              />
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
}

export default ViewProject;
