import { gql } from '@apollo/client';

export const USERS = gql`
  query usersAdminSharedResumes($filters: AdminSearchInput) {
    usersAdminSharedResumes(filters: $filters) {
      count
      users {
        billable
        id
        displayName
        email
        jobTitle
        department {
          name
        }
      }
    }
  }
`;

export const DESIGNATIONS = gql`
  query designations($searchString: String, $filters: DesignationFiltersInput) {
    designations(searchString: $searchString, filters: $filters) {
      count
      designations {
        jobTitle
      }
    }
  }
`;
