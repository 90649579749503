import { gql } from '@apollo/client';

// __typename we will remove this in future
export const CREATE_RESOURCE_ALLOCATION = gql`
  mutation CreateResourceProjectAllocation(
    $input: CreateResourceProjectAllocation!
  ) {
    createResourceProjectAllocation(input: $input) {
      __typename
    }
  }
`;

export const UPDATE_RESOURCE_ALLOCATION = gql`
  mutation UpdateResourceProjectAllocation(
    $input: UpdateResourceProjectAllocationInput!
  ) {
    updateResourceProjectAllocation(input: $input) {
      __typename
    }
  }
`;
