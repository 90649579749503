import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Switch,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { MODULES_KEY, PERMISSION_OBJECT, REGEX } from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import VerifyPermissions from '../../components/VerifyPermissions';
import { UPDATE_MONTHLY_INSTANCES } from './graphql/Mutations';
import { MONTHLY_INSTANCES } from './graphql/Queries';

function Configuration() {
  const [form] = Form?.useForm();
  const [tableData, setTableData] = useState([]);
  const [isSaved, setIsSaved] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const isUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.CONFIGURATIONS,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });

  const [executeQuery, { data: monthlyInstancesData }] = useLazyQuery(
    MONTHLY_INSTANCES,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [editMonthlyInstances] = useMutation(UPDATE_MONTHLY_INSTANCES, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      executeQuery();
    },
    onError() {},
  });

  useEffect(() => {
    executeQuery();
  }, []);

  useEffect(() => {
    if (monthlyInstancesData) {
      const initialData = monthlyInstancesData?.monthlyInstances?.data?.map(
        (item) => ({
          id: item?.id,
          levelId: item?.level?.id,
          level: item?.level?.name,
          lateComing: item?.lateComing === null ? '-' : item?.lateComing,
          workFromHome: item?.workFromHome === null ? '-' : item?.workFromHome,
          hoursAdjustment:
            item?.hoursAdjustment === null ? '-' : item?.hoursAdjustment,
          isReferral: item?.isReferral || false,
        }),
      );
      setTableData(initialData);
    }
  }, [monthlyInstancesData]);

  const handleInputChange = (value, record, dataIndex) => {
    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      const recordIndex = updatedTableData?.findIndex(
        (item) => item?.id === record?.id,
      );
      const updatedRecord = { ...updatedTableData[recordIndex] };
      updatedRecord[dataIndex] = parseInt(value, 10);
      updatedTableData[recordIndex] = updatedRecord;
      return updatedTableData;
    });
    setIsSaved(false);
  };

  const handleSwitchChange = (checked, record) => {
    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      const recordIndex = updatedTableData?.findIndex(
        (item) => item?.id === record?.id,
      );
      const updatedRecord = { ...updatedTableData[recordIndex] };
      updatedRecord.isReferral = checked;
      updatedTableData[recordIndex] = updatedRecord;
      return updatedTableData;
    });
    setIsSaved(false);
  };

  const handleSave = async () => {
    try {
      const selectedFields = tableData?.map(
        ({
          levelId,
          lateComing,
          workFromHome,
          hoursAdjustment,
          isReferral,
        }) => ({
          levelId,
          lateComing: lateComing === '-' ? null : lateComing,
          workFromHome: workFromHome === '-' ? null : workFromHome,
          hoursAdjustment: hoursAdjustment === '-' ? null : hoursAdjustment,
          isReferral,
        }),
      );
      await editMonthlyInstances({
        variables: {
          data: selectedFields,
        },
      });
      setIsSaved(true);
      setModalOpen(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('Error:', error);
    }
  };

  const handleCancel = () => {
    executeQuery();
    setIsSaved(true);
  };

  const validateNumber = (_, value) => {
    if (REGEX?.NUMBER?.test(value) || REGEX?.ONLY_DASH?.test(value)) {
      return Promise.resolve();
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Should be a valid Number');
  };

  const columns = [
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      width: '20%',
      align: 'center',
    },
    {
      title: 'Late coming',
      dataIndex: 'lateComing',
      key: 'lateComing',
      width: '20%',
      render: (text, record) => (
        <Form.Item
          name={`lateComing_${record?.id}`}
          rules={[
            {
              validator: validateNumber,
            },
          ]}
        >
          <Input
            defaultValue={record?.lateComing}
            onChange={(e) =>
              handleInputChange(e?.target?.value, record, 'lateComing')
            }
            onPressEnter={() => setModalOpen(true)}
            disabled={!isUpdatePermission}
          />
        </Form.Item>
      ),
    },
    {
      title: 'WFH',
      dataIndex: 'workFromHome',
      key: 'workFromHome',
      width: '20%',
      render: (text, record) => (
        <Form.Item
          name={`workFromHome_${record?.id}`}
          rules={[
            {
              validator: validateNumber,
            },
          ]}
        >
          <Input
            defaultValue={record?.workFromHome}
            onChange={(e) =>
              handleInputChange(e?.target?.value, record, 'workFromHome')
            }
            onPressEnter={() => setModalOpen(true)}
            disabled={!isUpdatePermission}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Hours Adjustment',
      dataIndex: 'hoursAdjustment',
      key: 'hoursAdjustment',
      width: '20%',
      render: (text, record) => (
        <Form.Item
          name={`hoursAdjustment_${record?.id}`}
          rules={[
            {
              validator: validateNumber,
            },
          ]}
        >
          <Input
            defaultValue={record?.hoursAdjustment}
            onChange={(e) =>
              handleInputChange(e?.target?.value, record, 'hoursAdjustment')
            }
            onPressEnter={() => setModalOpen(true)}
            disabled={!isUpdatePermission}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Referral Amount',
      dataIndex: 'isReferral',
      key: 'isReferral',
      align: 'center',
      render: (text, record) => (
        <Form.Item name={`isReferral_${record?.id}`} valuePropName="checked">
          <Switch
            defaultChecked={record?.isReferral}
            onChange={(checked) => handleSwitchChange(checked, record)}
            disabled={!isUpdatePermission}
          />
        </Form.Item>
      ),
    },
  ];

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Configure Maximum Monthly Instances"
        extra={
          <div className="monthly-instances-btn">
            <Button onClick={handleCancel} disabled={isSaved}>
              Cancel
            </Button>
            <Button
              className="save-btn"
              type="primary"
              onClick={() => setModalOpen(true)}
              disabled={isSaved}
            >
              Save Changes
            </Button>
          </div>
        }
      >
        <Row>
          <Col xs={24} sm={20} md={16} lg={12}>
            <div className="monthly-instances-alert">
              <Alert
                message="Please note that updates will be displayed here and will be applicable from next month."
                type="info"
                showIcon
              />
            </div>
            <Form form={form} className="configuration-form">
              <CommonTable
                size="small"
                className="monthly-instances-table"
                columns={columns}
                dataSource={tableData}
                rowKey={(obj) => obj?.id}
                bordered
              />
            </Form>
          </Col>
        </Row>
      </Card>
      {modalOpen && (
        <Modal
          title="Attention User!"
          centered
          closable={false}
          open={modalOpen}
          onOk={handleSave}
          onCancel={() => setModalOpen(false)}
        >
          <Alert
            description="Updates will only be applicable from the next month. Do you still wish to proceed?"
            type="info"
            showIcon
          />
        </Modal>
      )}
    </div>
  );
}

export default Configuration;
