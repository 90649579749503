import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_INCIDENT_REPORT = gql`
  mutation UpdateIncidentReport(
    $where: ID!
    $data: UpdateIncidentReportInput!
  ) {
    updateIncidentReport(where: $where, data: $data) {
      message
    }
  }
`;

export const CREATE_INCIDENT_REPORT_ADMIN = gql`
  mutation CreateIncidentReportAdmin($data: IncidentReportInput!) {
    createIncidentReportAdmin(data: $data) {
      message
    }
  }
`;
