import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FilterFilled,
  PlusOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form, Modal, Select, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { debounce, keys, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ATTENDANCE,
  DEFAULT_DATE_FORMAT,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import CommonButton from '../../components/CommonButton';
import CommonSelect from '../../components/CommonSelect';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import VerifyPermissions from '../../components/VerifyPermissions';
import { GET_EVENTS } from '../event/graphql/Queries';
import RsvpModal from './components/RsvpModal';
import { REMOVE_ATTENDEE } from './graphql/Mutation';
import { GET_ATTENDEES } from './graphql/Queries';

const { Option } = Select;

const Rsvp = () => {
  const history = useHistory();
  const [form] = Form?.useForm();
  const initialFilter = {
    skip: 0,
    limit: LIMIT,
    sort: {
      key: 'createdAt',
      order: 'DESC',
    },
    status: null,
    eventId: '',
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [filterVisible, setFilterVisible] = useState({
    statusFilterVisible: false,
    eventFilterVisible: false,
  });
  const [eventSearch, setEventSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const isCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.RSVP_MANAGEMENT,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });
  const isUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.RSVP_MANAGEMENT,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });
  const isDeletePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.RSVP_MANAGEMENT,
    allowedPermissions: [PERMISSION_OBJECT?.delete],
  });

  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  // comment for future use
  // const [selectedType, setSelectedType] = useState();
  // const [selectedPaymentStatus, setSelectedPaymentStatus] = useState();
  // const { getToken } = useContext(AppContext);
  // const token = getToken();
  const [attendeeFilter, setAttendeeFilter] = useState(initialFilter);

  const [fetchAttendee, { data, loading, refetch }] = useLazyQuery(
    GET_ATTENDEES,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: attendeeFilter,
      },
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 15,
          total: res?.attendees?.total,
        };
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );

  // comment for future use
  // const { data: attendeeType } = useQuery(GET_ATTENDEE_TYPES, {
  //   fetchPolicy: 'network-only',
  // });

  const [removeAttendee] = useMutation(REMOVE_ATTENDEE);

  useEffect(() => {
    fetchAttendee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setAttendeeFilter({
      ...attendeeFilter,
      skip,
      limit: pagination.pageSize,
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setAttendeeFilter({
        ...attendeeFilter,
        skip,
        limit: pagination.pageSize,
        sort: {
          key: sorter?.field,
          order: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      fetchAttendee({
        variables: {
          filter: {
            ...attendeeFilter,
            skip,
            limit: pagination.pageSize,
            sort: {
              key: sorter?.field,
              order: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
            },
          },
        },
      });
    } else {
      setAttendeeFilter({
        ...attendeeFilter,
        skip,
        limit: pagination.pageSize,
        sort: {
          key: 'createdAt',
          order: 'DESC',
        },
      });
      fetchAttendee({
        variables: {
          filter: {
            ...attendeeFilter,
            skip,
            limit: pagination.pageSize,
            sort: {
              key: 'createdAt',
              order: 'DESC',
            },
          },
        },
      });
    }
  };

  // comment for future use
  // const renderGuestsName = (value) => {
  //   if (value && value.length && value.length > 0)
  //     return map(value, (_, index) => (
  //       <>
  //         {`${_.firstName} ${_.lastName || ''}`}
  //         <strong>{`${value.length - 1 === index ? '' : ', '}`}</strong>
  //       </>
  //     ));
  //   return '-';
  // };

  const onDeleteClick = (id) => {
    Modal?.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Delete rvsp',
      content: 'Are you sure you want to delete this rvsp?',
      centered: true,
      closable: true,
      okText: 'Yes',
      cancelText: 'No',
      className: 'custom-modal-button',
      onOk: async () =>
        removeAttendee({
          variables: {
            input: {
              id,
            },
          },
        })
          .then(() => {
            refetch();
          })
          .catch((err) => err),
    });
  };

  const onEditClick = (id) => {
    history.push(`${ROUTES_MODULES_KEY?.RSVP_MANAGEMENT}/edit/${id}`);
    setShowModal(true);
  };

  // comment for future use
  // const getCsv = async (
  //   fetchStatus,
  //   fetchEvent,
  //   fetchPaymentStatus,
  //   fetchType,
  // ) => {
  //   const url = `${process.env.REACT_APP_SERVER_REST_URL}/attendee/csvExport`;
  //   let sendUrl;
  //   if (fetchStatus) {
  //     sendUrl = `${url}?status=${fetchStatus}`;
  //   }
  //   if (fetchEvent) {
  //     if (sendUrl) sendUrl = `${sendUrl}&event=${fetchEvent}`;
  //     else sendUrl = `${url}?event=${fetchEvent}`;
  //   }
  //   if (fetchPaymentStatus) {
  //     if (sendUrl) sendUrl = `${sendUrl}&paymentStatus=${fetchPaymentStatus}`;
  //     else sendUrl = `${url}?paymentStatus=${fetchPaymentStatus}`;
  //   }
  //   if (fetchType) {
  //     if (sendUrl) sendUrl = `${sendUrl}&type=${fetchType}`;
  //     else sendUrl = `${url}?type=${fetchType}`;
  //   }
  //   if (!sendUrl) {
  //     sendUrl = url;
  //   }
  //   await axios
  //     .get(sendUrl, {
  //       headers: {
  //         authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       handleCsvDownload(
  //         res?.data,
  //         `RSVP-REPORT-${moment().format('MM-DD-YYYY')}.csv`,
  //       );
  //     });
  // };

  const handleFilter = () => {
    setAttendeeFilter({
      ...attendeeFilter,
      skip: 0,
      status: selectedStatus,
    });
    setPaginationProp({ ...paginationProp, currentPage: 1 });
    fetchAttendee({
      variables: {
        filter: {
          ...attendeeFilter,
          skip: 0,
          status: selectedStatus,
        },
      },
    });
    setFilterVisible({
      ...filterVisible,
      statusFilterVisible: false,
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setAttendeeFilter({
      ...attendeeFilter,
      skip: 0,
      status: null,
    });
    setPaginationProp({ ...paginationProp, currentPage: 1 });
    fetchAttendee({
      variables: {
        filter: {
          ...attendeeFilter,
          skip: 0,
          status: null,
        },
      },
    });
  };

  const handleEventReset = () => {
    form?.resetFields();
    setAttendeeFilter({
      ...attendeeFilter,
      skip: 0,
      eventId: '',
    });
    setPaginationProp({ ...paginationProp, currentPage: 1 });
    fetchAttendee({
      variables: {
        filter: {
          ...attendeeFilter,
          skip: 0,
          eventId: '',
        },
      },
    });
  };

  const handleEventFilter = () => {
    setAttendeeFilter({
      ...attendeeFilter,
      skip: 0,
      eventId: selectedEvent,
    });
    setPaginationProp({ ...paginationProp, currentPage: 1 });
    fetchAttendee({
      variables: {
        filter: {
          ...attendeeFilter,
          skip: 0,
          eventId: selectedEvent,
        },
      },
    });
    setFilterVisible({
      ...filterVisible,
      eventFilterVisible: false,
    });
  };

  const handleEvent = (value) => {
    setSelectedEvent(value);
    setEventSearch('');
  };

  const handleEventSearch = debounce((value) => {
    setEventSearch(value);
  }, 500);

  const getAttendanceFilterProps = () => ({
    filterDropdownOpen: filterVisible?.statusFilterVisible,
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({
        ...filterVisible,
        statusFilterVisible: visible,
      }),
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <Form name="control-ref" form={form} layout="vertical">
            <Form.Item name="status">
              <Select
                allowClear
                placeholder="Sort By Status"
                onChange={(e) => {
                  if (e) {
                    setSelectedStatus(e);
                  } else {
                    setSelectedStatus();
                  }
                }}
              >
                {keys(ATTENDANCE)?.map((key) => (
                  <Option key={key} value={key}>
                    {ATTENDANCE[key]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <Space direction="horizontal">
            <Button size="small" onClick={handleFilter}>
              Apply
            </Button>
            <Button
              danger
              size="small"
              onClick={() => {
                handleReset();
                setFilterVisible({
                  ...filterVisible,
                  statusFilterVisible: false,
                });
              }}
            >
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),

    filterIcon: () => (
      <FilterFilled
        className={attendeeFilter?.status ? 'filter-filled-data' : ''}
      />
    ),
  });

  const getEventFilterProps = () => ({
    filterDropdownOpen: filterVisible?.eventFilterVisible,
    onFilterDropdownOpenChange: (visible) =>
      setFilterVisible({
        ...filterVisible,
        eventFilterVisible: visible,
      }),
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <Form name="control-ref" form={form} layout="vertical">
            <Form.Item name="event">
              <CommonSelect
                placeholder="Select Event"
                showSearch
                query={GET_EVENTS}
                onChange={handleEvent}
                onSearch={handleEventSearch}
                variables={{
                  filters: { skip: 0, limit: LIMIT, search: eventSearch },
                }}
                useEffectDeps={[eventSearch]}
                responsePath="eventsAdmin.events"
                valuePath="id"
                labelPath="name"
                fetchPolicy="network-only"
                virtual={false}
              />
            </Form.Item>
          </Form>
          <Space direction="horizontal">
            <Button size="small" onClick={handleEventFilter}>
              Apply
            </Button>
            <Button
              danger
              size="small"
              onClick={() => {
                handleEventReset();
                setFilterVisible({
                  ...filterVisible,
                  eventFilterVisible: false,
                });
              }}
            >
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),

    filterIcon: () => (
      <FilterFilled
        className={attendeeFilter?.eventId ? 'filter-filled-data' : ''}
      />
    ),
  });

  const columns = [
    {
      title: 'Name',
      key: 'lastName',
      dataIndex: 'lastName',
      width: '250px',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'lastName' && sortedInfo?.order,
      render: (value, record) => (
        <div>
          <span>
            {record?.user?.displayName || record?.user?.firstName}
            {record?.guests?.length > 0 && (
              <Tooltip
                title={record?.guests?.map((guest) => (
                  <div key={guest?.id}>
                    {`${guest?.firstName} ${guest?.lastName}`}
                  </div>
                ))}
                placement="topLeft"
              >
                {` +${record?.noOfGuests}`}
              </Tooltip>
            )}
          </span>
        </div>
      ),
    },
    // comment for future use
    // {
    //   title: 'ADDITIONAL GUESTS',
    //   key: 'noOfGuests',
    //   dataIndex: 'noOfGuests',
    //   width: '100px',
    // },
    // {
    //   title: 'ADDITIONAL GUESTS NAME',
    //   dataIndex: 'guests',
    //   width: '200px',
    //   key: 'guests',
    //   render: (value) => renderGuestsName(value),
    // },
    {
      title: 'Event',
      width: '350px',
      ...getEventFilterProps(),
      render: (value, record) => record?.event?.name,
    },
    {
      title: 'Attendance',
      key: 'status',
      dataIndex: 'status',
      width: '100px',
      ...getAttendanceFilterProps(),
      render: (value) => ATTENDANCE[value],
    },
    {
      title: 'Event Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: '100px',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'startDate' && sortedInfo?.order,
      render: (value, record) =>
        dayjs(record?.event?.startDate)?.format(DEFAULT_DATE_FORMAT),
    },
    // comment for future use
    // {
    //   title: 'ATTENDEE TYPE',
    //   dataIndex: 'attendeeType',
    //   width: '100px',
    //   key: 'attendeeType',
    //   render: (value) => value && value?.value,
    // },
    // comment for future use
    // {
    //   title: 'PAYMENT STATUS',
    //   dataIndex: 'paymentStatus',
    //   key: 'paymentStatus',
    //   width: '120px',
    //   render: (value) => PAYMENT_STATUS[value] || '-',
    // },
    ...(isUpdatePermission || isDeletePermission
      ? [
          {
            title: 'Actions',
            key: 'actions',
            width: '120px',
            render: (value, record) => (
              <div className="d-flex align-center justify-center">
                {isUpdatePermission && (
                  <Tooltip title="Edit">
                    <CommonButton
                      type="link"
                      onClick={() => {
                        onEditClick(record?.id);
                      }}
                      disabled={record?.deletedAt}
                    >
                      <EditOutlined />
                    </CommonButton>
                  </Tooltip>
                )}
                {isDeletePermission && (
                  <Tooltip title="Delete">
                    <CommonButton
                      onClick={() => onDeleteClick(record?.id)}
                      type="link"
                      danger
                      disabled={record?.deletedAt}
                    >
                      <DeleteOutlined />
                    </CommonButton>
                  </Tooltip>
                )}
              </div>
            ),
          },
        ]
      : []),
  ];

  // comment for future use
  // const handleStatusChange = (value) => {
  //   if (value) {
  //     setAttendeeFilter({ ...attendeeFilter, skip: 0, status: value });
  //     fetchAttendee({
  //       variables: {
  //         input: {
  //           ...attendeeFilter,
  //           status: value,
  //         },
  //       },
  //     });
  //   } else {
  //     setAttendeeFilter({ ...attendeeFilter, skip: 0, status: undefined });
  //     fetchAttendee({
  //       variables: {
  //         input: { ...attendeeFilter, status: undefined },
  //       },
  //     });
  //   }
  // };

  // comment for future use
  // const handlePaymentStatusChange = (value) => {
  //   if (value) {
  //     setAttendeeFilter({ ...attendeeFilter, skip: 0, paymentStatus: value });
  //     fetchAttendee({
  //       variables: {
  //         input: { ...attendeeFilter, paymentStatus: value },
  //       },
  //     });
  //   } else {
  //     setAttendeeFilter({
  //       ...attendeeFilter,
  //       skip: 0,
  //       paymentStatus: undefined,
  //     });
  //     fetchAttendee({
  //       variables: { input: { ...attendeeFilter, paymentStatus: undefined } },
  //     });
  //   }
  // };

  const onSearchChange = async (value) => {
    setPaginationProp({ ...paginationProp, current: 1 });
    setAttendeeFilter({ ...attendeeFilter, skip: 0, search: trim(value) });
    fetchAttendee({
      variables: { input: { ...attendeeFilter, skip: 0, search: trim(value) } },
    });
    if (!value) {
      setAttendeeFilter({ ...attendeeFilter, skip: 0, search: '' });
      fetchAttendee({
        variables: { input: { ...attendeeFilter, search: '' } },
      });
    }
  };

  // comment for future use
  // const handleEventChange = (value) => {
  //   if (value) {
  //     setAttendeeFilter({ ...attendeeFilter, skip: 0, event: parseInt(value) });
  //     fetchAttendee({
  //       variables: {
  //         input: { ...attendeeFilter, skip: 0, event: parseInt(value) },
  //       },
  //     });
  //   } else {
  //     setAttendeeFilter({ ...attendeeFilter, skip: 0, event: undefined });
  //     fetchAttendee({
  //       variables: { input: { ...attendeeFilter, event: undefined } },
  //     });
  //   }
  // };

  // comment for future use
  // const handleTypeChange = (value) => {
  //   if (value) {
  //     setAttendeeFilter({ ...attendeeFilter, skip: 0, type: value });
  //     fetchAttendee({
  //       variables: {
  //         input: { ...attendeeFilter, skip: 0, type: value },
  //       },
  //     });
  //   } else {
  //     setAttendeeFilter({ ...attendeeFilter, skip: 0, type: undefined });
  //     fetchAttendee({
  //       variables: { input: { ...attendeeFilter, type: undefined } },
  //     });
  //   }
  // };

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Event RSVP Management"
        extra={
          <div className="project-filter">
            <SearchComponent className="mr-8" getData={onSearchChange} />
            {isCreatePermission && (
              <Tooltip title="Attendee">
                <CommonButton
                  type="primary"
                  className="mr-8"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    history.push(
                      `${ROUTES_MODULES_KEY?.RSVP_MANAGEMENT}/create`,
                    );
                    setShowModal(true);
                  }}
                >
                  Attendee
                </CommonButton>
              </Tooltip>
            )}
            {
              // comment for future use
              /* <CommonSelect
              allowClear
              className="w-150 mr-8 mb-8"
              placeholder="Attendance"
              onChange={(e) => {
                handleStatusChange(e);
                if (e) {
                  setSelectedStatus(e);
                } else {
                  setSelectedStatus();
                }
              }}
            >
              {keys(ATTENDANCE).map((key) => (
                <Option key={key} value={key}>
                  {ATTENDANCE[key]}
                </Option>
              ))}
            </CommonSelect>
            <CommonSelect
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className="w-150 mr-8 mb-8"
              placeholder="Event"
              onChange={(e) => {
                handleEventChange(e);
                if (e) {
                  setSelectedEvent(e);
                } else {
                  setSelectedEvent();
                }
              }}
            >
              {map(eventData?.events?.events, (event) => (
                <Option key={event?.id} value={event?.id}>
                  {event?.name}
                </Option>
              ))}
            </CommonSelect>
            <CommonSelect
              allowClear
              className="w-150 mr-8 mb-8"
              placeholder="Attendee Type"
              onChange={(e) => {
                handleTypeChange(e);
                if (e) {
                  setSelectedType(e);
                } else {
                  setSelectedType();
                }
              }}
            >
              {map(attendeeType?.attendeeTypes?.data, (type) => (
                <Option key={type?.key} value={type?.key}>
                  {type?.value}
                </Option>
              ))}
            </CommonSelect>
            <CommonSelect
              allowClear
              className="w-150 mr-8 mb-8"
              placeholder="Payment Status"
              onChange={(e) => {
                handlePaymentStatusChange(e);
                if (e) {
                  setSelectedPaymentStatus(e);
                } else {
                  setSelectedPaymentStatus();
                }
              }}
            >
              {keys(PAYMENT_STATUS).map((key) => (
                <Option key={key} value={key}>
                  {PAYMENT_STATUS[key]}
                </Option>
              ))}
            </CommonSelect>
            <Tooltip title="Export Report">
              <CommonButton
                type="primary"
                className="mr-8"
                onClick={(e) => {
                  e.stopPropagation();
                  getCsv(
                    selectedStatus,
                    selectedEvent,
                    selectedPaymentStatus,
                    selectedType,
                  );
                }}
                disabled={data?.attendees?.data?.length === 0}
              >
                Export RSVP Report
              </CommonButton>
            </Tooltip> */
            }
          </div>
        }
      >
        <div className="card-body-wrapper">
          <CommonTable
            size="small"
            columns={columns}
            paginationConfig={paginationProp}
            loadingData={loading}
            onChange={handleTableChange}
            data={data?.attendees?.data || []}
            rowKey={(obj) => obj.id}
          />
          {showModal && (
            <RsvpModal
              showModal={showModal}
              setShowModal={setShowModal}
              refetch={refetch}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default Rsvp;
