import { gql } from '@apollo/client';

export const CREATE_ROLE = gql`
  mutation CreateRolePermissions($input: PermissionRoleInput!) {
    createRolePermissions(input: $input) {
      message
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRolePermissions(
    $where: PermissionUniqueInput!
    $input: PermissionRoleInput!
  ) {
    updateRolePermissions(where: $where, input: $input) {
      message
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRolePermissions($where: PermissionUniqueInput!) {
    deleteRolePermissions(where: $where) {
      message
    }
  }
`;
