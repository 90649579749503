import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const INCIDENT_MASTERS = gql`
  query incidentMasters($filters: IncidentMasterFilterInput!) {
    incidentMasters(filters: $filters) {
      count
      data {
        id
        name
        description
        status
      }
    }
  }
`;
