import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES, USER } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import { NEW_ACCESS_TOKEN } from './graphql/Mutations';

const RefreshToken = () => {
  // eslint-disable-next-line no-undef
  const user = JSON.parse(localStorage.getItem(USER));
  const { initializeAuth, getRefreshToken } = useContext(AppContext);
  const history = useHistory();
  const {
    location: { state },
  } = history;
  const refreshToken = getRefreshToken();
  function successCallback(accessToken) {
    initializeAuth(user, accessToken);
  }

  const [refresh, { loading }] = useMutation(NEW_ACCESS_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const accessToken = response?.newAccessToken;
      successCallback(accessToken);
      if (state?.from !== ROUTES?.AUTHENTICATION) {
        history?.replace(state?.from);
      } else {
        history?.replace(ROUTES?.MAIN);
      }
    },
    onError() {
      history?.replace(`${ROUTES?.AUTH}${ROUTES?.LOGIN}`);
    },
  });

  useEffect(() => {
    if (refreshToken)
      refresh({
        variables: {
          refreshToken,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderComponent />;

  return null;
};

export default RefreshToken;
