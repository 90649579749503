import { useLazyQuery } from '@apollo/client';
import { Card, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BACKEND_MONTH_FORMAT } from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import { MONTHLY_STATISTICS } from './graphql/Queries';

function Statistics() {
  const [filters, setFilters] = useState({
    skip: 0,
    searchString: '',
    month: dayjs()?.format(BACKEND_MONTH_FORMAT),
    sortField: 'displayName',
    sortOrder: 'ASC',
  });

  const [executeQuery, { loading, data }] = useLazyQuery(MONTHLY_STATISTICS, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const dataSource = data?.monthlyStatistics?.data;

  useEffect(() => {
    executeQuery({
      variables: {
        filters,
      },
    });
  }, [filters]);

  const handleMonth = (value) => {
    setFilters({
      ...filters,
      month: value
        ? dayjs(value)?.format(BACKEND_MONTH_FORMAT)
        : dayjs()?.format(BACKEND_MONTH_FORMAT),
    });
  };

  const handleSearch = debounce((value) => {
    setFilters({ ...filters, searchString: value?.trim() });
  }, 700);

  const onChange = (_, __, sorter) => {
    const { field, order } = sorter;
    setFilters({
      ...filters,
      sortField: field,
      sortOrder: order === 'descend' ? 'DESC' : 'ASC',
    });
  };

  const columns = [
    {
      title: 'Employee Details',
      dataIndex: 'displayName',
      key: 'displayName',
      sorter: true,
      defaultSortOrder:
        filters?.sortField === 'displayName' ? filters?.sortOrder : false,
      render: (value, record) => (
        <>
          {record?.displayName}
          <p className="name-column">
            [{`${record?.department?.name}-${record?.level?.name}`} ]
          </p>
        </>
      ),
    },
    {
      title: 'WFH',
      dataIndex: 'wfhCount',
      key: 'wfhCount',
      align: 'center',
      width: '15%',
      sorter: true,
      defaultSortOrder:
        filters?.sortField === 'wfhCount' ? filters?.sortOrder : false,
      render: (value, record) => {
        const wfhCountClass =
          record?.wfhCount > record?.totalWorkFromHome
            ? 'statistics-cell-background'
            : '';
        return (
          <div className="statistics-cell">
            <div className="actual-record">
              {record?.wfhCount}/{record?.totalWorkFromHome || 0}
            </div>
            <div className={wfhCountClass} />
          </div>
        );
      },
    },
    {
      title: 'Late Coming',
      dataIndex: 'lateComingCount',
      key: 'lateComingCount',
      align: 'center',
      width: '15%',
      sorter: true,
      defaultSortOrder:
        filters?.sortField === 'lateComingCount' ? filters?.sortOrder : false,
      render: (value, record) => {
        const lateComingClass =
          record?.lateComingCount > record?.totalLateComing
            ? 'statistics-cell-background'
            : '';
        return (
          <div className="statistics-cell">
            <div className="actual-record">
              {record?.lateComingCount}/{record?.totalLateComing || 0}
            </div>
            <div className={lateComingClass} />
          </div>
        );
      },
    },
    {
      title: 'Hours Adjustment',
      dataIndex: 'usedHourAdjustments',
      key: 'usedHourAdjustments',
      align: 'center',
      width: '15%',
      sorter: true,
      defaultSortOrder:
        filters?.sortField === 'usedHourAdjustments'
          ? filters?.sortOrder
          : false,
      render: (value, record) => {
        const hourAdjustmentsClass =
          record?.usedHourAdjustments > record?.totalHourAdjustment
            ? 'statistics-cell-background'
            : '';
        return (
          <div className="statistics-cell">
            <div className="actual-record">
              {record?.usedHourAdjustments}/{record?.totalHourAdjustment || 0}
            </div>
            <div className={hourAdjustmentsClass} />
          </div>
        );
      },
    },
    {
      title: 'Early Joining',
      dataIndex: 'totalEarlyJoining',
      key: 'totalEarlyJoining',
      align: 'center',
      width: '15%',
      sorter: true,
      defaultSortOrder:
        filters?.sortField === 'totalEarlyJoining' ? filters?.sortOrder : false,
      render: (value) => (
        <div className="statistics-cell">
          <div className="actual-record">{value || 0}</div>
        </div>
      ),
    },
    {
      title: 'Early Leaving',
      dataIndex: 'totalEarlyLeaving',
      key: 'totalEarlyLeaving',
      align: 'center',
      width: '15%',
      sorter: true,
      defaultSortOrder:
        filters?.sortField === 'totalEarlyLeaving' ? filters?.sortOrder : false,
      render: (value) => (
        <div className="statistics-cell">
          <div className="actual-record">{value || 0}</div>
        </div>
      ),
    },
    {
      title: 'Incident',
      dataIndex: 'totalIncidentReported',
      key: 'totalIncidentReported',
      align: 'center',
      width: '15%',
      sorter: true,
      defaultSortOrder:
        filters?.sortField === 'totalIncidentReported'
          ? filters?.sortOrder
          : false,
      render: (value) => (
        <div className="statistics-cell">
          <div className="actual-record">{value || 0}</div>
        </div>
      ),
    },
  ];

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Statistics- WFH/ Late Coming / HoursAdjustment"
        extra={
          <>
            <div className="project-filter">
              <SearchComponent
                getData={handleSearch}
                name="Name, Department, Designation, Level"
              />
              <DatePicker
                defaultValue={dayjs()}
                format="YYYY-MM"
                className="statistic-month-picker"
                picker="month"
                onChange={handleMonth}
              />
            </div>
          </>
        }
      >
        <div className="card-body-wrapper">
          <CommonTable
            size="small"
            className="statistics-table"
            columns={columns}
            dataSource={dataSource}
            onChange={onChange}
            loadingData={loading}
            rowKey={(obj) => obj?.id}
            bordered
          />
        </div>
      </Card>
    </div>
  );
}

export default Statistics;
