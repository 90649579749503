import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Popconfirm, Space, Tag, Tooltip } from 'antd';
import { camelCase, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  CURRENT_STATUS,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  STATUS,
} from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import VerifyPermissions from '../../components/VerifyPermissions';
import MasterModal from './component/MasterModel';
import {
  CREATE_INCIDENT_MASTER,
  DELETE_INCIDENT_MASTER,
  UPDATE_INCIDENT_MASTER,
} from './graphql/Mutation';
import { INCIDENT_MASTERS } from './graphql/Queries';
import './incidentMaster.less';

const initialFilters = {
  filter: {
    limit: LIMIT,
    skip: 0,
    search: '',
    sortOrder: 'DESC',
    statusFilter: null,
  },
  current: 1,
};

const IncidentMaster = () => {
  const [masterId, setMasterId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [editingItem, setEditingItem] = useState(false);
  const [tableParams, setTableParams] = useState(initialFilters);
  const [dataSource, setDataSource] = useState(null);

  const isCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.INCIDENT_MASTER,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });

  const isDeletePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.INCIDENT_MASTER,
    allowedPermissions: [PERMISSION_OBJECT?.delete],
  });

  const isUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.INCIDENT_MASTER,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });

  const [incidentMaster, { data, loading }] = useLazyQuery(INCIDENT_MASTERS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDataSource(res?.incidentMasters?.data);
    },
  });

  const [createIncidentMaster] = useMutation(CREATE_INCIDENT_MASTER, {
    onCompleted() {
      setTableParams({
        ...tableParams,
        current: 1,
        filter: {
          skip: 0,
          limit: LIMIT,
        },
      });
    },
    onError() {},
  });
  const [updateIncidentMaster] = useMutation(UPDATE_INCIDENT_MASTER, {
    onCompleted() {
      incidentMaster({
        variables: {
          filters: tableParams?.filter,
        },
      });
    },
    onError() {},
  });
  const [deleteIncidentMaster] = useMutation(DELETE_INCIDENT_MASTER, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      incidentMaster({
        variables: {
          filters: tableParams?.filter,
        },
      });
    },
    onError() {},
  });

  const renderTags = (status) => {
    switch (status) {
      case CURRENT_STATUS.ACTIVE:
        return (
          <Tag color="success">
            {startCase(camelCase(CURRENT_STATUS.ACTIVE))}
          </Tag>
        );
      case CURRENT_STATUS.INACTIVE:
        return (
          <Tag color="error">
            {startCase(camelCase(CURRENT_STATUS.INACTIVE))}
          </Tag>
        );
      default:
        return '-';
    }
  };

  useEffect(() => {
    incidentMaster({
      variables: {
        filters: tableParams?.filter,
      },
    });
  }, [tableParams]);

  const handleTableChange = (pagination, filters) => {
    const { current, pageSize } = pagination;
    setTableParams({
      ...tableParams,
      filter: {
        ...tableParams?.filter,
        limit: pageSize,
        skip: current * pageSize - pageSize,
        statusFilter: filters?.status?.[0],
      },
      current,
    });
  };

  const handleSearch = (val) => {
    const search = val?.trim();
    setTableParams({
      ...tableParams,
      filter: {
        ...tableParams?.filter,
        skip: 0,
        search,
      },
      current: 1,
    });
  };

  const onFinish = async (val) => {
    if (editingItem) {
      updateIncidentMaster({
        variables: {
          where: {
            id: editingItem?.id,
          },
          data: val,
        },
      });
    } else {
      createIncidentMaster({
        variables: {
          data: val,
        },
      });
    }
  };

  const handleAdd = () => {
    setEditingItem(null);
    setOpenModal(true);
  };

  const handleDelete = async ({ id }) => {
    setMasterId(id);
  };

  const confirm = () => {
    deleteIncidentMaster({
      variables: {
        where: {
          id: masterId,
        },
      },
    });
  };

  const handleEdit = (item) => {
    setEditingItem(item);
    setOpenModal(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: '40%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      filters: STATUS,
      filterMultiple: false,
      render: (status) => renderTags(status),
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          {isUpdatePermission && (
            <Tooltip placement="bottom" title={<span>Edit</span>}>
              <Button
                type="text"
                onClick={() => {
                  handleEdit(record);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          )}

          {isDeletePermission && (
            <Popconfirm
              title="Delete Master"
              description="Are you sure to delete this Master?"
              placement="topLeft"
              onConfirm={confirm}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip placement="bottom" title={<span>Delete Master</span>}>
                <Button
                  danger
                  type="text"
                  onClick={() => {
                    handleDelete(record);
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Incident Master"
        extra={
          <>
            <div className="holidays-sidebar">
              <div className="holiday-filter-right">
                {isCreatePermission && (
                  <Button key="1" type="primary" onClick={() => handleAdd()}>
                    Add Master
                  </Button>
                )}

                <SearchComponent getData={handleSearch} />
              </div>
            </div>
          </>
        }
      >
        <CommonTable
          className="master-common-table"
          size="small"
          columns={columns}
          dataSource={dataSource}
          rowKey={(obj) => obj?.id}
          bordered
          onChange={handleTableChange}
          paginationConfig={{
            position: ['bottomCenter'],
            total: data?.incidentMasters?.count,
            current: tableParams?.current,
            pageSize: tableParams?.filter.limit,
          }}
          loadingData={loading}
        />

        <MasterModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          onSubmit={onFinish}
          initialValues={editingItem}
        />
      </Card>
    </div>
  );
};

export default IncidentMaster;
