import { gql } from '@apollo/client';

export const ADD_PROJECT = gql`
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      message
      project {
        name
        clientName
        integrations {
          referenceId
          referenceName
        }
        tags
        createdOn
        creator {
          firstName
          lastName
        }
        status
        endDate
      }
    }
  }
`;

export const EDIT_PROJECT = gql`
  mutation updateProject(
    $input: UpdateProjectInput!
    $where: UpdateProjectID!
  ) {
    updateProject(input: $input, where: $where) {
      message
      project {
        name
        clientName
        integrations {
          referenceId
          referenceName
        }
        tags
        createdOn
        creator {
          firstName
          lastName
        }
        status
        endDate
      }
    }
  }
`;

export const CREATE_PROJECT_MEMBERS = gql`
  mutation createProjectMembers($input: CreateProjectMembersInput) {
    createProjectMembers(input: $input) {
      message
    }
  }
`;

export const DELETE_PROJECT_MEMBERS = gql`
  mutation deleteProjectMember($where: ProjectMemberUniqueInput!) {
    deleteProjectMember(where: $where) {
      message
    }
  }
`;

export const CREATE_MONTHLY_EXCEL_SHEET = gql`
  mutation CreateMonthlyExcelSheet($input: CreateMonthlyExcelSheetInput!) {
    createMonthlyExcelSheet(input: $input) {
      message
    }
  }
`;
