import { gql } from '@apollo/client';

export const CREATE_INCIDENT_MASTER = gql`
  mutation CreateIncidentMaster($data: IncidentMasterInput!) {
    createIncidentMaster(data: $data) {
      message
    }
  }
`;

export const UPDATE_INCIDENT_MASTER = gql`
  mutation UpdateIncidentMaster(
    $where: IncidentMasterUniqueInput!
    $data: IncidentMasterInput!
  ) {
    updateIncidentMaster(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_INCIDENT_MASTER = gql`
  mutation DeleteIncidentMaster($where: IncidentMasterUniqueInput!) {
    deleteIncidentMaster(where: $where) {
      message
    }
  }
`;
