import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const MONTHLY_STATISTICS = gql`
  query monthlyStatistics($filters: MonthlyStatisticsFilter) {
    monthlyStatistics(filters: $filters) {
      count
      data {
        id
        firstName
        lastName
        displayName
        level {
          name
        }
        department {
          name
        }
        wfhCount
        totalWorkFromHome
        usedHourAdjustments
        totalHourAdjustment
        lateComingCount
        totalLateComing
        totalEarlyJoining
        totalEarlyLeaving
        totalIncidentReported
      }
    }
  }
`;
