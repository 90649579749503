import { Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  DISALLOWED_KEYS,
  MODULES_KEY,
  PERMISSION_OBJECT,
} from '../../../common/constants';
import VerifyPermissions from '../../../components/VerifyPermissions';

const InputCellRenderer = ({ node, colDef, result, handleUpdateRecord }) => {
  const inputRef = useRef(null);
  const prevInputRef = useRef(null);
  const [inputKey, setInputKey] = useState(null);
  const isCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.RESOURCE_ALLOCATION,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });
  const record = node?.data;

  const oldValue = node?.data?.hoursAllocation?.find(
    (item) => item?.projectId === colDef?.field,
  )?.hours;

  const [value, setValue] = useState(
    node?.data?.hoursAllocation?.find(
      (item) => item?.projectId === colDef?.field,
    )?.hours,
  );
  const handleInputChange = (e) => {
    const inputValue = e?.target?.value;
    const numericValue = parseInt(inputValue?.slice(0, 2), 10);
    setValue(numericValue);
  };

  const handleInputBlur = () => {
    if (value !== oldValue) {
      handleUpdateRecord(value, colDef?.field, record, colDef?.headerName);
    }
  };

  const handleInputFocus = () => {
    // eslint-disable-next-line no-undef
    prevInputRef.current = document?.activeElement;
  };

  const handleKeyDown = (e) => {
    if (DISALLOWED_KEYS?.includes(e?.key)) {
      e?.preventDefault();
    }
    setInputKey(e?.key);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const cellElement = document?.querySelector('.ag-cell-focus');
    if (cellElement) {
      const inputElement = cellElement?.querySelector('input');
      if (inputElement) {
        inputElement?.focus();
      }
    }
  }, [value, inputKey]);

  return (
    <Input
      ref={inputRef}
      disabled={result || !isCreatePermission}
      className="resource-allocation-input"
      value={parseInt(value, 10) || ''}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      onFocus={handleInputFocus}
      onPressEnter={() => {
        if (inputRef?.current) inputRef?.current?.blur();
      }}
      onKeyDown={handleKeyDown}
    />
  );
};

export default InputCellRenderer;
