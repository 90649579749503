import { gql } from '@apollo/client';

export const MODULE_LISTING = gql`
  query Modules($filters: ModuleFilterInput) {
    modules(filters: $filters) {
      count
      data {
        id
        name
      }
    }
  }
`;

export const ROLE_LISTING = gql`
  query RolePermission($where: PermissionUniqueInput) {
    rolePermission(where: $where) {
      id
      name
      permissions {
        access {
          create
          delete
          read
          update
        }
        moduleId
        moduleName
      }
      createdAt
      updatedAt
    }
  }
`;

export const ROLE_ACCESS = gql`
  query RolePermissions($filters: PermissionRolesFilterInput) {
    rolePermissions(filters: $filters) {
      count
      data {
        id
        name
      }
    }
  }
`;
