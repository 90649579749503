import { UserOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Result,
} from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  BACKEND_DATE_FORMAT,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import VerifyPermissions from '../../components/VerifyPermissions';
import { RESUME_LINK } from './graphql/Mutations';
import { DESIGNATIONS, USERS } from './graphql/Queries';

const { required, email } = formValidatorRules;

function Users() {
  const [form] = Form?.useForm();
  const [paginationFilter, setPaginationFilter] = useState({
    currentPage: 1,
    pageSize: LIMIT,
  });
  const [initialFilter, setInitialFilter] = useState({
    skip: 0,
    searchStrings: [],
    sortOrder: 'ASC',
    sortField: 'displayName',
    designation: null,
    departmentId: null,
  });
  const [resumeModal, setResumeModal] = useState({
    open: false,
    currentStep: 0,
  });
  const totalSteps = 2;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [displayName, setDisplayName] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [resumeFormData, setResumeFormData] = useState({});
  const [showSuccessResult, setShowSuccessResult] = useState(false);
  const [designationSearch, setDesignationSearch] = useState('');
  const isUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.SHARED_RESUMES,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });

  const [executeQuery, { loading, data }] = useLazyQuery(USERS, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const [resumeLink, { loading: resumeLinkLoading }] = useMutation(
    RESUME_LINK,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        form?.resetFields();
        setShowSuccessResult(true);
        setSelectedRowKeys([]);
      },
      onError: () => {
        setResumeModal({ open: true, currentStep: 0 });
      },
    },
  );

  useEffect(() => {
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          limit: paginationFilter?.pageSize,
        },
      },
    });
  }, []);

  const handleDesignationSearch = debounce((value) => {
    setDesignationSearch(value);
  }, 500);

  const handleSearch = debounce((value) => {
    const { pageSize } = paginationFilter;
    const newPaginationFilter = { ...paginationFilter, currentPage: 1 };
    const newInitialFilter = {
      ...initialFilter,
      skip: 0,
      searchStrings:
        value?.length > 0 ? value?.toString()?.trim()?.split(',') : [],
    };
    setPaginationFilter(newPaginationFilter);
    setInitialFilter(newInitialFilter);
    setDesignationSearch('');
    executeQuery({
      variables: {
        filters: {
          ...newInitialFilter,
          skip: 0,
          limit: pageSize,
          searchStrings:
            value?.length > 0 ? value?.toString()?.trim()?.split(',') : [],
        },
      },
    });
  }, 700);

  const onChange = (pagination, filters, sorter) => {
    const { current: currentPage, pageSize } = pagination;
    const { order, field } = sorter;
    const filter = {
      skip: currentPage ? (currentPage - 1) * pageSize : 0,
      sortOrder: order === 'descend' ? 'DESC' : 'ASC',
      sortField: field,
    };
    setInitialFilter({ ...initialFilter, ...filter });
    setPaginationFilter({ ...paginationFilter, currentPage, pageSize });
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          skip: filter?.skip,
          limit: pageSize,
          sortField: initialFilter?.sortField || filter?.sortField,
          sortOrder: filter?.sortOrder,
        },
      },
    });
  };

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setDisplayName(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      displayName: record?.displayName,
    }),
  };

  const handleCheckboxChange = (e) => {
    setShowDatePicker(e?.target?.checked);
  };

  const disabledDate = (current) =>
    current && current < dayjs()?.startOf('day');

  const resumeUserName = displayName
    ?.map((row) => row?.displayName)
    ?.join(', ');

  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (value, record) => <span> {record?.displayName}</span>,
    },
    {
      title: 'Designation',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      render: (value, record) => <span> {record?.jobTitle}</span>,
    },
    {
      title: 'Department',
      dataIndex: ['department', 'name'],
      key: 'department',
      render: (value, record) => <span> {record?.department?.name}</span>,
    },
  ];

  const renderMaxTag = (omittedValues) => {
    if (omittedValues?.length === 0) {
      return null;
    }
    return <span>+ {omittedValues?.length} more...</span>;
  };

  const onFinish = async () => {
    try {
      const {
        shortenName,
        hideProfilePicture,
        sharedResumeExpiryDate,
        sharedTo,
      } = resumeFormData;
      if (resumeModal?.currentStep < totalSteps) {
        setResumeModal({
          ...resumeModal,
          currentStep: resumeModal?.currentStep + 1,
        });
      } else {
        setResumeModal({ open: false, currentStep: 0 });
      }
      if (resumeModal?.currentStep === 1) {
        await resumeLink({
          variables: {
            input: {
              sharedUserResumeIds: selectedRowKeys,
              sharedTo,
              shortenName: shortenName || false,
              hideProfilePicture: hideProfilePicture || false,
              sharedResumeExpiryDate:
                sharedResumeExpiryDate?.format(BACKEND_DATE_FORMAT) || null,
            },
          },
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('Error', error?.message);
    }
  };

  const handleValueChange = (allValues) => {
    setResumeFormData({ ...resumeFormData, ...allValues });
  };

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Select employees whose resumes you want to share with client's"
        extra={
          <div className="project-filter-right">
            <div className="project-filter">
              {isUpdatePermission && (
                <Button
                  key="1"
                  type="primary"
                  disabled={selectedRowKeys?.length <= 0}
                  onClick={() => {
                    setResumeModal({ open: true, currentStep: 0 });
                    setResumeFormData({});
                    setShowSuccessResult(false);
                  }}
                >
                  Share Resume
                </Button>
              )}
              <span className="resume-select-tags">
                <CommonSelect
                  mode="tags"
                  placeholder="Search Name, Department, Designation"
                  showSearch
                  query={DESIGNATIONS}
                  onChange={handleSearch}
                  onSearch={handleDesignationSearch}
                  variables={{ searchString: designationSearch }}
                  useEffectDeps={[designationSearch]}
                  responsePath="designations.designations"
                  valuePath="jobTitle"
                  labelPath="jobTitle"
                  fetchPolicy="network-only"
                  onClear={() =>
                    setInitialFilter({
                      ...initialFilter,
                      searchStrings: [],
                    })
                  }
                  value={initialFilter?.searchStrings}
                  maxTagCount={3}
                  maxTagPlaceholder={renderMaxTag}
                  virtual={false}
                />
              </span>
            </div>
          </div>
        }
      >
        <div className="card-body-wrapper">
          <CommonTable
            size="small"
            columns={columns}
            dataSource={data?.usersAdminSharedResumes?.users}
            rowKey={(obj) => obj?.id}
            onChange={onChange}
            loadingData={loading}
            bordered
            paginationConfig={{
              current: paginationFilter?.currentPage,
              total: data?.usersAdminSharedResumes?.count,
              pageSize: paginationFilter?.pageSize,
            }}
            rowSelection={
              isUpdatePermission && {
                ...rowSelection,
              }
            }
            rowClassName="resume-table-row"
            className="resume-table"
          />
        </div>
      </Card>
      <Modal
        title="Configure And Share"
        className="resume-user-modal"
        centered
        open={resumeModal?.open}
        maskClosable={false}
        onOk={() => form?.submit()}
        onCancel={() => setResumeModal({ open: false, currentStep: 0 })}
        okText={
          // eslint-disable-next-line no-nested-ternary
          resumeModal?.currentStep === 0
            ? 'Next'
            : resumeModal?.currentStep === 1
            ? 'Share'
            : 'Okay'
        }
      >
        <>
          {resumeModal?.currentStep === 0 && (
            <p>
              Sharing Resumes of{' '}
              <span className="user-resume-name">{resumeUserName}</span>. Before
              you share, please consider the following options:
            </p>
          )}
          <Form
            form={form}
            layout="vertical"
            name="resumeModalForm"
            onFinish={onFinish}
            onValuesChange={handleValueChange}
          >
            {resumeModal?.currentStep === 0 && (
              <>
                <Form.Item
                  name="shortenName"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Checkbox>Shorten the Name of employee(s)</Checkbox>
                </Form.Item>
                <Form.Item
                  name="hideProfilePicture"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Checkbox>Hide profile picture</Checkbox>
                </Form.Item>
                <Form.Item
                  name="resumeExpiryDate"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Checkbox onChange={handleCheckboxChange}>
                    Set Expiry Date for the receiver
                  </Checkbox>
                </Form.Item>
                {showDatePicker && (
                  <Form.Item
                    name="sharedResumeExpiryDate"
                    className="mb-0 expiry-date-picker"
                  >
                    <DatePicker disabledDate={disabledDate} />
                  </Form.Item>
                )}
              </>
            )}
            {resumeModal?.currentStep === 1 && (
              <Form.Item
                label="Email"
                name="sharedTo"
                rules={[{ required, message: 'Please Enter Email!' }, email]}
                className="resume-email mb-0"
              >
                <Input prefix={<UserOutlined />} placeholder="Enter Email" />
              </Form.Item>
            )}
            {resumeLinkLoading && (
              <LoaderComponent size="default" setHeight={50} />
            )}
            {showSuccessResult && (
              <Result
                status="success"
                className="resume-success-message"
                title="Link for the selected Resume(s) sent Successfully!"
              />
            )}
          </Form>
        </>
      </Modal>
    </div>
  );
}

export default Users;
