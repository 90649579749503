import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation adminLogin($input: adminLoginInput!) {
    adminLogin(input: $input) {
      accessToken
      refreshToken
      message
      user {
        id
        email
        profileImage
        firstName
        lastName
        role
      }
    }
  }
`;
export const GOOGLE_LOGIN = gql`
  mutation userLogin($input: userLoginInput!) {
    userLogin(input: $input) {
      accessToken
      refreshToken
      message
      authUrl
      user {
        email
        jobTitle
        firstName
        lastName
        profileImage
        mobileNo
        displayName
        level {
          id
          name
          description
        }
        roleId
        role
        isIntern
        permissionRole {
          id
          name
          permissions {
            access {
              create
              delete
              read
              update
            }
            moduleId
            moduleName
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const NEW_ACCESS_TOKEN = gql`
  mutation newAccessToken($refreshToken: String!) {
    newAccessToken(refreshToken: $refreshToken)
  }
`;
