import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_METABASE_REPORTS = gql`
  query GetMetabaseReports($filters: MetabaseReportListFilters) {
    getMetabaseReports(filters: $filters) {
      count
      data {
        id
        title
        url
        permissions
        createdAt
        updatedAt
      }
    }
  }
`;
