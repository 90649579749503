import { gql } from '@apollo/client';

export const CREATE_LEAVE = gql`
  mutation CreateLeaveRequestAdmin($data: CreateLeaveRequestAdminInput!) {
    createLeaveRequestAdmin(data: $data) {
      message
      data {
        createdAt
        dayType
        id
        startDate
        endDate
        fromLeaveType
        toLeaveType
        reason
        type
        status
        planned
        user {
          id
          displayName
          firstName
          lastName
          jobTitle
          department {
            id
            name
          }
          reportingManager {
            id
            displayName
            firstName
            lastName
            jobTitle
            profileImage
          }
          profileImage
          level {
            id
            name
            description
          }
        }
        noOfDays
        pollId
        requestedOn
        leaveRedirectUrl
        updatedAt
        source
        duration
        leaveStatusHistory {
          status
          reason
          createdAt
          actionByUser {
            id
            displayName
            firstName
            lastName
            jobTitle
            profileImage
          }
        }
      }
    }
  }
`;

export const UPDATE_LEAVE = gql`
  mutation UpdateLeaveByAdmin($data: UserUpdateLeaveRequestInput!) {
    updateLeaveByAdmin(data: $data) {
      message
      data {
        id
        startDate
        endDate
        fromLeaveType
        toLeaveType
        reason
        type
        status
        planned
        user {
          id
          displayName
          firstName
          lastName
          jobTitle
          department {
            id
            name
          }
          reportingManager {
            id
            displayName
            firstName
            lastName
            jobTitle
            profileImage
          }
          profileImage
          level {
            id
            name
            description
          }
        }
        noOfDays
        dayType
        createdAt
        pollId
        requestedOn
        leaveRedirectUrl
        updatedAt
        source
        duration
        leaveStatusHistory {
          status
          reason
          createdAt
          actionByUser {
            id
            displayName
            firstName
            lastName
            jobTitle
            profileImage
          }
        }
      }
    }
  }
`;
