import { PUSHER_EVENTS } from '../common/constants';
import pusherInstance from './Pusher';

export const subscribeToResourceAllocationEvents = ({
  channelName,
  handlers,
  startDate = '',
  endDate = '',
}) => {
  const channel = pusherInstance?.subscribe(channelName);

  if (channel?.name === PUSHER_EVENTS.QUARTERLY_DISTRIBUTION) {
    channel?.bind(
      PUSHER_EVENTS.QUARTERLY_DISTRIBUTION_EVENT,
      handlers?.handleQuarterlyDistribution,
    );
  }
  channel?.bind(
    `${PUSHER_EVENTS.RESOURCE_ALLOCATION_UPDATED}-${startDate}-${endDate}`,
    handlers?.handleUpdate,
  );
  channel?.bind(
    `${PUSHER_EVENTS.RESOURCE_ALLOCATION_CREATED}-${startDate}-${endDate}`,
    handlers?.handleCreate,
  );

  return channel;
};

export const unsubscribeFromResourceAllocationEvents = (
  channel,
  startDate = '',
  endDate = '',
) => {
  if (channel === PUSHER_EVENTS.QUARTERLY_DISTRIBUTION) {
    channel?.unbind(PUSHER_EVENTS.QUARTERLY_DISTRIBUTION_EVENT);
  }
  channel?.unbind(
    `${PUSHER_EVENTS.RESOURCE_ALLOCATION_UPDATED}-${startDate}-${endDate}`,
  );
  channel?.unbind(
    `${PUSHER_EVENTS.RESOURCE_ALLOCATION_CREATED}-${startDate}-${endDate}`,
  );
};
