import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES_MODULES_KEY } from '../../common/constants';
import EmailDesign from './EmailDesign';

const EmailDesignWrapper = () => (
  <>
    <Switch>
      <Route
        path={`${ROUTES_MODULES_KEY?.EMAIL_SIGNATURE}`}
        exact
        component={EmailDesign}
      />
    </Switch>
  </>
);
export default EmailDesignWrapper;
