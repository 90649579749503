import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import Error404 from '../../Error404';
import { MODULES_KEY, PERMISSION_OBJECT, ROUTES } from '../../common/constants';
import Leave from './Leave';
import './leave.less';

const LeaveWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.LEAVES}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.LEAVES}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <Leave {...props} />
        </AccessControl>
      )}
    />

    <Route path="*" component={Error404} />
  </Switch>
);

export default LeaveWrapper;
