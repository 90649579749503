import { gql } from '@apollo/client';

export const CALENDAR_LISTING = gql`
  query Calendar($filters: UserFiltersInput) {
    calendar(filters: $filters) {
      count
      userList {
        id
        firstName
        lastName
        displayName
        profileImage
        jobTitle
        department {
          id
          name
        }
        leaves {
          id
          startDate
          endDate
          fromLeaveType
          toLeaveType
          type
          status
        }
        workFromHomes {
          id
          startDate
          endDate
          toType
          fromType
          status
        }
      }
      holidays {
        id
        name
        description
        startDate
        endDate
      }
    }
  }
`;

export const GET_CONFIG = gql`
  query ConfigData {
    getConfig {
      configData {
        id
        key
        value
      }
      count
    }
  }
`;

export const DESIGNATIONS = gql`
  query designations($searchString: String, $filters: DesignationFiltersInput) {
    designations(searchString: $searchString, filters: $filters) {
      count
      designations {
        jobTitle
      }
    }
  }
`;
