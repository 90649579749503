import { gql } from '@apollo/client';

export const CREATE_QUARTER_RESOURCE = gql`
  mutation CreateQuarterView($data: CreateQuarterViewInput!) {
    createQuarterView(data: $data) {
      message
    }
  }
`;

export const CREATE_QUARTER_DISTRIBUTION = gql`
  mutation CreateQuarterlyDistribution(
    $data: CreateQuarterlyDistributionInput!
  ) {
    createQuarterlyDistribution(data: $data) {
      message
    }
  }
`;

export const UPDATE_QUARTER_DISTRIBUTION = gql`
  mutation UpdateQuarterlyDistribution(
    $data: UpdateQuarterlyDistributionInput!
  ) {
    updateQuarterlyDistribution(data: $data) {
      message
    }
  }
`;

export const DELETE_QUARTER_DISTRIBUTION = gql`
  mutation DeleteQuarterlyDistribution(
    $where: DeleteQuarterlyDistributionInput!
  ) {
    deleteQuarterlyDistribution(where: $where) {
      message
    }
  }
`;
