import {
  ArrowLeftOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Modal, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EVENT_STATUS, ROUTES_MODULES_KEY } from '../../../common/constants';
import CommonButton from '../../../components/CommonButton';
import LoaderComponent from '../../../components/LoaderComponent';
import { PUBLISH_EVENT, UPDATE_EVENT } from '../graphql/Mutation';
import { GET_SINGLE_EVENT } from '../graphql/Queries';
import EventForm from './EventForm';

const EventAddEdit = ({ isEdit, isDuplicate }) => {
  const history = useHistory();
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true);

  const [fetchSingleEvent, { data, refetch }] = useLazyQuery(GET_SINGLE_EVENT, {
    fetchPolicy: 'network-only',
    onCompleted() {
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  const [updateEvent, { loading: publishing }] = useMutation(UPDATE_EVENT, {
    onCompleted() {
      refetch();
    },
  });

  const [publishEvent] = useMutation(PUBLISH_EVENT, {
    onCompleted() {
      refetch();
    },
  });

  useEffect(() => {
    if (eventId) {
      fetchSingleEvent({
        variables: {
          eventId,
        },
      });
    } else {
      setLoading(false);
    }
  }, [eventId]);

  const onDuplicate = () => {
    Modal?.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Duplicate Event',
      content: 'Are you sure you want to duplicate this event?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      className: 'custom-modal-button',
      onOk: () => {
        history.push(
          `${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}/duplicate/${eventId}`,
        );
      },
    });
  };

  const onPublishEvent = ({
    name,
    categoryId,
    typeId,
    startDate,
    endDate,
    status,
    id,
  }) => {
    Modal?.confirm({
      icon: <ExclamationCircleOutlined />,
      title: `${
        status === EVENT_STATUS.PUBLISHED ? 'Un-Publish' : 'Publish'
      } Event`,
      content: `Are you sure, you want to ${
        status === EVENT_STATUS.PUBLISHED ? 'Un-Publish' : 'Publish'
      } this event?`,
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      className: 'custom-modal-button',
      okButtonProps: { publishing },
      onOk: async () =>
        status === EVENT_STATUS.PUBLISHED
          ? updateEvent({
              variables: {
                data: {
                  name,
                  categoryId,
                  typeId,
                  startDate,
                  endDate,
                  status:
                    status === EVENT_STATUS.PUBLISHED
                      ? EVENT_STATUS.UNPUBLISHED
                      : EVENT_STATUS.PUBLISHED,
                },
                updateEventId: id,
              },
            })
          : publishEvent({
              variables: {
                publishEventId: id,
              },
            }),
    });
  };

  const initialValues = {
    categoryId: data?.event?.data?.categoryId,
    typeId: data?.event?.data?.typeId,
    name: data?.event?.data?.name,
    start_date: data?.event?.data?.startDate
      ? dayjs(data?.event?.data?.startDate)
      : undefined,
    start_time: data?.event?.data?.startDate
      ? dayjs(data?.event?.data?.startDate)
      : undefined,
    end_time: data?.event?.data?.endDate
      ? dayjs(data?.event?.data?.endDate)
      : undefined,
    ministries: data?.event?.data?.ministries || [],
    isVirtualEvent: data?.event?.data?.isVirtualEvent,
    locationName: data?.event?.data?.locationName,
    streetAddress: data?.event?.data?.streetAddress,
    suiteNo: data?.event?.data?.suiteNo,
    city: data?.event?.data?.city,
    state: data?.event?.data?.state,
    zipCode: data?.event?.data?.zipcode,
    notes: data?.event?.data?.notes,
    additionalPhotos: data?.event?.data?.additionalPhotos,
    virtualEventUrl: data?.event?.data?.virtualEventUrl,
    description: data?.event?.data?.description,
    contacts: data?.event?.data?.contacts,
    allowSpouse: data?.event?.data?.allowSpouse,
    availabilityDate: data?.event?.data?.availabilityDate
      ? dayjs(data?.event?.data?.availabilityDate)
      : undefined,
    noOfGuests: data?.event?.data?.noOfGuests,
    maxAttendees: data?.event?.data?.maxAttendees,
    isEntryFee: data?.event?.data?.isEntryFee,
    entryFee: data?.event?.data?.entryFee,
    paymentUrl: data?.event?.data?.paymentUrl,
    isDonationFee: data?.event?.data?.isDonationFee,
    donationFee: data?.event?.data?.donationFee,
    donationUrl: data?.event?.data.donationUrl,
    noOfWaitList: data?.event?.data?.noOfWaitlist,
    isVisible: data?.event?.data?.isVisible,
    registration_start_date: data?.event?.data?.registrationStartDate
      ? dayjs(data?.event?.data?.registrationStartDate)
      : undefined,
    registration_close_date: data?.event?.data?.registrationCloseDate
      ? dayjs(data?.event?.data?.registrationCloseDate)
      : undefined,
    registration_start_time: data?.event?.data?.registrationStartDate
      ? dayjs(data?.event?.data?.registrationStartDate)
      : undefined,
    registration_close_time: data?.event?.data?.registrationCloseDate
      ? dayjs(data?.event?.data?.registrationCloseDate)
      : undefined,
    additionalPdfs: data?.event?.data?.pdfDetails,
    feedbackCloseDate: data?.event?.data?.feedbackCloseDate
      ? dayjs(data?.event?.data?.feedbackCloseDate)
      : undefined,
    feedbackStartDate: data?.event?.data?.feedbackStartDate
      ? dayjs(data?.event?.data?.feedbackStartDate)
      : undefined,
    isTableSponsored: data?.event?.data?.isTableSponsored,
    seatsPerTable: data?.event?.data?.seatsPerTable,
    pricePerTable: data?.event?.data?.pricePerTable,
    headerImage: data?.event?.data?.headerImage,
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <Card
        size="small"
        className="ant-body-scroll event-card"
        title={
          <div className="d-flex align-center">
            <Button
              type="text"
              shape="square"
              onClick={() =>
                history.push(`${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}`)
              }
              icon={<ArrowLeftOutlined />}
            />
            {isEdit && 'Edit Event'} {!eventId && 'Create Event'}{' '}
            {isDuplicate && 'Duplicate Event'}
          </div>
        }
        extra={
          isEdit && (
            <div className="d-flex">
              <Tooltip title="Duplicate Event">
                <CommonButton
                  className="align-center d-flex mr-8"
                  onClick={onDuplicate}
                >
                  <CopyOutlined />
                  Duplicate
                </CommonButton>
              </Tooltip>
              <Tooltip
                title={
                  data?.event?.data?.status === 'PUBLISHED'
                    ? 'Unpublish Event'
                    : 'Publish Event'
                }
              >
                <CommonButton
                  type="primary"
                  onClick={() => {
                    onPublishEvent(data?.event?.data);
                  }}
                >
                  {data?.event?.data?.status === 'PUBLISHED'
                    ? 'Unpublish Event'
                    : 'Publish Event'}
                </CommonButton>
              </Tooltip>
            </div>
          )
        }
      >
        <div className="card-body-wrapper user-detail-page">
          <EventForm
            isEdit={isEdit || null}
            isDuplicate={isDuplicate || null}
            initialValues={(isEdit || isDuplicate) && initialValues}
            data={data}
          />
        </div>
      </Card>
    </>
  );
};

export default EventAddEdit;
