import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import Error404 from '../../Error404';
import {
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import Resume from './Resume';
import Users from './User';
import ViewUser from './ViewUser';
import './user.less';

const UsersWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES_MODULES_KEY?.USERS}`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.USERS}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <Users {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES_MODULES_KEY?.USERS}/create`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.USERS}
          allowedPermissions={[PERMISSION_OBJECT?.create]}
          showNoAccess
        >
          <ViewUser {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SETTINGS}${ROUTES?.VIEW_USER}`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.USERS}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <ViewUser {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.SETTINGS}${ROUTES?.USER_RESUME}`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.USERS}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <Resume {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" component={Error404} />
  </Switch>
);

export default UsersWrapper;
