import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const HOLIDAYS = gql`
  query holidaysAdmin($filter: HolidaysFilter!) {
    holidaysAdmin(filters: $filter) {
      holidays {
        id
        name
        startDate
        endDate
        description
        recurring
      }
      count
    }
  }
`;
