import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import LoaderComponent from '../../components/LoaderComponent';
import UserResume from '../auth/geustLogin/components/UserResume';
import { EMPLOYEE_RESUME } from '../auth/geustLogin/graphql/Queries';

const Resume = () => {
  const { userId } = useParams();
  const { data, loading } = useQuery(EMPLOYEE_RESUME, {
    fetchPolicy: 'network-only',
    onError: () => {},
    variables: {
      input: {
        userId,
      },
    },
  });

  if (loading) {
    return <LoaderComponent size="default" />;
  }

  return <UserResume data={data} />;
};

export default Resume;
