import { gql } from '@apollo/client';

export const CREATE_HOLIDAY = gql`
  mutation createHoliday($data: CreateHolidayInput!) {
    createHoliday(input: $data) {
      holiday {
        id
        name
        startDate
        endDate
        description
        recurring
      }
      message
    }
  }
`;
export const UPDATE_HOLIDAY = gql`
  mutation updateHoliday($data: UpdateHolidayInput!, $where: UpdateHolidayID!) {
    updateHoliday(input: $data, where: $where) {
      holiday {
        id
        name
        startDate
        endDate
        description
        recurring
      }
      message
    }
  }
`;
export const DELETE_HOLIDAY = gql`
  mutation deleteHoliday($data: DeleteHolidayInput!) {
    deleteHoliday(input: $data) {
      message
    }
  }
`;
