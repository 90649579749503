import { filter, includes, map } from 'lodash';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { MODULES_KEY, ROLE_KEYS } from '../common/constants';

const VerifyPermissions = ({ modulekey, allowedPermissions }) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);

  if ([ROLE_KEYS.USER, ROLE_KEYS.INTERN]?.includes(currentUser?.role)) {
    if (
      modulekey === MODULES_KEY?.ROLE ||
      !currentUser?.permissionRole?.permissions
    ) {
      return false;
    }
    const modulePermission = filter(
      currentUser?.permissionRole?.permissions,
      (data) => data?.moduleName === modulekey,
    );
    if (modulePermission?.[0]?.access) {
      const permission = map(
        filter(Object?.keys(modulePermission?.[0]?.access), (data) =>
          includes(allowedPermissions, data),
        ),
        (data) => modulePermission?.[0]?.access?.[data],
      )?.every((record) => record === true);
      return permission;
    }
  }
  return true;
};
export default VerifyPermissions;
