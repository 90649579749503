import { gql } from '@apollo/client';

export const GET_OTP = gql`
  mutation GetOtp($input: GetOtpInput!) {
    getOtp(input: $input) {
      message
    }
  }
`;

export const VERIFY_EMAIL_BY_OTP = gql`
  mutation VerifyEmailByOtp($input: VerifyEmailByOtpInput!) {
    verifyEmailByOtp(input: $input) {
      message
      clientId
    }
  }
`;

export const RESUME_LINK = gql`
  mutation SharedResumesWithConfigurations(
    $input: ShareResumesWithConfigurationsInput!
  ) {
    sharedResumesWithConfigurations(input: $input) {
      message
    }
  }
`;
