import {
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import {
  Badge,
  Button,
  Card,
  DatePicker,
  Popover,
  Select,
  Space,
  Switch,
  Tooltip,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { debounce, find, flattenDeep, map, some, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import api from '../../common/api';
import {
  BACKEND_DATE_FORMAT,
  COLUMN_COLOR,
  ERROR_MESSAGE,
  LEVEL,
  LIMIT,
  PROJECT_COLUMN_TYPE,
  PUSHER_EVENTS,
  ROLE_KEYS,
  ROUTES,
  ROW_COLOR,
  ROW_HEIGHT,
  SESSION_EXPIRED,
  SYNC_MESSAGE,
  USER,
  WEEK_FORMAT,
} from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import EmptyComponents from '../../components/EmptyComponents';
import LoaderComponent from '../../components/LoaderComponent';
import {
  subscribeToResourceAllocationEvents,
  unsubscribeFromResourceAllocationEvents,
} from '../../components/PusherEvents';
import history from '../../historyData';
import { ALL_TAGS, DEPARTMENTS } from '../users/graphql/Queries';
import HeaderComponent from './components/HeaderComponent';
import InputCellRenderer from './components/InputCellRenderer';
import NameCellRenderer from './components/NameCellRenderer';
import PlannedCellRenderer from './components/PlannedCellRenderer';
import {
  CREATE_RESOURCE_ALLOCATION,
  UPDATE_RESOURCE_ALLOCATION,
} from './graphql/Mutation';
import {
  ACTIVE_PROJECTS,
  PROJECTS,
  RESOURCE_ALLOCATION,
} from './graphql/Queries';
import './resourceAllocation.less';

function ResourceAllocationTesting(stateProps) {
  const {
    location: { state },
  } = stateProps;
  // eslint-disable-next-line no-undef
  const user = JSON.parse(localStorage.getItem(USER));
  const defaultSkip = 0;
  const projectStatus = 'ACTIVE';
  const [searchString, setSearchString] = useState([]);
  const [startDate, setStartDate] = useState(
    state?.startDate
      ? dayjs(state?.startDate)
      : dayjs()?.weekday(1)?.add(1, 'week')?.format(BACKEND_DATE_FORMAT),
  );
  const [endDate, setEndDate] = useState(
    state?.endDate
      ? dayjs(state?.endDate)
      : dayjs()?.weekday(5)?.add(1, 'week')?.format(BACKEND_DATE_FORMAT),
  );
  const [weekPickerValue, setWeekPickerValue] = useState(
    state?.weekPickerValue instanceof dayjs
      ? state.weekPickerValue
      : dayjs().add(1, 'week'),
  );
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [projectFilterData, setProjectFilterData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const pathName = history?.location?.pathname;
  const [isProjectDataEnd, setIsProjectDataEnd] = useState(false);
  const [skipData, setSkipData] = useState(0);
  const [search, setSearch] = useState('');
  const [tagSearch, setTagSearch] = useState('');
  const [departmentFilteredId, setDepartmentFilteredId] = useState();
  const [departmentSearch, setDepartmentSearch] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([]);
  const [inputColId, setInputColId] = useState(null);
  const [inputRowId, setInputRowId] = useState(null);
  const filteredColumns = useMemo(() => {
    if (selectedProjects?.length > 0) {
      const defaultColumns = columnDefs?.slice(0, 3);
      const remainingColumns = columnDefs
        ?.slice(3)
        ?.filter(({ field }) => selectedProjects?.includes(field))
        ?.map((column) => ({ ...column, width: 'auto', flex: 1 }));
      return [...defaultColumns, ...remainingColumns];
    }
    return columnDefs;
  }, [selectedProjects, columnDefs]);

  const [executeQuery, { loading: resourceLoading }] = useLazyQuery(
    RESOURCE_ALLOCATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ resourceAllocations }) =>
        setRowData(uniqBy(resourceAllocations, 'id') ?? []),
      onError: () => {},
    },
  );
  const [executeProjectQuery] = useLazyQuery(PROJECTS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setIsProjectDataEnd(
        res?.projectsResourceAllocation?.projects?.length < LIMIT,
      );
      const newData = res?.projectsResourceAllocation?.projects?.map(
        (mappedProject) => ({
          value: mappedProject?.id,
          label: mappedProject?.name,
        }),
      );
      setProjectData((prevData) => uniqBy([...prevData, ...newData], 'value'));
    },
    onError: () => {},
  });

  const [updateMutation, { loading: updateLoading }] = useMutation(
    UPDATE_RESOURCE_ALLOCATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setIsSaved(true);
      },
      onError: () => {},
    },
  );

  const [createMutation, { loading: createLoading }] = useMutation(
    CREATE_RESOURCE_ALLOCATION,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setIsSaved(true);
      },
      onError: () => {},
    },
  );

  const handleUpdateRecord = async (
    newValue,
    projectId,
    record,
    projectName,
  ) => {
    try {
      const hoursValue = parseInt(newValue, 10);
      const existingRecord = record?.hoursAllocation?.find(
        (item) => item?.projectId === projectId,
      );
      if (existingRecord) {
        const updatedRecord = {
          ...existingRecord,
          hours: hoursValue,
        };
        setIsSaved(false);
        await updateMutation({
          variables: {
            input: {
              hours: updatedRecord?.hours,
              id: updatedRecord?.id,
            },
          },
        });
      } else {
        const createdRecord = {
          projectId,
          projectName,
          hours: hoursValue,
        };
        setIsSaved(false);
        await createMutation({
          variables: {
            input: {
              hours: createdRecord?.hours,
              projectId: createdRecord?.projectId,
              resourceAllocationId: record?.id,
            },
          },
        });
      }
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console?.error('Save failed:', errInfo);
    }
  };

  const result = weekPickerValue?.isBefore(dayjs().weekday(1), 'day');

  const [activeProjectColumns, { loading: projectLoading }] = useLazyQuery(
    ACTIVE_PROJECTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (selectedItems?.length > 0) {
          const newData = res?.activeProjects?.map((mappedProject) => ({
            value: mappedProject?.id,
            label: mappedProject?.name,
          }));
          setProjectFilterData(() => uniqBy([...newData], 'value'));
        }
        const newData = res?.activeProjects?.map((mappedProject) => ({
          headerComponent: HeaderComponent,
          headerComponentParams: {
            headerName: mappedProject?.name,
            field: mappedProject?.id,
            pathName,
            weekPickerValue,
            startDate,
            endDate,
          },
          autoHeaderHeight: true,
          field: mappedProject?.id,
          headerClass: find(mappedProject?.tags, (tag) =>
            some(PROJECT_COLUMN_TYPE, { name: tag }),
          ),
          cellClass: 'resource-cell-align',
          wrapText: true,
          minWidth: 60,
          width: selectedItems?.length > 0 || isShow ? 'auto' : 60,
          flex: selectedItems?.length > 0 || isShow ? 1 : 'auto',
          cellRenderer: (params) => (
            <InputCellRenderer
              result={result}
              handleUpdateRecord={handleUpdateRecord}
              {...params}
            />
          ),
          resizable: true,
        }));
        const defaultColumns = [
          {
            headerName: 'Name',
            field: 'user.displayName',
            width: 200,
            pinned: 'left',
            lockPinned: true,
            editable: false,
            headerClass: 'header-text-center',
            cellClass: 'lock-pinned',
            suppressMenu: false,
            resizable: false,
            cellRenderer: (params) => (
              <NameCellRenderer
                pathName={pathName}
                weekPickerValue={weekPickerValue}
                startDate={startDate}
                endDate={endDate}
                {...params}
              />
            ),
          },
          {
            headerName: 'Total',
            field: 'totalHours',
            headerClass: 'header-text-center',
            maxWidth: 80,
            pinned: 'left',
            lockPinned: true,
            editable: false,
            suppressMenu: false,
            resizable: false,
            cellClass:
              'lock-pinned resource-cell-align name-column-displayName',
          },
          {
            headerName: 'Planned',
            field: 'plannedHours',
            headerClass: 'header-text-center',
            maxWidth: 80,
            pinned: 'left',
            lockPinned: true,
            resizable: false,
            editable: false,
            suppressMenu: false,
            cellClass:
              'lock-pinned resource-cell-align name-column-displayName',
            cellRenderer: (params) => <PlannedCellRenderer {...params} />,
          },
        ];
        setColumnDefs(() => uniqBy([...defaultColumns, ...newData], 'field'));
      },
      onError: () => {},
    },
  );

  useEffect(() => {
    activeProjectColumns({
      variables: {
        filters: {
          startDate,
          isShow,
        },
      },
    });
    executeQuery({
      variables: {
        filters: {
          searchString,
          startDate,
          endDate,
        },
      },
    });
    executeProjectQuery({
      variables: {
        filters: {
          skip: defaultSkip,
          limit: LIMIT,
          status: projectStatus,
        },
      },
    });
  }, []);

  useEffect(() => {
    const channel = subscribeToResourceAllocationEvents({
      channelName: PUSHER_EVENTS.RESOURCE_ALLOCATION,
      startDate,
      endDate,
      handlers: {
        handleUpdate: (data) => {
          setRowData((prev) =>
            prev?.map((record) => {
              if (record?.id === data?.resourceAllocationId) {
                const hoursAllocation = Array?.isArray(record?.hoursAllocation)
                  ? record?.hoursAllocation?.map((allocation) => {
                      if (
                        allocation?.id === data?.resourceProjectAllocationId
                      ) {
                        return { ...allocation, hours: data?.hours };
                      }
                      return allocation;
                    })
                  : [];
                return {
                  ...record,
                  plannedHours: data?.plannedHours,
                  hoursAllocation,
                };
              }
              return record;
            }),
          );
        },

        handleCreate: (data) => {
          setRowData((prev) =>
            prev?.map((record) => {
              if (record?.id === data?.resourceAllocationId) {
                const hoursAllocation = [
                  ...(record?.hoursAllocation || []),
                  {
                    id: data?.resourceProjectAllocationId,
                    hours: data?.hours,
                    projectId: data?.projectId,
                  },
                ];
                return {
                  ...record,
                  plannedHours: data?.plannedHours,
                  hoursAllocation,
                };
              }
              return record;
            }),
          );
        },
      },
    });

    return () => {
      unsubscribeFromResourceAllocationEvents(channel, startDate, endDate);
    };
  }, [startDate, endDate, columnDefs]);

  const handleDatepicker = (date) => {
    const weekStartDate = dayjs(date)?.weekday(1)?.format(BACKEND_DATE_FORMAT);
    const weekEndDate = dayjs(date)?.weekday(5)?.format(BACKEND_DATE_FORMAT);
    setWeekPickerValue(date);
    setStartDate(weekStartDate);
    setEndDate(weekEndDate);
    const queryVariables = {
      filters: {
        startDate: weekStartDate,
        endDate: weekEndDate,
        searchString: searchString?.length > 0 ? searchString : [],
        tagSearch: selectedItems?.length > 0 ? selectedItems : [],
      },
    };
    executeQuery({
      variables: queryVariables,
    });
    activeProjectColumns({
      variables: {
        filters: {
          startDate: weekStartDate,
          ...(selectedItems?.length > 0 && { searchString: selectedItems }),
          isShow,
        },
      },
    });
  };

  const prevWeek = () => {
    const weekStart = dayjs(weekPickerValue)?.weekday(-6);
    const weekEnd = dayjs(weekPickerValue)?.weekday(-2);
    const formattedStartDate = weekStart?.format(BACKEND_DATE_FORMAT);
    const formattedEndDate = weekEnd?.format(BACKEND_DATE_FORMAT);
    setWeekPickerValue(weekStart);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    executeQuery({
      variables: {
        filters: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          searchString: searchString?.length > 0 ? searchString : [],
          tagSearch: selectedItems?.length > 0 ? selectedItems : [],
        },
      },
    });
    activeProjectColumns({
      variables: {
        filters: {
          startDate: formattedStartDate,
          ...(selectedItems?.length > 0 && { searchString: selectedItems }),
          isShow,
        },
      },
    });
  };

  const nextWeek = () => {
    const newWeekStart = dayjs(weekPickerValue)?.weekday(8);
    const newWeekEnd = dayjs(weekPickerValue)?.weekday(12);
    const formattedNewStartDate = newWeekStart?.format(BACKEND_DATE_FORMAT);
    const formattedNewEndDate = newWeekEnd?.format(BACKEND_DATE_FORMAT);
    setWeekPickerValue(newWeekStart);
    setStartDate(formattedNewStartDate);
    setEndDate(formattedNewEndDate);
    executeQuery({
      variables: {
        filters: {
          startDate: formattedNewStartDate,
          endDate: formattedNewEndDate,
          searchString: searchString?.length > 0 ? searchString : [],
          tagSearch: selectedItems?.length > 0 ? selectedItems : [],
        },
      },
    });
    activeProjectColumns({
      variables: {
        filters: {
          startDate: formattedNewStartDate,
          ...(selectedItems?.length > 0 && { searchString: selectedItems }),
          isShow,
        },
      },
    });
  };

  const handleDepartment = (value) => {
    setDepartmentFilteredId(value);
    setSearchString(value?.toString()?.trim()?.split(','));
    setDepartmentSearch('');
    const formattedStartDate = weekPickerValue
      ?.weekday(1)
      ?.format(BACKEND_DATE_FORMAT);
    const formattedEndDate = weekPickerValue
      ?.weekday(5)
      ?.format(BACKEND_DATE_FORMAT);
    executeQuery({
      variables: {
        filters: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          searchString:
            value?.length > 0 ? value?.toString()?.trim()?.split(',') : [],
          tagSearch: selectedItems?.length > 0 ? selectedItems : [],
        },
      },
    });
    activeProjectColumns({
      variables: {
        filters: {
          startDate: formattedStartDate,
          ...(selectedItems?.length > 0 && { searchString: selectedItems }),
          isShow,
        },
      },
    });
  };

  const handleDepartmentSearch = debounce((value) => {
    setDepartmentSearch(value);
  }, 500);

  const handlePopupScroll = async (e) => {
    const nearBottom =
      e?.target?.scrollTop + e?.target?.clientHeight ===
      e?.target?.scrollHeight;
    if (nearBottom && !isProjectDataEnd) {
      const newSkip = skipData + LIMIT;
      await executeProjectQuery({
        variables: {
          filters: {
            skip: newSkip,
            limit: LIMIT,
            status: projectStatus,
          },
        },
      });
      setSkipData(newSkip);
    }
  };

  const handleSearchData = debounce((value) => {
    executeProjectQuery({
      variables: {
        filters: {
          skip: defaultSkip,
          limit: LIMIT,
          searchString: value,
          status: projectStatus,
        },
      },
    });
  }, 700);

  const handleTagChange = (selectedTags) => {
    setSearch('');
    setTagSearch('');
    setSelectedItems(selectedTags);
    executeQuery({
      variables: {
        filters: {
          startDate,
          endDate,
          searchString: searchString?.length > 0 ? searchString : [],
          tagSearch: selectedTags?.length > 0 ? selectedTags : [],
        },
      },
    });
    activeProjectColumns({
      variables: {
        filters: {
          startDate,
          ...(selectedTags?.length > 0 && { searchString: selectedTags }),
          isShow,
        },
      },
    });
  };

  const debouncedUpdateValue = useCallback(
    debounce((newValue) => {
      setSkipData(0);
      setTagSearch(newValue?.toLowerCase());
    }, 500),
    [],
  );

  const updateSearchValue = (newValue) => {
    setSearch(newValue?.toLowerCase());
  };

  const handleTagSearch = (searchText) => {
    debouncedUpdateValue(searchText);
    updateSearchValue(searchText);
  };

  const handleProjectSelect = (value) => {
    setSelectedProjects(value);
    setSearchString([]);
    executeProjectQuery({
      variables: {
        filters: {
          skip: defaultSkip,
          limit: LIMIT,
          status: projectStatus,
        },
      },
    });
  };
  const renderMaxTag = (omittedValues) => {
    if (omittedValues?.length === 0) {
      return null;
    }
    return <span>+ {omittedValues?.length} more...</span>;
  };

  const customWeekStartEndFormat = (value) =>
    `${dayjs(value)?.weekday(1)?.format(WEEK_FORMAT)} - ${dayjs(value)
      ?.weekday(5)
      ?.format(WEEK_FORMAT)}`;

  const handleBeforeUnload = () => {
    history.push({
      pathname: history.location.pathname,
      state: null,
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleSync = async () => {
    const syncUrls = [
      process.env.REACT_APP_SYNC_LEAVES_URL,
      process.env.REACT_APP_SYNC_DASHBOARD_LEAVES_URL,
      process.env.REACT_APP_SYNC_USERS_URL,
      process.env.REACT_APP_SYNC_DEPARTMENT_URL,
    ];
    try {
      await Promise?.all(
        syncUrls?.map(async (url) => {
          await api?.post(url);
        }),
      );
      message?.destroy();
      message?.success(SYNC_MESSAGE);
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.extensions?.code === SESSION_EXPIRED
      ) {
        message?.destroy();
        message?.error(ERROR_MESSAGE);
        history?.replace({
          pathname: ROUTES?.AUTHENTICATION,
          // eslint-disable-next-line no-undef
          state: { from: window?.location?.pathname },
        });
      }
    }
  };

  const handleToggleSwitch = (checked) => {
    setIsShow(checked);
    activeProjectColumns({
      variables: {
        filters: {
          startDate,
          ...(selectedItems?.length > 0 && { searchString: selectedItems }),
          isShow: checked,
        },
      },
    });
  };

  const getRowClass = (params) => {
    const leave =
      params?.data?.leaves && map(flattenDeep(params?.data?.leaves), 'status');
    if (
      leave?.includes(ROW_COLOR.APPROVED) ||
      leave?.includes(ROW_COLOR.APPROVAL_IN_PROCESS)
    ) {
      return 'approved-leave-row-data';
    }
    if (leave?.includes(ROW_COLOR.PENDING)) {
      return 'pending-leave-row-data';
    }
    return null;
  };

  const noRowsOverlayComponent = useMemo(() => EmptyComponents, []);

  const highlightColumn = (colId, color) => {
    // eslint-disable-next-line no-undef
    const cells = document?.querySelectorAll(`.ag-cell[col-id="${colId}"]`);
    cells?.forEach((cell) => {
      // eslint-disable-next-line no-param-reassign
      cell.style.backgroundColor = color;
    });
  };

  const handleCellKey = (e) => {
    setInputColId(e?.column?.colId);
    setInputRowId(e?.rowIndex);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const cells = document?.querySelectorAll('.ag-cell');
    // eslint-disable-next-line no-undef
    const cellElement = document?.querySelector('.ag-cell-focus');
    const cellId = cellElement?.getAttribute('col-id');
    if (cells && cellId) {
      cells?.forEach((cell) => {
        const colId = cell?.getAttribute('col-id');
        highlightColumn(colId, colId === cellId ? COLUMN_COLOR : '');
      });
    }
  }, [inputColId, inputRowId]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const cellElement = document?.querySelector('.ag-cell-focus');
    if (cellElement) {
      const childElements = cellElement?.children;
      if (
        childElements?.length === 1 &&
        childElements[0]?.tagName?.toLowerCase() === 'input'
      ) {
        const inputElement = childElements[0];
        inputElement?.focus();
      }
    }
  }, [inputColId, inputRowId]);

  return (
    <div className="table-card-page">
      <div className="resource-allocation">
        <Card
          className="ant-body-scroll"
          title={
            <span>
              Resource Allocation{' '}
              {(updateLoading || createLoading) && (
                <span className="saving-message">Saving...</span>
              )}
              {isSaved && <span className="saved-message">Saved</span>}
            </span>
          }
          extra={
            <div className="resource-allocation-card">
              <div className="project-color-info">
                <Popover
                  placement="rightTop"
                  title="Project Color Info"
                  content={
                    <Space direction="vertical">
                      {Object.entries(PROJECT_COLUMN_TYPE)?.map(
                        ([key, type]) => (
                          <Badge
                            key={key}
                            className="project-info-badge"
                            color={type?.color}
                            text={type?.name}
                          />
                        ),
                      )}
                    </Space>
                  }
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
              <div className="resourceAllocation-date-picker">
                <div className="d-flex">
                  <LeftOutlined onClick={prevWeek} />
                  <DatePicker
                    suffixIcon={null}
                    value={weekPickerValue}
                    onChange={handleDatepicker}
                    format={customWeekStartEndFormat}
                    picker="week"
                    bordered={false}
                  />
                  <RightOutlined onClick={nextWeek} />
                </div>
              </div>
              {([ROLE_KEYS?.SUPER_ADMIN, ROLE_KEYS?.ADMIN]?.includes(
                user?.role,
              ) ||
                [LEVEL?.L1, LEVEL?.L2]?.includes(user?.level?.name)) && (
                <Tooltip
                  placement="bottom"
                  title={
                    <span>
                      Users, Department, Leaves, Dashboard-Leaves Sync
                    </span>
                  }
                >
                  <Button onClick={handleSync}>
                    <SyncOutlined />
                  </Button>
                </Tooltip>
              )}
              <span className="toggle-project-name">
                <Switch
                  checkedChildren="Planned"
                  unCheckedChildren="All"
                  defaultChecked={false}
                  onChange={handleToggleSwitch}
                />
              </span>
              <span className="visible-project-name">
                <Select
                  mode="multiple"
                  placeholder="Select Project Columns"
                  className="visible-project-columns"
                  onChange={handleProjectSelect}
                  maxTagCount={3}
                  maxTagPlaceholder={renderMaxTag}
                  options={
                    selectedItems?.length > 0 ? projectFilterData : projectData
                  }
                  allowClear
                  filterOption={(inputValue, option) =>
                    option?.label
                      ?.toLowerCase()
                      ?.includes(inputValue?.toLowerCase())
                  }
                  onPopupScroll={handlePopupScroll}
                  onSearch={(e) => {
                    handleSearchData(e);
                    setSkipData(0);
                  }}
                  virtual={false}
                />
              </span>
              <span className="resource-allocation-select">
                <>
                  <CommonSelect
                    mode="tags"
                    placeholder="Select Name, Department, Designation"
                    className="resource-allocation-search"
                    query={DEPARTMENTS}
                    onChange={handleDepartment}
                    onSearch={handleDepartmentSearch}
                    variables={{ search: departmentSearch }}
                    useEffectDeps={[departmentSearch]}
                    responsePath="departments.departments"
                    valuePath="name"
                    labelPath="name"
                    fetchPolicy="network-only"
                    maxTagCount={3}
                    maxTagPlaceholder={renderMaxTag}
                    allowClear
                    onClear={() => setSearchString([])}
                    value={departmentFilteredId}
                    virtual={false}
                  />
                </>
              </span>
              <span className="resource-allocation-select">
                <CommonSelect
                  mode="tags"
                  placeholder="Search Tags"
                  className="resource-allocation-tags"
                  allowClear
                  query={ALL_TAGS}
                  onChange={handleTagChange}
                  onSearch={(searchText) => {
                    handleTagSearch(searchText);
                  }}
                  variables={{
                    searchString: tagSearch,
                  }}
                  useEffectDeps={[tagSearch]}
                  responsePath="searchTags.tags"
                  valuePath="name"
                  labelPath="name"
                  fetchPolicy="network-only"
                  maxTagCount={3}
                  maxTagPlaceholder={renderMaxTag}
                  searchValue={search}
                  virtual={false}
                />
              </span>
            </div>
          }
        >
          <div className="card-body-wrapper">
            {resourceLoading || projectLoading ? (
              <LoaderComponent size="default" />
            ) : (
              <div className="container">
                <div className="grid ag-theme-alpine">
                  <AgGridReact
                    rowData={rowData}
                    rowHeight={ROW_HEIGHT}
                    columnDefs={filteredColumns}
                    noRowsOverlayComponent={noRowsOverlayComponent}
                    getRowClass={getRowClass}
                    onCellFocused={handleCellKey}
                    onCellMouseOver={(e) => {
                      if (inputColId) {
                        highlightColumn(inputColId, COLUMN_COLOR);
                      }
                      highlightColumn(e?.colDef?.field, COLUMN_COLOR);
                    }}
                    onCellMouseOut={(e) => {
                      if (inputColId) {
                        highlightColumn(inputColId, COLUMN_COLOR);
                      }
                      highlightColumn(e?.colDef?.field, '');
                    }}
                    onBodyScroll={(e) => {
                      if (inputColId) {
                        highlightColumn(inputColId, COLUMN_COLOR);
                      }
                      highlightColumn(e?.colDef?.field, COLUMN_COLOR);
                    }}
                    suppressScrollOnNewData
                  />
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}

export default ResourceAllocationTesting;
