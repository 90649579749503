import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
} from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { BILLING, LIMIT, USER_TYPE } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CommonSelect from '../../../components/CommonSelect';
import CommonTable from '../../../components/CommonTable';
import { DEPARTMENTS } from '../../users/graphql/Queries';
import {
  CREATE_PROJECT_MEMBERS,
  DELETE_PROJECT_MEMBERS,
} from '../graphql/Mutations';
import {
  PROJECT_MEMBERS,
  USERS_WITH_RESOURCE_ALLOCATION_TAG,
} from '../graphql/Queries';

const { required } = formValidatorRules;
const AddMembers = ({
  projectId,
  isActive,
  isEndDatePast,
  isUpdatePermission,
  isDeletePermission,
}) => {
  const [form] = Form?.useForm();
  const [memberFilterData, setMemberFilterData] = useState([]);
  const [memberCount, setMemberCount] = useState(0);
  const [inputNumberValue, setInputNumberValue] = useState(40);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [nameSelection, setNameSelection] = useState(true);
  const [departmentSearch, setDepartmentSearch] = useState('');
  const [userSearch, setUserSearch] = useState('');
  const userType = Form?.useWatch('type', form);

  const [executeMembersQuery] = useLazyQuery(PROJECT_MEMBERS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const newCount = res?.projectMembers?.count;
      const newData = res?.projectMembers?.data?.map((user) => user);
      setMemberCount(newCount);
      setMemberFilterData(newData);
    },
    onError() {},
  });

  const [deleteMutation] = useMutation(DELETE_PROJECT_MEMBERS, {
    fetchPolicy: 'network-only',
    onError() {},
    refetchQueries: [
      {
        query: PROJECT_MEMBERS,
        fetchPolicy: 'network-only',
        variables: {
          where: {
            id: projectId,
          },
          filters: {
            skip: 0,
            limit: LIMIT,
          },
        },
      },
    ],
  });

  const [createMutation] = useMutation(CREATE_PROJECT_MEMBERS, {
    fetchPolicy: 'network-only',
    onError() {},
    refetchQueries: [
      {
        query: PROJECT_MEMBERS,
        fetchPolicy: 'network-only',
        variables: {
          where: {
            id: projectId,
          },
          filters: {
            skip: 0,
            limit: LIMIT,
          },
        },
      },
    ],
  });

  useEffect(() => {
    executeMembersQuery({
      variables: {
        where: {
          id: projectId,
        },
        filters: {
          skip: 0,
          limit: LIMIT,
        },
      },
    });
  }, [projectId]);

  const handleSave = async () => {
    form?.submit();
  };
  const handlePagination = (page, ...rest) => {
    setCurrentPage(page);
    executeMembersQuery({
      variables: {
        where: {
          id: projectId,
        },
        filters: {
          skip: currentPage ? (page - 1) * rest?.[0] : 0,
          limit: rest?.[0],
        },
      },
    });
  };

  const onFinish = async (values) => {
    setNameSelection(true);
    setDepartmentSearch('');
    setUserSearch('');
    await form?.validateFields();
    await createMutation({
      variables: {
        input: {
          hours: inputNumberValue,
          projectId,
          userId: values?.userName,
          billable: values?.billing,
          type: values?.type || USER_TYPE?.[0]?.value,
        },
      },
    });
    form?.resetFields();
    setInputNumberValue(40);
  };

  const handleDepartment = (value) => {
    setDepartmentSearch('');
    setSelectedDepartment(value);
    setNameSelection(!value);
    form.setFieldsValue({ userName: null });
  };

  const setSearchValue = (value, user) => {
    if (user) {
      setUserSearch(value);
    } else {
      setDepartmentSearch(value);
    }
  };

  const setSearchValueDebounced = useCallback(
    debounce(setSearchValue, 500),
    [],
  );

  const handleDepartmentSearch = (value) => {
    setSearchValueDebounced(value);
  };

  const handleUserSearch = (value) => {
    setSearchValueDebounced(value, 'user');
  };

  const handleUserSelect = () => setUserSearch('');

  const decreaseCredit = () => {
    setInputNumberValue((prevValue) => {
      const newValue = Math?.max(prevValue - 1, 1);
      form.setFieldsValue({ userHours: newValue });
      return newValue;
    });
  };

  const increaseCredit = () => {
    setInputNumberValue((prevValue) => {
      const newValue = Math?.min(prevValue + 1, 40);
      form.setFieldsValue({ userHours: newValue });
      return newValue;
    });
  };

  const handleValue = (value) => {
    setInputNumberValue(value);
  };

  const handleDelete = (id) => {
    Modal?.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Confirm Deletion',
      content:
        'Are you sure you want to delete this member?  This will not retrieve dedicated hours in the resource allocation sheet in future.',
      centered: true,
      okText: 'Yes, I confirm',
      className: 'custom-modal-button',
      onOk: () => {
        deleteMutation({
          variables: {
            where: {
              id,
            },
          },
        });
        setCurrentPage(1);
      },
    });
  };

  const columns = [
    {
      title: 'Department',
      dataIndex: ['user', 'department', 'name'],
      key: 'department',
      align: 'center',
    },
    {
      title: 'User Name',
      dataIndex: ['user', 'displayName'],
      key: 'displayName',
      align: 'center',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
    },
    {
      title: 'Billing',
      dataIndex: 'billable',
      key: 'billable',
      align: 'center',
      render: (value) => (value ? 'Billable' : 'Non-Billable'),
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'hours',
      align: 'center',
    },
    ...(isDeletePermission
      ? [
          {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (_, record) => (
              <Button
                danger
                type="text"
                onClick={() => {
                  handleDelete(record?.id);
                }}
                disabled={!isActive}
              >
                <DeleteOutlined />
              </Button>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      {isActive && (
        <>
          <Divider />
          <h3 className="add-member-heading">Add Team Members</h3>
          {!isEndDatePast && isUpdatePermission && (
            <Row>
              <Col md={24}>
                <Form
                  name="control-ref"
                  form={form}
                  onFinish={onFinish}
                  initialValues={{ userHours: 40 }}
                  layout="inline"
                  className="add-member-form"
                >
                  <Form.Item
                    name="department"
                    rules={[{ required, message: 'Please Select Department!' }]}
                  >
                    <CommonSelect
                      placeholder="Select Department"
                      className="select-department"
                      showSearch
                      allowClear
                      query={DEPARTMENTS}
                      onChange={handleDepartment}
                      onSearch={handleDepartmentSearch}
                      variables={{ search: departmentSearch }}
                      useEffectDeps={[departmentSearch]}
                      responsePath="departments.departments"
                      valuePath="id"
                      labelPath="name"
                      fetchPolicy="network-only"
                      virtual={false}
                    />
                  </Form.Item>
                  <Form.Item
                    name="userName"
                    rules={[{ required, message: 'Please Select Name!' }]}
                  >
                    <CommonSelect
                      getPopupContainer={(trigger) => trigger?.parentNode}
                      className="select-user-name"
                      placeholder="Select Name"
                      showSearch
                      allowClear
                      disabled={nameSelection}
                      query={USERS_WITH_RESOURCE_ALLOCATION_TAG}
                      onSearch={handleUserSearch}
                      onChange={handleUserSelect}
                      useEffectDeps={[selectedDepartment, userSearch]}
                      isDataDependent={[selectedDepartment]}
                      responsePath="usersWithResourceAllocationTagAdmin.data"
                      variables={{
                        filters: {
                          projectId,
                          departmentId: selectedDepartment,
                          searchString: userSearch,
                        },
                      }}
                      valuePath="id"
                      labelPath="displayName"
                      conditionToCheckBeforeQuery={
                        selectedDepartment?.length > 0
                      }
                      fetchPolicy="network-only"
                      virtual={false}
                    />
                  </Form.Item>
                  <Form.Item name="type">
                    <Select
                      className="select-type"
                      defaultValue={USER_TYPE?.[0]?.value}
                      options={USER_TYPE}
                    />
                  </Form.Item>
                  <Form.Item name="billing">
                    <Select
                      className="select-billing"
                      defaultValue={BILLING?.[0]?.value}
                      options={BILLING}
                    />
                  </Form.Item>
                  <Form.Item
                    name="userHours"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (
                            userType !== USER_TYPE?.[1]?.value &&
                            value <= 0
                          ) {
                            return Promise?.reject(
                              new Error('Enter More Than 0'),
                            );
                          }
                          return Promise?.resolve();
                        },
                      }),
                    ]}
                    dependencies={['type']}
                  >
                    <InputNumber
                      className="select-user-hours"
                      addonBefore={
                        <MinusCircleOutlined onClick={decreaseCredit} />
                      }
                      addonAfter={
                        <PlusCircleOutlined onClick={increaseCredit} />
                      }
                      onChange={handleValue}
                      trigger="onPressEnter"
                      max={40}
                      min={0}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={handleSave}
                      icon={<PlusOutlined />}
                    >
                      Add
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          )}
        </>
      )}
      {memberFilterData?.length > 0 && (
        <Row>
          <Col span={12}>
            <CommonTable
              size="small"
              className="add-member-listing-table"
              columns={columns}
              dataSource={memberFilterData}
              rowKey={(obj) => obj?.id}
              paginationConfig={{
                current: currentPage,
                total: memberCount,
                onChange: handlePagination,
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
export default AddMembers;
