import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import { MODULES_KEY, PERMISSION_OBJECT, ROUTES } from '../../common/constants';
import ResourceAllocationTesting from './ResourceAllocationTesting';

const ResourceAllocationTestingWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.RESOURCE_ALLOCATION_TESTING}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.RESOURCE_ALLOCATION}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <ResourceAllocationTesting {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);
export default ResourceAllocationTestingWrapper;
