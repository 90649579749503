import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Avatar, Menu, Modal, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import {
  AllocationIcon,
  Asset,
  Event,
  IncidentReport,
  Leave,
  Logs,
  ProjectResourceSheet,
  QuarterAllocationIcon,
  Resume,
  Settings,
  Statistics,
} from '../../../assets/svg';
import {
  MODULES,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES,
} from '../../../common/constants';
import VerifyPermissions from '../../../components/VerifyPermissions';

function getItem(label, key, modulekey, icon, children, type) {
  return {
    key,
    modulekey,
    icon,
    children,
    label,
    type,
  };
}
const menuPermission = [PERMISSION_OBJECT?.read];

function Sidebar({ location: { pathname }, history }) {
  const { dispatch } = useContext(AppContext);
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [logoutModal, setLogoutModal] = useState(false);
  const hasPermission = (menu) => {
    if (
      [
        MODULES_KEY?.EMAIL_SIGNATURE,
        MODULES_KEY?.PROJECT_RESOURCE_SHEET,
      ]?.includes(menu?.modulekey)
    ) {
      return true;
    }
    return VerifyPermissions({
      modulekey: menu?.modulekey,
      allowedPermissions: menuPermission,
    });
  };

  const generateChildren = (permissionArray) =>
    permissionArray
      ?.map(({ moduleKey, route, permissionKey }) => {
        if (
          VerifyPermissions({
            modulekey: permissionKey,
            allowedPermissions: menuPermission,
          })
        ) {
          return getItem(moduleKey, route, permissionKey);
        }
        return null;
      })
      ?.filter((item) => item !== null);

  const getFirstChildKey = (permissionsArray) => {
    const foundPermission = permissionsArray?.find((permission) => {
      const isPermission = VerifyPermissions({
        modulekey: permission?.permissionKey,
        allowedPermissions: menuPermission,
      });
      return isPermission;
    });
    return foundPermission ? foundPermission?.permissionKey : null;
  };

  const assetPermissions = [
    {
      moduleKey: MODULES.LISTING,
      route: `${ROUTES?.ASSETS_TRACKER}${ROUTES?.LISTING}`,
      permissionKey: MODULES_KEY.ASSETS_LISTING,
    },
    {
      moduleKey: MODULES.BOOKINGS,
      route: `${ROUTES?.ASSETS_TRACKER}${ROUTES?.BOOKINGS}`,
      permissionKey: MODULES_KEY.ASSETS_BOOKING,
    },
  ];

  const eventsPermissions = [
    {
      moduleKey: MODULES.EVENT_MANAGEMENT,
      route: `${ROUTES?.EVENTS}${ROUTES?.EVENT_MANAGEMENT}`,
      permissionKey: MODULES_KEY.EVENT_MANAGEMENT,
    },
    {
      moduleKey: MODULES.RSVP_MANAGEMENT,
      route: `${ROUTES?.EVENTS}${ROUTES?.RSVP_MANAGEMENT}`,
      permissionKey: MODULES_KEY.RSVP_MANAGEMENT,
    },
  ];

  const settingsPermissions = [
    {
      moduleKey: MODULES.USERS,
      route: `${ROUTES?.SETTINGS}${ROUTES?.USERS}`,
      permissionKey: MODULES_KEY.USERS,
    },
    {
      moduleKey: MODULES.PROJECTS,
      route: `${ROUTES?.SETTINGS}${ROUTES?.PROJECTS}`,
      permissionKey: MODULES_KEY.PROJECTS,
    },
    {
      moduleKey: MODULES.ROLE,
      route: `${ROUTES?.SETTINGS}${ROUTES?.ROLE}`,
      permissionKey: MODULES_KEY.ROLE,
    },
    {
      moduleKey: MODULES.HOLIDAYS,
      route: `${ROUTES?.SETTINGS}${ROUTES?.HOLIDAYS}`,
      permissionKey: MODULES_KEY.HOLIDAYS,
    },
    {
      moduleKey: MODULES.CONFIGURATION,
      route: `${ROUTES?.SETTINGS}${ROUTES?.CONFIGURATION}`,
      permissionKey: MODULES_KEY.CONFIGURATIONS,
    },
    {
      moduleKey: MODULES?.ALERT_CONFIG,
      route: `${ROUTES?.SETTINGS}${ROUTES?.ALERT_CONFIG}`,
      permissionKey: MODULES_KEY?.ALERT_CONFIG,
    },
    {
      moduleKey: MODULES.INCIDENT_MASTER,
      route: `${ROUTES?.SETTINGS}${ROUTES?.INCIDENT_MASTER}`,
      permissionKey: MODULES_KEY.INCIDENT_MASTER,
    },
  ];

  const logsPermissions = [
    {
      moduleKey: MODULES.ACTIVITY,
      route: `${ROUTES?.LOGS}${ROUTES?.ACTIVITY}`,
      permissionKey: MODULES_KEY.LOGS,
    },
    {
      moduleKey: MODULES.HOUR_LOG,
      route: `${ROUTES?.LOGS}${ROUTES?.HOUR_LOG}`,
      permissionKey: MODULES_KEY.HOURS_LOG_HISTORY,
    },
    {
      moduleKey: MODULES.HOUR_LOG_SUMMARY,
      route: `${ROUTES?.LOGS}${ROUTES?.HOUR_LOG_SUMMARY}`,
      permissionKey: MODULES_KEY.HOURS_LOG_HISTORY,
    },
  ];

  const menuItems = [
    getItem(
      MODULES?.RESOURCE_ALLOCATION,
      ROUTES?.RESOURCE_ALLOCATION,
      MODULES_KEY?.RESOURCE_ALLOCATION,
      <span className="anticon">
        <AllocationIcon />
      </span>,
    ),
    getItem(
      MODULES?.QUARTER_VIEW,
      ROUTES?.QUARTER_VIEW,
      MODULES_KEY?.QUARTER_VIEW,
      <span className="anticon">
        <QuarterAllocationIcon />
      </span>,
    ),
    getItem(
      MODULES?.PROJECT_RESOURCE_SHEET,
      ROUTES?.PROJECT_RESOURCE_SHEET,
      MODULES_KEY?.PROJECT_RESOURCE_SHEET,
      <span className="anticon">
        <ProjectResourceSheet />
      </span>,
    ),
    getItem(
      MODULES?.LEAVES,
      ROUTES?.LEAVES,
      MODULES_KEY?.LEAVES,
      <span className="anticon">
        <Leave />
      </span>,
    ),
    getItem(
      MODULES?.INCIDENT_REPORT,
      ROUTES?.INCIDENT_REPORT,
      MODULES_KEY?.INCIDENT_REPORT,
      <span className="anticon">
        <IncidentReport />
      </span>,
    ),
    getFirstChildKey(assetPermissions) &&
      getItem(
        MODULES.ASSETS_TRACKER,
        ROUTES.ASSETS_TRACKER,
        getFirstChildKey(assetPermissions),
        <span className="anticon">
          <Asset />
        </span>,
        generateChildren(assetPermissions),
      ),
    getItem(
      MODULES?.EMPLOYEE_RESUMES,
      ROUTES?.EMPLOYEE_RESUMES,
      MODULES_KEY?.SHARED_RESUMES,
      <span className="anticon">
        <Resume />
      </span>,
    ),
    getItem(
      MODULES?.STATISTICS,
      ROUTES?.STATISTICS,
      MODULES_KEY?.STATISTICS,
      <span className="anticon">
        <Statistics />
      </span>,
    ),
    getFirstChildKey(eventsPermissions) &&
      getItem(
        MODULES.EVENTS,
        ROUTES.EVENTS,
        getFirstChildKey(eventsPermissions),
        <span className="anticon">
          <Event />
        </span>,
        generateChildren(eventsPermissions),
      ),
    getFirstChildKey(settingsPermissions) &&
      getItem(
        MODULES.SETTINGS,
        ROUTES.SETTINGS,
        getFirstChildKey(settingsPermissions),
        <span className="anticon">
          <Settings />
        </span>,
        generateChildren(settingsPermissions),
      ),
    getFirstChildKey(logsPermissions) &&
      getItem(
        MODULES.LOGS,
        ROUTES.LOGS,
        getFirstChildKey(logsPermissions),
        <span className="anticon">
          <Logs />
        </span>,
        generateChildren(logsPermissions),
      ),
    // comment for future use
    // getItem(
    //   MODULES?.RESOURCE_ALLOCATION_OLD,
    //   ROUTES?.RESOURCE_ALLOCATION_OLD,
    //   MODULES_KEY?.RESOURCE_ALLOCATION,
    //   <span className="anticon">
    //     <AllocationIcon />
    //   </span>,
    // ),
    // comment for future use
    // getItem(
    //   MODULES?.FOOD_COUPONS,
    //   ROUTES?.FOOD_COUPONS,
    //   MODULES_KEY?.FOOD,
    //   <span className="anticon">
    //     <FoodCouponsIcon />
    //   </span>,
    // ),
    // comment for future use
    // getItem(
    //   MODULES?.UPLOAD_WFH_SHEET,
    //   ROUTES?.UPLOAD_WFH_SHEET,
    //   MODULES_KEY?.WFH,
    //   <span className="anticon">
    //     <UploadIcon />
    //   </span>,
    // ),
  ];
  const onMenuSelect = ({ key }) => {
    setSelectedKeys([key]);
    history?.push(key);
  };

  useEffect(() => {
    const pathnameSegments = pathname?.split('/')?.filter(Boolean);
    const newSelectedKeys = pathnameSegments?.map(
      (_, index) => `/${pathnameSegments?.slice(0, index + 1)?.join('/')}`,
    );
    setSelectedKeys(newSelectedKeys);
  }, [pathname]);

  const handleLogout = (e) => {
    e.stopPropagation();
    if (!logoutModal) {
      setLogoutModal(true);
      Modal?.confirm({
        icon: <ExclamationCircleOutlined />,
        title: 'Logout',
        content: 'Are you sure you want to log out?',
        centered: true,
        className: 'custom-modal-button',
        onOk: () => {
          dispatch({
            type: 'LOGOUT',
          });
          history?.push(ROUTES?.MAIN);
          setLogoutModal(false);
        },
        onCancel: () => {
          setLogoutModal(false);
        },
      });
    }
  };

  const profileItems = [
    getItem(
      currentUser?.jobTitle || currentUser?.firstName || currentUser?.lastName,
      MODULES?.PROFILE,
      ROUTES?.PROFILE,
      <span>
        <Space className="pointer">
          <Avatar alt="Avatar" size="default">
            {`${currentUser?.firstName?.[0]}${currentUser?.lastName?.[0]}`}
          </Avatar>
        </Space>
      </span>,
      [
        getItem(
          MODULES.EMAIL_SIGNATURE,
          `${ROUTES?.PROFILE}${ROUTES?.EMAIL_SIGNATURE}`,
        ),
        getItem(
          <p className="mt-0" onClick={handleLogout}>
            {MODULES?.LOGOUT}
          </p>,
          ROUTES?.LOGOUT,
          ROUTES?.LOGOUT,
        ),
      ],
    ),
  ];

  return (
    <div className="side-bar">
      <Menu
        inlineIndent={16}
        theme="light"
        mode="inline"
        selectedKeys={selectedKeys}
        defaultSelectedKeys={[ROUTES?.USERS]}
        onSelect={onMenuSelect}
        onClick={onMenuSelect}
        items={menuItems?.filter(hasPermission)}
      />
      <div className="bottom-logout">
        <div className="profile-menu">
          <Menu
            className="profile-bar"
            theme="light"
            onSelect={onMenuSelect}
            onClick={onMenuSelect}
            selectedKeys={selectedKeys}
            items={profileItems}
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Sidebar);
