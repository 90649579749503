import { Card, Result, Select } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { SORT_ORDER, STATUS } from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import { PROJECT_SHEET_LISTING } from './graphql/Queries';

const { Option } = Select;
const initialFilters = {
  sortOrder: SORT_ORDER?.[0]?.value,
  status: STATUS?.[0]?.value,
  projectExcel: true,
};

const ProjectResourceSheet = () => {
  const [sheetUrl, setSheetUrl] = useState(null);
  const [sheetUrlData, setSheetUrlData] = useState([]);
  const [filteredSheetData, setFilteredSheetData] = useState([]);
  const [projectSearch, setProjectSearch] = useState('');
  const [projectId, setProjectId] = useState('');

  const handleSheetChange = (e) => {
    setSheetUrl(e);
  };

  useEffect(() => {
    if (filteredSheetData?.[0]?.projectExcel?.length > 0 && projectId) {
      setSheetUrl(filteredSheetData?.[0]?.projectExcel[0]?.excelSheetUrl);
    }
  }, [projectId]);

  const projectSelection = (value) => {
    setSheetUrl(null);
    setProjectId(value);
    setProjectSearch('');
    setFilteredSheetData(sheetUrlData?.filter((item) => item?.id === value));
  };

  const handleProjectSearch = debounce((value) => {
    setProjectSearch(value?.toString());
  }, 500);

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Project Resource Sheet"
        extra={
          <div className="project-filter">
            <CommonSelect
              placeholder="Select Project"
              showSearch
              query={PROJECT_SHEET_LISTING}
              onChange={projectSelection}
              onSearch={handleProjectSearch}
              responsePath="projectResourceSheet.projects"
              valuePath="id"
              labelPath="name"
              fetchPolicy="network-only"
              variables={{
                filters: { ...initialFilters, searchString: projectSearch },
              }}
              useEffectDeps={[projectSearch]}
              virtual={false}
              getApiData={(records) => {
                setSheetUrlData(records);
              }}
            />
            <Select
              placeholder="Select Sheet"
              disabled={!projectId}
              value={sheetUrl}
              onChange={handleSheetChange}
            >
              {filteredSheetData?.[0]?.projectExcel?.map((sheet) => (
                <Option key={sheet?.title} value={sheet?.excelSheetUrl}>
                  {sheet?.title}
                </Option>
              ))}
            </Select>
          </div>
        }
      >
        {projectId && sheetUrl ? (
          <iframe
            src={sheetUrl}
            title="resource-sheet"
            width="100%"
            height="100%"
          />
        ) : (
          <Result
            className="no-filters-selected"
            title="Please select the project to view the resource sheet"
            extra={<p>Note: All filters are compulsory to select.</p>}
          />
        )}
      </Card>
    </div>
  );
};

export default ProjectResourceSheet;
