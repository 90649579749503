import { gql } from '@apollo/client';

export const ASSETS = gql`
  query Assets($filters: AssetFilters) {
    assets(filters: $filters) {
      count
      assets {
        id
        assetId
        assetName
        assetType
        os
        osVersion
        isActive
        availability
        deviceCompany
        udid
        countryOfOrigin
        modelNumber
        preAssigned
        rooted
        appleId
        googleAccount
        assetTracker {
          user {
            firstName
            displayName
          }
        }
        manufacturerSoftware
        manufacturerSoftwareVersion
      }
    }
  }
`;

export const ASSET = gql`
  query Asset($id: ID!) {
    asset(id: $id) {
      id
      assetId
      assetName
      assetType
      os
      osVersion
      isActive
      availability
      deviceCompany
      udid
      countryOfOrigin
      modelNumber
      preAssigned
      rooted
      appleId
      googleAccount
      user {
        id
        displayName
      }
      manufacturerSoftware
      manufacturerSoftwareVersion
    }
  }
`;

export const ASSET_BOOKINGS = gql`
  query AssetBookings($filters: AssetBookingFilters) {
    assetBookings(filters: $filters) {
      count
      assetBookings {
        id
        asset {
          assetId
          assetName
        }
        user {
          displayName
          firstName
          lastName
        }
        clockIn
        clockOut
        createdAt
        updatedAt
        assignedAt
        submittedAt
      }
    }
  }
`;

export const USERS = gql`
  query usersAdminAssetsListing($filters: AdminSearchInput) {
    usersAdminAssetsListing(filters: $filters) {
      count
      users {
        billable
        id
        displayName
        email
        jobTitle
        department {
          name
        }
        isActive
        isIntern
      }
    }
  }
`;
