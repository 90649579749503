import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Col, Popconfirm, Row, Space, Tooltip } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LIMIT, ROUTES_MODULES_KEY, SORT_ORDER } from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import { DELETE_ROLE } from './graphql/Mutations';
import { ROLE_ACCESS } from './graphql/Queries';

const RoleTable = () => {
  const history = useHistory();

  const [initialFilter, setInitialFilter] = useState({
    skip: 0,
    searchString: '',
    sortOrder: SORT_ORDER?.[0]?.value,
  });
  const [paginationFilter, setPaginationFilter] = useState({
    current: 1,
    pageSize: LIMIT,
    total: 0,
  });
  const [roleId, setRoleId] = useState('');

  const [executeQuery, { loading, data }] = useLazyQuery(ROLE_ACCESS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationFilter,
        total: res?.rolePermissions?.count,
      };
      setPaginationFilter(pagination);
    },
    onError: () => {},
  });

  const [deleteRole] = useMutation(DELETE_ROLE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setPaginationFilter({
        ...paginationFilter,
        current: 1,
      });
      executeQuery({
        variables: {
          filters: {
            ...initialFilter,
            skip: 0,
            limit: paginationFilter?.pageSize,
          },
        },
      });
    },
    onError: () => {},
  });

  useEffect(() => {
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          limit: paginationFilter?.pageSize,
        },
      },
    });
  }, []);

  const handleSearch = debounce((value) => {
    setInitialFilter({
      ...initialFilter,
      skip: 0,
      searchString: value?.trim(),
    });
    setPaginationFilter({
      ...paginationFilter,
      current: 1,
    });
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          skip: 0,
          limit: paginationFilter?.pageSize,
          searchString: value?.trim(),
        },
      },
    });
  }, 500);

  const handleDeleteRole = (record) => {
    setRoleId(record?.id);
  };

  const confirm = () => {
    deleteRole({
      variables: {
        where: {
          id: roleId,
        },
      },
    });
  };

  const handlePagination = (pagination) => {
    const { current, pageSize } = pagination;
    const filter = {
      skip: current ? (current - 1) * pageSize : 0,
    };
    setInitialFilter({ ...initialFilter, ...filter });
    setPaginationFilter({ ...paginationFilter, current, pageSize });
    executeQuery({
      variables: {
        filters: {
          ...initialFilter,
          skip: filter?.skip,
          limit: pageSize,
        },
      },
    });
  };

  const columns = [
    {
      title: 'Role Name',
      dataIndex: 'name',
      key: 'name',
      width: '70%',
    },
    {
      title: 'Action',
      width: '30%',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip placement="bottom" title={<span>Edit Role</span>}>
            <Button
              type="text"
              onClick={() => {
                history?.push({
                  pathname: `${ROUTES_MODULES_KEY?.ROLE}/edit/${record?.id}`,
                });
              }}
            >
              <EditOutlined />
            </Button>
          </Tooltip>
          <Popconfirm
            title="Delete Role"
            description="Are you sure you want to delete the role?"
            placement="topLeft"
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip placement="bottom" title={<span>Delete Role</span>}>
              <Button
                danger
                type="text"
                onClick={() => {
                  handleDeleteRole(record);
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Card
      className="ant-body-scroll role-table"
      title="Users Role"
      extra={
        <>
          <div className="listing-filter">
            <Button
              key="1"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                history?.push({
                  pathname: `${ROUTES_MODULES_KEY?.ROLE}/add`,
                });
              }}
            >
              Create Role
            </Button>
            <SearchComponent getData={handleSearch} name="Role" />
          </div>
        </>
      }
    >
      <Row>
        <Col xs={24} lg={12} xl={8}>
          <div className="card-body-wrapper">
            <CommonTable
              size="small"
              columns={columns}
              dataSource={data?.rolePermissions?.data}
              loadingData={loading}
              rowKey={(obj) => obj?.id}
              bordered
              onChange={handlePagination}
              paginationConfig={paginationFilter}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default RoleTable;
