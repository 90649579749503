import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_BILLABLE = gql`
  mutation UpdateBillableSummary($input: UpdateBillableSummaryInput!) {
    updateBillableSummary(input: $input) {
      message
    }
  }
`;
