import React from 'react';

function EmailNote() {
  return (
    <div>
      <h1>How to add this signature to Gmail</h1>
      <ul className="pl-24">
        <li>
          Copy the signature to the clipboard click on{' '}
          <strong>Copy Signature</strong> button.
        </li>
        <li>Log in to Gmail/G Suite.</li>
        <li>
          Click the gear icon in the upper-right corner and choose{' '}
          <strong>See all settings.</strong>
        </li>
        <li>
          On the <strong>General</strong> tab (default), scroll down to the{' '}
          <strong>Signature</strong> section.
        </li>
        <li>
          Click the <strong>Create new </strong> button to add a new email
          signature or the <strong>Edit button</strong> if you want to replace
          an existing signature.
        </li>
        <li>
          <strong>Paste</strong> the copied signature in the{' '}
          <strong>Edit signature</strong> section <strong>(Ctrl + V)</strong>.
        </li>
        <li>
          (Optional) Choose whether to insert the signature automatically for
          new emails and replies and forwards.
        </li>
        <li>
          Scroll down and click <strong>Save changes.</strong>
        </li>
      </ul>
    </div>
  );
}
export default EmailNote;
