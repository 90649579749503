import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import Error404 from '../../Error404';
import {
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import IncidentMaster from './IncidentMaster';

const IncidentMasterWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES_MODULES_KEY?.INCIDENT_REPORT}`}
      render={() => (
        <AccessControl
          modulekey={MODULES_KEY?.INCIDENT_REPORT}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <IncidentMaster />
        </AccessControl>
      )}
    />
    <Route path="*" component={Error404} />
  </Switch>
);

export default IncidentMasterWrapper;
