import { gql } from '@apollo/client';

export const USERS = gql`
  query usersAdminLeaves($filters: AdminSearchInput) {
    usersAdminLeaves(filters: $filters) {
      count
      users {
        billable
        id
        displayName
        email
        jobTitle
        department {
          name
        }
        isActive
        isIntern
      }
    }
  }
`;

export const DESIGNATIONS = gql`
  query designations($searchString: String, $filters: DesignationFiltersInput) {
    designations(searchString: $searchString, filters: $filters) {
      count
      designations {
        jobTitle
      }
    }
  }
`;

export const USER_LEVEL = gql`
  query levels {
    levels {
      count
      levels {
        id
        name
        description
      }
    }
  }
`;

export const LEAVE_LISTING = gql`
  query InternOnLeaves($filters: InternFiltersInput!) {
    internOnLeaves(filters: $filters) {
      count
      data {
        id
        startDate
        endDate
        fromLeaveType
        toLeaveType
        reason
        type
        status
        planned
        user {
          id
          displayName
          firstName
          lastName
          jobTitle
          department {
            id
            name
          }
          reportingManager {
            id
            displayName
            firstName
            lastName
            jobTitle
            profileImage
          }
          profileImage
          level {
            id
            name
            description
          }
        }
        noOfDays
        dayType
        createdAt
        pollId
        requestedOn
        leaveRedirectUrl
        updatedAt
        source
        duration
        leaveStatusHistory {
          status
          reason
          createdAt
          actionByUser {
            id
            displayName
            firstName
            lastName
            jobTitle
            profileImage
          }
        }
        refData
        creator {
          displayName
          firstName
          lastName
        }
      }
    }
  }
`;

export const TOTAL_DAYS = gql`
  query DaysAndTypesForLeave($data: DaysAndTypesForLeaveInput!) {
    daysAndTypesForLeave(data: $data) {
      leaveBalances {
        user {
          id
          displayName
          firstName
          lastName
          jobTitle
          department {
            id
            name
          }
          reportingManager {
            id
            displayName
            firstName
            lastName
            jobTitle
            profileImage
          }
          profileImage
          level {
            id
            name
            description
          }
        }
        leaveType {
          id
          name
          key
          calculative
        }
        count
        usedCount
      }
      totalDays
      leaveTypeAvailability {
        available {
          user {
            id
            displayName
            firstName
            lastName
            jobTitle
            department {
              id
              name
            }
            reportingManager {
              id
              displayName
              firstName
              lastName
              jobTitle
              profileImage
            }
            profileImage
            level {
              id
              name
              description
            }
          }
          leaveType {
            id
            name
            key
            calculative
          }
          count
          usedCount
        }
        unavailable {
          count
          usedCount
        }
      }
    }
  }
`;

export const LEAVE_TYPES = gql`
  query leaveTypes {
    leaveTypes {
      data {
        id
        name
        key
        calculative
      }
    }
  }
`;
