import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Badge, Button, Card, DatePicker, Popover, Space } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  ALERT_CONFIG_DAYS_LEAVE_TYPE,
  ALERT_CONFIG_LEAVE_TYPE,
  BACKEND_DATE_FORMAT,
  CALENDAR_VIEW_MODAL,
  DATE_FORMAT,
  LIMIT,
  MONTH_FORMAT,
  WEEK_DAYS_FORMAT,
} from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import AlertConfigModal from './components/AlertConfigModal';
import { CALENDAR_LISTING, DESIGNATIONS, GET_CONFIG } from './graphql/Queries';

dayjs.extend(isBetween);

const { RangePicker } = DatePicker;

const initialCalendarListing = {
  endDate: dayjs()?.format(BACKEND_DATE_FORMAT),
  limit: LIMIT,
  searchStrings: [],
  skip: 0,
  startDate: dayjs()?.format(BACKEND_DATE_FORMAT),
};

const initialCalendarPagination = {
  current: 1,
  pageSize: LIMIT,
  total: 0,
};

function AlertConfig() {
  const [dateRange, setDateRange] = useState([
    dayjs().startOf('month'),
    dayjs().endOf('month'),
  ]);
  const [showModal, setShowModal] = useState(false);
  const [month, setMonth] = useState('');
  const [threshold, setThreshold] = useState('');
  const [calendarPagination, setCalendarPagination] = useState(
    initialCalendarPagination,
  );
  const [calendarListing, setCalendarListing] = useState(
    initialCalendarListing,
  );
  const [designationSearch, setDesignationSearch] = useState('');
  const [holidays, setHolidays] = useState('');

  const [executeCalendar, { loading: calendarLoading, data }] = useLazyQuery(
    CALENDAR_LISTING,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setHolidays(res?.calendar?.holidays);
        setCalendarPagination((prev) => ({
          ...prev,
          total: res?.calendar?.count,
        }));
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error('Error: ', error);
      },
    },
  );

  const [executeQuery, { loading, refetch }] = useLazyQuery(GET_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const months = res?.getConfig?.configData?.find(
        (item) => item?.key === CALENDAR_VIEW_MODAL.MONTHS,
      )?.value;
      const thresholdValue = res?.getConfig?.configData?.find(
        (item) => item?.key === CALENDAR_VIEW_MODAL.THRESHOLD_VALUE,
      )?.value;
      setMonth(months);
      setThreshold(thresholdValue);
      setCalendarListing({
        ...calendarListing,
        endDate: dayjs()
          ?.add(thresholdValue, 'month')
          ?.format(BACKEND_DATE_FORMAT),
      });
      setDateRange([dayjs(), dayjs()?.add(thresholdValue, 'month')]);
      executeCalendar({
        variables: {
          filters: {
            ...calendarListing,
            endDate: dayjs()
              ?.add(thresholdValue, 'month')
              ?.format(BACKEND_DATE_FORMAT),
          },
        },
      });
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
    },
  });

  useEffect(() => {
    executeQuery();
  }, []);

  const handleDesignationSearch = debounce((value) => {
    setDesignationSearch(value);
  }, 500);

  const handleSearch = debounce((value) => {
    const { pageSize } = calendarPagination;
    const newInitialFilter = {
      ...calendarListing,
      skip: 0,
      searchStrings:
        value?.length > 0 ? value?.toString()?.trim()?.split(',') : [],
    };
    setCalendarPagination({ ...calendarPagination, current: 1 });
    setCalendarListing(newInitialFilter);
    setDesignationSearch('');
    executeCalendar({
      variables: {
        filters: {
          ...newInitialFilter,
          skip: 0,
          limit: pageSize,
          searchStrings:
            value?.length > 0 ? value?.toString()?.trim()?.split(',') : [],
        },
      },
    });
  }, 500);

  const handleChange = (dates) => {
    setDateRange([dates?.[0]?.startOf('day'), dates?.[1]?.endOf('day')]);
    const payload = {
      ...calendarListing,
      startDate: dates?.[0]?.format(BACKEND_DATE_FORMAT),
      endDate: dates?.[1]?.format(BACKEND_DATE_FORMAT),
    };
    setCalendarListing(payload);
    executeCalendar({
      variables: {
        filters: {
          skip: 0,
          limit: calendarPagination?.pageSize,
          ...payload,
        },
      },
    });
  };

  const days = [];
  let calendarMonth = dayjs(dateRange?.[0]);
  while (
    calendarMonth?.isBefore(dateRange?.[1]) ||
    calendarMonth?.isSame(dateRange?.[1], 'day')
  ) {
    days?.push(calendarMonth?.format(BACKEND_DATE_FORMAT));
    calendarMonth = calendarMonth?.add(1, 'day');
  }

  const getLeaveDisplay = (leaves, workFromHomes, day) => {
    const dayOfWeek = dayjs(day)?.day();
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;

    if (isWeekend) {
      return {
        children: (
          <div className="alert-config-cell alert-config-weekdays">
            <span> {dayjs(day)?.format(DATE_FORMAT)}</span>
          </div>
        ),
      };
    }

    const holiday = holidays?.find((holidayLeave) =>
      dayjs(day)?.isBetween(
        dayjs(holidayLeave?.startDate),
        dayjs(holidayLeave?.endDate),
        null,
        '[]',
      ),
    );
    if (holiday) {
      return {
        children: (
          <div className="alert-config-cell alert-config-holiday">
            <span> {dayjs(day)?.format(DATE_FORMAT)}</span>
          </div>
        ),
      };
    }

    const workFromHome = workFromHomes?.map((wfh) => ({
      ...wfh,
      type: ALERT_CONFIG_LEAVE_TYPE.WORK_FROM_HOME,
    }));
    const events = [...leaves, ...workFromHome];
    const currentEvent = events?.find((event) =>
      dayjs(day)?.isBetween(
        dayjs(event?.startDate),
        dayjs(event?.endDate),
        null,
        '[]',
      ),
    );
    if (currentEvent) {
      const startType = currentEvent?.fromType || currentEvent?.fromLeaveType;
      const endType = currentEvent?.toType || currentEvent?.toLeaveType;
      let alertConfigLeaveClassName;

      if (currentEvent.type === ALERT_CONFIG_LEAVE_TYPE.PAID) {
        if (
          startType === ALERT_CONFIG_LEAVE_TYPE.FIRST_HALF &&
          endType === ALERT_CONFIG_LEAVE_TYPE.SECOND_HALF
        ) {
          alertConfigLeaveClassName = 'paid-leave';
        } else if (
          startType === ALERT_CONFIG_LEAVE_TYPE.FIRST_HALF &&
          endType === ALERT_CONFIG_LEAVE_TYPE.FIRST_HALF
        ) {
          alertConfigLeaveClassName = 'paid-first-half-leave';
        } else if (
          startType === ALERT_CONFIG_LEAVE_TYPE.SECOND_HALF &&
          endType === ALERT_CONFIG_LEAVE_TYPE.SECOND_HALF
        ) {
          alertConfigLeaveClassName = 'paid-second-half-leave';
        }
      } else if (currentEvent.type === ALERT_CONFIG_LEAVE_TYPE.UNPAID) {
        if (
          startType === ALERT_CONFIG_LEAVE_TYPE.FIRST_HALF &&
          endType === ALERT_CONFIG_LEAVE_TYPE.SECOND_HALF
        ) {
          alertConfigLeaveClassName = 'unpaid-leave';
        } else if (
          startType === ALERT_CONFIG_LEAVE_TYPE.FIRST_HALF &&
          endType === ALERT_CONFIG_LEAVE_TYPE.FIRST_HALF
        ) {
          alertConfigLeaveClassName = 'unpaid-first-half-leave';
        } else if (
          startType === ALERT_CONFIG_LEAVE_TYPE.SECOND_HALF &&
          endType === ALERT_CONFIG_LEAVE_TYPE.SECOND_HALF
        ) {
          alertConfigLeaveClassName = 'unpaid-second-half-leave';
        }
      } else if (currentEvent.type === ALERT_CONFIG_LEAVE_TYPE.WORK_FROM_HOME) {
        if (
          startType === ALERT_CONFIG_LEAVE_TYPE.FIRST_HALF &&
          endType === ALERT_CONFIG_LEAVE_TYPE.SECOND_HALF
        ) {
          alertConfigLeaveClassName = 'work-from-home';
        } else if (
          startType === ALERT_CONFIG_LEAVE_TYPE.FIRST_HALF &&
          endType === ALERT_CONFIG_LEAVE_TYPE.FIRST_HALF
        ) {
          alertConfigLeaveClassName = 'work-from-home-first-half';
        } else if (
          startType === ALERT_CONFIG_LEAVE_TYPE.SECOND_HALF &&
          endType === ALERT_CONFIG_LEAVE_TYPE.SECOND_HALF
        ) {
          alertConfigLeaveClassName = 'work-from-home-second-half';
        }
      }
      return {
        children: (
          <div className={`${alertConfigLeaveClassName} alert-config-cell`}>
            <span> {dayjs(day)?.format(DATE_FORMAT)}</span>
          </div>
        ),
      };
    }
    return dayjs(day)?.format(DATE_FORMAT);
  };

  function calculateLeaveCounts() {
    const leaveCounts = {};

    days.forEach((day) => {
      const dayOfWeek = dayjs(day).day();
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;

      if (!isWeekend) {
        let count = 0;
        data?.calendar?.userList.forEach((user) => {
          const hasLeave = user?.leaves?.some((leave) =>
            dayjs(day).isBetween(
              dayjs(leave.startDate),
              dayjs(leave.endDate),
              null,
              '[]',
            ),
          );
          if (hasLeave) {
            count += 1;
          }
        });
        leaveCounts[day] = count;
      } else {
        leaveCounts[day] = 0;
      }
    });
    return leaveCounts;
  }

  const leaveCount = calculateLeaveCounts();

  const columns = [
    {
      title: 'Employee',
      dataIndex: 'displayName',
      key: 'displayName',
      fixed: 'left',
      align: 'left',
      className: 'alert-config-name',
      render: (text, record) => (
        <>
          {text}
          <p className="name-column">
            [{`${record?.department?.name}>${record?.jobTitle}`}]
          </p>
        </>
      ),
    },
    ...days.map((day) => ({
      title: (
        <>
          {dayjs(day).format(BACKEND_DATE_FORMAT) ===
            dayjs(day).endOf('month').format(BACKEND_DATE_FORMAT) && (
            <span>|</span>
          )}
          {dayjs(day).date() === Math.floor(dayjs(day).daysInMonth() / 2) && (
            <span className="month-name">
              {dayjs(day).format(MONTH_FORMAT)}
            </span>
          )}
          <div className="mt-8">
            {leaveCount[day] > 1 && <div className="multiple-leave-on-day" />}
            {dayjs(day)?.format(WEEK_DAYS_FORMAT)}
          </div>
        </>
      ),
      key: day,
      render: (record) =>
        getLeaveDisplay(record?.leaves, record?.workFromHomes, day),
    })),
  ];

  const handleAddConfig = () => {
    setShowModal(true);
  };

  const handlePagination = (pagination) => {
    const { current, pageSize } = pagination;
    const filter = {
      skip: current ? (current - 1) * pageSize : 0,
    };
    setCalendarListing({
      ...calendarListing,
      ...filter,
    });
    setCalendarPagination({ ...calendarPagination, current, pageSize });
    executeCalendar({
      variables: {
        filters: {
          ...calendarListing,
          skip: filter?.skip,
          limit: pageSize,
        },
      },
    });
  };

  const renderMaxTag = (omittedValues) => {
    if (omittedValues?.length === 0) {
      return null;
    }
    return <span>+ {omittedValues?.length} more...</span>;
  };

  if (loading) return <LoaderComponent />;

  return (
    <>
      <div className="table-card-page">
        <Card
          className="ant-body-scroll"
          title="Team Calendar"
          extra={
            <div className="project-filter-right d-flex align-center">
              <div className="project-color-info mr-8">
                <Popover
                  placement="rightTop"
                  title="Leave Color Info"
                  content={
                    <Space direction="vertical">
                      {Object?.entries(ALERT_CONFIG_DAYS_LEAVE_TYPE)?.map(
                        ([key, type]) => (
                          <Badge
                            key={key}
                            className="project-info-badge"
                            color={type?.color}
                            text={type?.name}
                          />
                        ),
                      )}
                    </Space>
                  }
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
              <Button className="mr-8" type="primary" onClick={handleAddConfig}>
                Add Config
              </Button>
              <RangePicker
                value={dateRange}
                format={BACKEND_DATE_FORMAT}
                picker="day"
                onChange={handleChange}
                allowClear={false}
              />
              <span className="alert-config-select mr-8 pl-8">
                <CommonSelect
                  mode="tags"
                  placeholder="Search Name, Department, Designation"
                  showSearch
                  query={DESIGNATIONS}
                  onChange={handleSearch}
                  onSearch={handleDesignationSearch}
                  variables={{ searchString: designationSearch }}
                  useEffectDeps={[designationSearch]}
                  responsePath="designations.designations"
                  valuePath="jobTitle"
                  labelPath="jobTitle"
                  fetchPolicy="network-only"
                  onClear={() =>
                    setCalendarListing({
                      ...calendarListing,
                      searchStrings: [],
                    })
                  }
                  value={calendarListing?.searchStrings}
                  maxTagCount={3}
                  maxTagPlaceholder={renderMaxTag}
                  virtual={false}
                />
              </span>
            </div>
          }
        >
          <div className="card-body-wrapper">
            <CommonTable
              size="small"
              className="monthly-instances-table"
              columns={columns}
              loadingData={calendarLoading || loading}
              dataSource={data?.calendar?.userList}
              rowKey="id"
              onChange={handlePagination}
              paginationConfig={calendarPagination}
            />
          </div>
        </Card>
      </div>
      {showModal && (
        <AlertConfigModal
          show={showModal}
          close={() => setShowModal(false)}
          month={month}
          threshold={threshold}
          refetch={refetch}
        />
      )}
    </>
  );
}

export default AlertConfig;
