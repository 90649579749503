import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_CHAT_AUTH_URL = gql`
  query getChatAuthUrl($input: UniqueIdInput) {
    getChatAuthUrl(input: $input) {
      url
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query User {
    user {
      id
      email
      jobTitle
      profileImage
      mobileNo
      firstName
      lastName
      displayName
      level {
        id
        name
        description
      }
      roleId
      role
      isIntern
      permissionRole {
        id
        name
        permissions {
          access {
            create
            delete
            read
            update
          }
          moduleId
          moduleName
        }
      }
    }
  }
`;
