import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  LEAVE_FORMAT,
  LEAVE_STATUS,
  WEEK_FORMAT,
} from '../../../common/constants';
import { UPDATE_LEAVE } from '../graphql/Mutations';
import { LEAVE_TYPES } from '../graphql/Queries';

const LeaveModal = ({ visible, setModalVisible, leaveRecord, refetch }) => {
  const [form] = Form?.useForm();
  const [btnDisable, setBtnDisable] = useState(true);

  const [updateLeave] = useMutation(UPDATE_LEAVE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      refetch();
      form?.resetFields();
      setModalVisible(false);
    },
    onError: () => {},
  });

  const [executeLeaveTypeQuery, { data }] = useLazyQuery(LEAVE_TYPES, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    executeLeaveTypeQuery();
  }, []);

  const onFinish = async (values) => {
    await updateLeave({
      variables: {
        data: {
          id: leaveRecord?.id,
          leaveTypeId: data?.leaveTypes?.data?.[1]?.id,
          reason: values?.reason,
          status: LEAVE_STATUS?.[2]?.value,
        },
      },
    });
  };

  useEffect(() => {
    form?.setFieldsValue({
      displayName: leaveRecord?.user?.displayName,
      leaveType: leaveRecord?.type,
      leaveDate:
        leaveRecord?.startDate === leaveRecord?.endDate
          ? dayjs(leaveRecord?.startDate)?.format(LEAVE_FORMAT)
          : `${dayjs(leaveRecord?.startDate)?.format(WEEK_FORMAT)} - ${dayjs(
              leaveRecord?.endDate,
            )?.format(LEAVE_FORMAT)}`,
      totalDuration: leaveRecord?.duration,
      note: leaveRecord?.reason,
    });
  }, [leaveRecord]);

  const handleCancel = () => {
    form?.resetFields();
    setModalVisible(false);
  };

  return (
    <Modal
      title="Cancel Leave"
      open={visible}
      onCancel={handleCancel}
      footer={null}
      centered
    >
      <Form
        layout="vertical"
        className="intern-leave-modal"
        form={form}
        onFinish={onFinish}
        onFieldsChange={() => {
          setBtnDisable(false);
        }}
      >
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <Form.Item name="displayName" label="Employee Name">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="leaveType" label="Leave Type">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="leaveDate" label="Leave Date">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="totalDuration" label="Total Duration">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="note" label="Note">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="reason"
          label="Reason"
          rules={[
            {
              required: true,
              message: 'Please Add Reason!',
            },
          ]}
        >
          <Input.TextArea
            rows={3}
            placeholder="Type here..."
            maxLength={255}
            showCount
          />
        </Form.Item>
        <Form.Item>
          <Button onClick={() => setModalVisible(false)}>Cancel</Button>
          <Button
            className="ml-16"
            type="primary"
            htmlType="submit"
            disabled={btnDisable}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LeaveModal;
