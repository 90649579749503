import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../AppContext';

import FooterLogo from '../assets/images/logicwind-logo.png';
import {
  BREAKPOINTS,
  LEVEL,
  ROLE_KEYS,
  ROUTES,
  USER,
} from '../common/constants';
import MobileLogoComponent from '../components/MobileLogoComponent';
import RouterPrompt from '../components/RouterPrompt';
import ContentRoutes from './ContentRoutes';
import Sidebar from './components/sidebar/Sidebar';

const { Content, Sider } = Layout;
const App = () => {
  const location = useLocation();
  // eslint-disable-next-line no-undef
  const user = JSON.parse(localStorage.getItem(USER));
  const { state } = useContext(AppContext);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [isActive, setActive] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const handleOverlay = () => {
    setActive(!isActive);
  };
  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if ('serviceWorker' in navigator) {
      // eslint-disable-next-line no-undef
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
  }, [location]);

  return (
    <>
      <header>
        <Link className="logo" to={ROUTES?.MAIN}>
          <img alt="logo" className="mr-0" src="/logo.png" />
        </Link>
        <div className="toggle-btn" onClick={handleToggle}>
          {collapsed ? (
            <MenuOutlined className="humber-icon" />
          ) : (
            <CloseOutlined className="humber-icon" />
          )}
        </div>
      </header>
      <Layout hasSider>
        <RouterPrompt openPrompt={state?.showPrompt} />
        {!isDesktopViewport && (
          <span
            className={
              isActive ? 'active overlay-responsive' : 'overlay-disable'
            }
            onClick={handleOverlay}
          />
        )}

        <Sider
          width={210}
          collapsible
          theme="light"
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          className={isActive ? 'close' : null}
          breakpoint={BREAKPOINTS?.mobile}
        >
          {[ROLE_KEYS?.SUPER_ADMIN, ROLE_KEYS?.ADMIN]?.includes(user?.role) ||
          [LEVEL?.L1, LEVEL?.L2]?.includes(user?.level?.name) ? (
            <Link id="logo" to={ROUTES?.META_REPORTS}>
              {collapsed || !isDesktopViewport ? (
                <MobileLogoComponent className="mr-0" />
              ) : (
                <>
                  <MobileLogoComponent className="mr-8" />
                  LW Hub
                </>
              )}
            </Link>
          ) : (
            <Link id="logo" to={ROUTES?.MAIN}>
              {collapsed || !isDesktopViewport ? (
                <MobileLogoComponent className="mr-0" />
              ) : (
                <>
                  <MobileLogoComponent className="mr-8" />
                  LW Hub
                </>
              )}
            </Link>
          )}
          <Sidebar collapsed={collapsed || !isDesktopViewport} />
        </Sider>
        <Layout className="site-layout">
          <Content className="wrapper">
            <ContentRoutes />
          </Content>
          <footer>
            <div className="text-center">
              Logicwind © {new Date().getFullYear()}
            </div>
            <div className="crafted">
              Crafted Mindfully at{' '}
              <a
                href="https://www.logicwind.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={FooterLogo} alt="footer-logo" />
              </a>
            </div>
          </footer>
        </Layout>
      </Layout>
    </>
  );
};

export default App;
