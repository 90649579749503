import { useMutation } from '@apollo/client';
import { Button, Col, DatePicker, Form, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import {
  BACKEND_MONTH_FORMAT,
  FULL_MONTH_FORMAT,
} from '../../../common/constants';
import { CREATE_MONTHLY_EXCEL_SHEET } from '../graphql/Mutations';

const CreateSheetModal = (props) => {
  const [form] = Form?.useForm();
  const { show, close } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [createSheet] = useMutation(CREATE_MONTHLY_EXCEL_SHEET, {
    fetchPolicy: 'network-only',
    onCompleted() {
      setIsButtonDisabled(false);
      close();
    },
    onError() {
      setIsButtonDisabled(false);
    },
  });

  const onFinish = async (values) => {
    setIsButtonDisabled(true);
    const month = dayjs(values?.month)
      ?.format(FULL_MONTH_FORMAT)
      ?.toUpperCase();
    const year = dayjs(values?.month)?.get('year');
    await createSheet({
      variables: {
        input: {
          month,
          year,
        },
      },
    });
  };

  return (
    <Modal
      title="Create Sheet"
      open={show}
      onCancel={close}
      footer={null}
      centered
      destroyOnClose
    >
      <div className="form mt-32">
        <Form
          name="create-sheet-form"
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          <Row gutter={[8, 0]}>
            <Col className="sheet-month-picker">
              <Form.Item
                name="month"
                label="Select Month:"
                initialValue={dayjs().subtract(1, 'month')}
              >
                <DatePicker
                  format={BACKEND_MONTH_FORMAT}
                  className="sheet-month-picker"
                  picker="month"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isButtonDisabled}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateSheetModal;
