import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import CommonSelect from '../../components/CommonSelect';
import {
  CREATE_METABASE_REPORT,
  UPDATE_METABASE_REPORT,
} from './graphql/Mutations';
import { GET_METABASE_REPORTS } from './graphql/Queries';

const MetaReports = () => {
  const [form] = Form?.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refetchType, setRefetchType] = useState('');
  const [sheetUrlData, setSheetUrlData] = useState([]);
  const [filteredSheetData, setFilteredSheetData] = useState([]);

  const [createMetabaseReport] = useMutation(CREATE_METABASE_REPORT, {
    onCompleted: () => {
      setModalVisible(false);
      form?.resetFields();
      setSheetUrlData([]);
      setFilteredSheetData([]);
      setRefetchType('create');
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('Error :', error);
    },
  });

  const [updateMetabaseReport] = useMutation(UPDATE_METABASE_REPORT, {
    onCompleted: (res) => {
      setModalVisible(false);
      form?.resetFields();
      setSheetUrlData([]);
      const updatedData = {
        ...filteredSheetData,
        title: res?.updateMetabaseReport?.data?.title,
        url: res?.updateMetabaseReport?.data?.url,
        permissions: res?.updateMetabaseReport?.data?.permissions,
      };
      setFilteredSheetData(updatedData);
      setRefetchType('update');
      setIsEdit(false);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('Error :', error);
    },
  });

  useEffect(() => {
    if (isEdit) {
      form?.setFieldsValue({
        title: filteredSheetData?.title || sheetUrlData?.[0]?.title,
        url: filteredSheetData?.url || sheetUrlData?.[0]?.url,
        permissions:
          filteredSheetData?.permissions?.join(',') ||
          sheetUrlData?.[0]?.permissions?.join(','),
      });
    }
  }, [isEdit, filteredSheetData, sheetUrlData]);

  const reportSelection = (value) => {
    setFilteredSheetData(
      sheetUrlData?.filter((item) => item?.id === value)?.[0],
    );
  };

  const onFinish = async (values) => {
    const metaBasePayload = {
      title: values?.title,
      url: values?.url,
      permissions: values?.permissions?.split(','),
    };
    setRefetchType('');
    try {
      if (isEdit) {
        await updateMetabaseReport({
          variables: {
            where: {
              id: filteredSheetData?.id || sheetUrlData?.[0]?.id,
            },
            data: {
              ...metaBasePayload,
            },
          },
        });
      } else {
        await createMetabaseReport({
          variables: {
            data: {
              ...metaBasePayload,
            },
          },
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error :', error);
    }
  };

  return (
    <div className="meta-report-iframe">
      <Space>
        <h3 className="m-0">Report :</h3>
        <CommonSelect
          placeholder="Select Project"
          query={GET_METABASE_REPORTS}
          onChange={reportSelection}
          responsePath="getMetabaseReports.data"
          valuePath="id"
          labelPath="title"
          fetchPolicy="network-only"
          virtual={false}
          getApiData={(records) => {
            setSheetUrlData(records);
          }}
          useEffectDeps={[refetchType]}
          isDataDependent={[refetchType]}
          value={filteredSheetData?.title || sheetUrlData?.[0]?.title}
        />
        <div className="text-end">
          <Button
            type="primary"
            className="mr-8"
            icon={<EditOutlined />}
            onClick={() => {
              setModalVisible(true);
              setIsEdit(true);
            }}
          >
            Edit Report
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setModalVisible(true);
              setIsEdit(false);
            }}
          >
            Add Report
          </Button>
        </div>
      </Space>
      <iframe
        src={filteredSheetData?.url || sheetUrlData?.[0]?.url}
        title={filteredSheetData?.title || sheetUrlData?.[0]?.title}
      />
      {modalVisible && (
        <Modal
          title={
            <span className="modal-title">{`${
              isEdit ? 'Edit' : 'Add'
            } Report`}</span>
          }
          open={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            form?.resetFields();
            if (isEdit) {
              setIsEdit(false);
            }
          }}
          onOk={() => form?.submit()}
          okButtonProps={{ disabled: !isValueChanged }}
          destroyOnClose
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFieldsChange={() => setIsValueChanged(true)}
          >
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: 'Please Add Title',
                },
              ]}
            >
              <Input placeholder="Please Enter Title" />
            </Form.Item>
            <Form.Item
              name="url"
              label="Meta Report Url"
              rules={[
                {
                  required: true,
                  message: 'Please Add Meta Report Url',
                },
              ]}
            >
              <Input placeholder="Please Enter Meta Report Url" />
            </Form.Item>
            <Form.Item
              name="permissions"
              label="Permission"
              rules={[
                {
                  required: true,
                  message: 'Please Add Permission',
                },
              ]}
            >
              <Input placeholder="Please Enter Permission" />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default MetaReports;
