import { gql } from '@apollo/client';

export const GET_EVENTS = gql`
  query EventsAdmin($filters: EventFilter!) {
    eventsAdmin(filters: $filters) {
      count
      events {
        id
        name
        status
        isSyncProgress
        typeId
        categoryId
        startDate
        endDate
        isEntryFee
        allowSpouse
        locationName
        noOfGuests
        maxAttendees
        noOfWaitlist
        isVisible
        constantContactFormUrl
        constantContactTitle
        pdfDetails
        type {
          id
          key
          value
          isDisabled
        }
        category {
          id
          key
          value
          isDisabled
        }
        notes
        description
        noOfRsvp
        noOfEventGuestsAttending
        noOfEventAttendingAttendees
        noOfEventNotAttendingAttendees
        noOfEventWaitlistAttendees
        noOfNotRespondedUsers
        notRespondedUsers {
          displayName
        }
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query EventCategories($filter: EventCategoryFilter!) {
    eventCategories(filter: $filter) {
      eventCategories {
        id
        key
        value
        isDisabled
      }
    }
  }
`;

export const GET_EVENT_TYPES = gql`
  query EventTypes($filter: EventTypeFilter!) {
    eventTypes(filter: $filter) {
      eventTypes {
        id
        key
        value
        isDisabled
      }
    }
  }
`;

export const GET_SINGLE_EVENT = gql`
  query Event($eventId: ID!) {
    event(id: $eventId) {
      data {
        id
        name
        categoryId
        typeId
        startDate
        endDate
        locationName
        streetAddress
        suiteNo
        city
        state
        zipcode
        headerImage
        notes
        description
        contacts
        noOfGuests
        maxAttendees
        isDonationFee
        donationFee
        isEntryFee
        entryFee
        ministries
        registrationStartDate
        registrationCloseDate
        feedbackStartDate
        feedbackCloseDate
        availabilityDate
        isTableSponsored
        pricePerTable
        seatsPerTable
        status
        folderId
        additionalPhotos
        constantContactFormId
        isVirtualEvent
        virtualEventUrl
        constantContactRefId
        paymentUrl
        donationUrl
        allowSpouse
        noOfWaitlist
        isVisible
        pdfUrls
        constantContactFormUrl
        constantContactTitle
        pdfDetails
        isSyncProgress
      }
    }
  }
`;
