import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import ProjectResourceSheet from './ProjectResourceSheet';

const ProjectResourceWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.PROJECT_RESOURCE_SHEET}
      component={ProjectResourceSheet}
    />
  </Switch>
);

export default ProjectResourceWrapper;
