import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import Error404 from '../../Error404';
import {
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import AlertConfig from './AlertConfig';
import './alertConfig.less';

const AlertConfigWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES_MODULES_KEY?.ALERT_CONFIG}`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.ALERT_CONFIG}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <AlertConfig {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" component={Error404} />
  </Switch>
);

export default AlertConfigWrapper;
