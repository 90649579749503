import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import {
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import HourLogSummary from './HourLogSummary';
import './hourLogSummary.less';

const HourLogSummaryWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES_MODULES_KEY?.HOUR_LOG_SUMMARY}`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.HOURS_LOG_HISTORY}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <HourLogSummary {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default HourLogSummaryWrapper;
