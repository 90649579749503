import {
  BellOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FilterFilled,
  PlusOutlined,
  QuestionOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Form,
  Modal,
  Select,
  Space,
  Tag,
  Tooltip,
  message,
} from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { map, trim } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import {
  EVENT_COLOR,
  EVENT_COUNT,
  EVENT_STATUS,
  EXCEL_SHEET_DATE_FORMAT,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROLE_KEYS,
  ROUTES_MODULES_KEY,
  USER,
} from '../../common/constants';
import { formatDate, handleDownloadExcelSheet } from '../../common/utils';
import CommonButton from '../../components/CommonButton';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import VerifyPermissions from '../../components/VerifyPermissions';
import NotificationModal from './components/NotificationModal';
import { DELETE_EVENT, PUBLISH_EVENT, UPDATE_EVENT } from './graphql/Mutation';
import { GET_EVENTS } from './graphql/Queries';

const { Option } = Select;

const Events = () => {
  // eslint-disable-next-line no-undef
  const user = JSON.parse(localStorage.getItem(USER));
  const history = useHistory();
  const { getToken } = useContext(AppContext);
  const token = getToken();
  const initialEventFilter = {
    skip: 0,
    limit: LIMIT,
    sortBy: 'createdAt_DESC',
    status: null,
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [form] = Form?.useForm();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [eventFilter, setEventFilter] = useState(initialEventFilter);
  const [showModal, setShowModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [notificationData, setNotificationData] = useState();
  const [statusFilterVisible, setStatusFilterVisible] = useState(false);
  const [isResponseModalVisible, setIsResponseModalVisible] = useState(false);
  const [responseData, setResponseData] = useState({});

  const isCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.EVENT_MANAGEMENT,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });
  const isUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.EVENT_MANAGEMENT,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });
  const isDeletePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.EVENT_MANAGEMENT,
    allowedPermissions: [PERMISSION_OBJECT?.delete],
  });

  const [fetchEventData, { loading, data, refetch }] = useLazyQuery(
    GET_EVENTS,
    {
      variables: { filters: eventFilter },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: 10,
          total: res?.eventsAdmin?.count,
        };
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );

  useEffect(() => {
    fetchEventData({ variables: { filters: eventFilter } });
  }, []);

  const [deleteEvent, { loading: removing }] = useMutation(DELETE_EVENT);
  const [updateEvent] = useMutation(UPDATE_EVENT, {
    onCompleted() {
      refetch();
    },
  });
  const [publishEvent, { loading: publishing }] = useMutation(PUBLISH_EVENT, {
    onCompleted() {
      refetch();
    },
  });

  const onPublishUnPublish = ({
    name,
    categoryId,
    typeId,
    startDate,
    endDate,
    status,
    id,
  }) => {
    Modal?.confirm({
      icon: <ExclamationCircleOutlined />,
      title: `${
        status === EVENT_STATUS.PUBLISHED ? 'Un-Publish' : 'Publish'
      } Event`,
      content: `Are you sure, you want to ${
        status === EVENT_STATUS.PUBLISHED ? 'Un-Publish' : 'Publish'
      } this event?`,
      centered: true,
      okText: `Yes, ${
        status === EVENT_STATUS.PUBLISHED ? 'Un-Publish' : 'Publish'
      }`,
      cancelText: 'No',
      okButtonProps: { publishing },
      className: 'custom-modal-button',
      onOk: async () =>
        status === EVENT_STATUS.PUBLISHED
          ? updateEvent({
              variables: {
                data: {
                  name,
                  categoryId,
                  typeId,
                  startDate,
                  endDate,
                  status:
                    status === EVENT_STATUS.PUBLISHED
                      ? EVENT_STATUS.UNPUBLISHED
                      : EVENT_STATUS.PUBLISHED,
                },
                updateEventId: id,
              },
            })
          : publishEvent({
              variables: {
                publishEventId: id,
              },
            }),
    });
  };

  const onDeleteClick = (id) => {
    Modal?.confirm({
      closable: false,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      title: 'Delete Event',
      content: 'Are you sure you want to delete this event?',
      okText: 'Yes',
      cancelText: 'No',
      className: 'custom-modal-button',
      okButtonProps: { removing },
      onOk: async () =>
        deleteEvent({
          variables: {
            removeEventId: id,
          },
        })
          .then(() => {
            refetch();
          })
          .catch((err) => err),
    });
  };

  const onDuplicate = (id) => {
    Modal?.confirm({
      closable: false,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      title: 'Duplicate Event',
      content: 'Are you sure you want to duplicate this event?',
      okText: 'Yes',
      cancelText: 'No',
      className: 'custom-modal-button',
      onOk: () => {
        history.push(`${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}/duplicate/${id}`);
      },
    });
  };

  const getCsv = async (record) => {
    const url = `${process.env.REACT_APP_SERVER_REST_URL}/api/v1/event/download-excel-sheet/${record?.id}`;
    await axios
      .post(url, null, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleDownloadExcelSheet(
          res?.data,
          `${record?.name?.replace(/\s+/g, '')}_${dayjs(record?.startDate)
            ?.format(EXCEL_SHEET_DATE_FORMAT)
            ?.replace(':', '.')
            ?.replace(/\s+/g, '_')}.xlsx`,
        );
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  };

  // comment for future use
  // const renderContactForm = (value) => {
  //   if (value?.length > 0) {
  //     return map(value, (item, index) => (
  //       <div key={item?.id}>
  //         <a
  //           href={item}
  //           target="_blank"
  //           rel="noopener noreferrer"
  //         >{`Link - ${index}`}</a>
  //         <br />
  //       </div>
  //     ));
  //   }
  //   return '--';
  // };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setEventFilter({ ...eventFilter, skip, limit: pagination.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setEventFilter({
        ...eventFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: `${sorter?.field}_${
          sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }`,
      });
      fetchEventData({
        variables: {
          filters: {
            ...eventFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: `${sorter?.field}_${
              sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }`,
          },
        },
      });
    } else {
      setEventFilter({
        ...eventFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: 'createdAt_DESC',
      });
      fetchEventData({
        variables: {
          filters: {
            ...eventFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: 'createdAt_DESC',
          },
        },
      });
    }
  };

  const handleStatusChanges = (value) => {
    setEventFilter({
      ...eventFilter,
      status: value,
    });
  };

  const handleFilter = () => {
    setEventFilter({
      ...eventFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, currentPage: 1 });
    fetchEventData({
      variables: {
        filters: {
          ...eventFilter,
          skip: 0,
        },
      },
    });
    setStatusFilterVisible(false);
  };

  const handleReset = () => {
    form?.resetFields();
    setEventFilter({
      ...eventFilter,
      skip: 0,
      status: null,
    });
    setPaginationProp({ ...paginationProp, currentPage: 1 });
    fetchEventData({
      variables: {
        filters: {
          ...eventFilter,
          skip: 0,
          status: null,
        },
      },
    });
    setStatusFilterVisible(false);
  };

  const syncButtonSpecificEvent = async (id) => {
    const url = `${process.env.REACT_APP_SERVER_REST_URL}/attendee/sync?event_id=${id}`;
    await axios
      .get(url, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        message.success(res?.data?.message);
      });
  };

  const getEventFilterProps = () => ({
    filterDropdownOpen: statusFilterVisible,
    onFilterDropdownOpenChange: (visible) => setStatusFilterVisible(visible),
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <Form name="control-ref" form={form} layout="vertical">
            <Form.Item name="status">
              <Select
                allowClear
                className="w-150 mr-8 mb-5"
                placeholder="Sort By Status"
                onChange={handleStatusChanges}
              >
                {map(EVENT_STATUS, (status) => (
                  <Option key={status} value={status} label={status}>
                    {status}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <Space direction="horizontal">
            <Button size="small" onClick={handleFilter}>
              Apply
            </Button>
            <Button
              danger
              size="small"
              onClick={() => {
                handleReset();
                setStatusFilterVisible(false);
              }}
            >
              Reset
            </Button>
          </Space>
        </Space>
      </div>
    ),

    filterIcon: () => (
      <FilterFilled
        className={eventFilter?.status ? 'filter-filled-data' : ''}
      />
    ),
  });

  const columns = [
    {
      title: 'Event',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
    },
    {
      title: 'Date & Time',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'startDate' && sortedInfo?.order,
      render: (value, record) => formatDate(record?.startDate),
    },
    {
      title: 'Location',
      dataIndex: 'locationName',
      key: 'location',
    },
    {
      title: 'Count',
      render: (value, record) => (
        <>
          {record?.noOfEventAttendingAttendees > 0 && (
            <Tag className="event-count-icons" color={EVENT_COLOR?.GREEN}>
              <Tooltip title={EVENT_COUNT?.ATTENDING}>
                <PlusOutlined height={8} width={8} />
                {record?.noOfEventAttendingAttendees}
              </Tooltip>
            </Tag>
          )}
          {record?.noOfEventNotAttendingAttendees > 0 && (
            <Tag className="event-count-icons" color={EVENT_COLOR?.RED}>
              <Tooltip title={EVENT_COUNT?.NOT_ATTENDING}>
                <CloseOutlined />
                {record?.noOfEventNotAttendingAttendees}
              </Tooltip>
            </Tag>
          )}
          {record?.noOfEventWaitlistAttendees > 0 && (
            <Tag className="event-count-icons" color={EVENT_COLOR?.YELLOW}>
              <Tooltip title={EVENT_COUNT?.MAYBE}>
                <QuestionOutlined />
                {record?.noOfEventWaitlistAttendees}
              </Tooltip>
            </Tag>
          )}
          {record?.noOfEventGuestsAttending > 0 && (
            <Tag className="event-count-icons" color={EVENT_COLOR?.BLUE}>
              <Tooltip title={EVENT_COUNT?.GUEST}>
                <UsergroupAddOutlined />
                {record?.noOfEventGuestsAttending}
              </Tooltip>
            </Tag>
          )}
          {record?.noOfNotRespondedUsers > 0 && (
            <Tag
              className="event-count-icons"
              color={EVENT_COLOR?.ORANGE}
              onClick={() => {
                setIsResponseModalVisible(true);
                setResponseData(record);
              }}
            >
              <Tooltip title={EVENT_COUNT?.NOT_RESPONDED}>
                <ClockCircleOutlined />
                {record?.noOfNotRespondedUsers}
              </Tooltip>
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
      ...getEventFilterProps(),
      render: (value, record) => (
        <Tag
          className={isUpdatePermission && 'pointer'}
          onClick={() => {
            if (isUpdatePermission) {
              onPublishUnPublish(record, record.status, record.id);
            }
          }}
          color={record?.status === EVENT_STATUS.PUBLISHED ? 'green' : 'red'}
        >
          {EVENT_STATUS[record?.status]}
        </Tag>
      ),
    },
    // comment for future use
    // {
    //   title: 'Form',
    //   key: 'form',
    //   render: (value, record) =>
    //     renderContactForm(record?.constantContactFormUrl),
    // },
    ...(isUpdatePermission || isDeletePermission
      ? [
          {
            title: 'Actions',
            key: 'actions',
            render: (value, record) => (
              <div className="d-flex align-center justify-center">
                {isUpdatePermission && (
                  <>
                    <Tooltip title="Send Notification">
                      <CommonButton
                        onClick={() => {
                          setShowModal(true);
                          setNotificationData(record);
                        }}
                        type="link"
                        disabled={!(record.status === EVENT_STATUS.PUBLISHED)}
                      >
                        <BellOutlined />
                      </CommonButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <CommonButton
                        type="link"
                        onClick={() => {
                          history.push(
                            `${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}/edit/${record?.id}`,
                          );
                        }}
                      >
                        <EditOutlined />
                      </CommonButton>
                    </Tooltip>
                    <Tooltip title="Duplicate">
                      <CommonButton
                        onClick={() => {
                          onDuplicate(record?.id);
                        }}
                        type="link"
                      >
                        <CopyOutlined />
                      </CommonButton>
                    </Tooltip>
                  </>
                )}
                {isDeletePermission && (
                  <Tooltip title="Delete">
                    <CommonButton
                      onClick={() => onDeleteClick(record?.id)}
                      type="link"
                      danger
                    >
                      <DeleteOutlined />
                    </CommonButton>
                  </Tooltip>
                )}
                {[ROLE_KEYS?.SUPER_ADMIN, ROLE_KEYS?.ADMIN]?.includes(
                  user?.role,
                ) && (
                  <Tooltip title="Export CSV">
                    <CommonButton
                      type="link"
                      disabled={record?.noOfEventAttendingAttendees <= 0}
                      onClick={(e) => {
                        e.stopPropagation();
                        getCsv(record);
                      }}
                    >
                      <DownloadOutlined />
                    </CommonButton>
                  </Tooltip>
                )}

                <Tooltip className="d-none" title="Sync">
                  <CommonButton
                    type="primary"
                    className="event-sync"
                    disabled={record?.isSyncProgress}
                    onClick={() => syncButtonSpecificEvent(record?.id)}
                  >
                    SYNC RSVP
                  </CommonButton>
                </Tooltip>
              </div>
            ),
          },
        ]
      : []),
  ];

  const onSearchChange = async (value) => {
    setEventFilter({ ...eventFilter, skip: 0, search: trim(value) });
    setPaginationProp({ ...paginationProp, current: 1 });
    fetchEventData({
      variables: { filters: { ...eventFilter, skip: 0, search: trim(value) } },
    });
    if (!value) {
      setEventFilter({ ...eventFilter, skip: 0, search: '' });
      fetchEventData({
        variables: { filters: { ...eventFilter, search: '' } },
      });
    }
  };

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Event Management"
        extra={
          <div className="project-filter">
            <SearchComponent getData={onSearchChange} />
            {isCreatePermission && (
              <Tooltip title="Add Event">
                <CommonButton
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    history.push(
                      `${ROUTES_MODULES_KEY?.EVENT_MANAGEMENT}/create`,
                    );
                  }}
                />
              </Tooltip>
            )}
          </div>
        }
      >
        <div className="card-body-wrapper">
          <CommonTable
            size="small"
            className="event-table"
            loadingData={loading}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            columns={columns}
            data={data?.eventsAdmin?.events || []}
            rowKey={(obj) => obj.id}
          />
          {showModal && (
            <NotificationModal
              showModal={showModal}
              setShowModal={setShowModal}
              data={notificationData}
              refetch={refetch}
            />
          )}
          {isResponseModalVisible && (
            <Modal
              title="Not Responded Employee"
              className="event-response-modal"
              open={isResponseModalVisible}
              onCancel={() => {
                setIsResponseModalVisible(false);
              }}
              footer={[
                <Button
                  onClick={() => {
                    setIsResponseModalVisible(false);
                  }}
                  key="back"
                  type="primary"
                >
                  Ok
                </Button>,
              ]}
            >
              <span className="not-responded-employee">
                {responseData?.notRespondedUsers
                  ?.map((item) => item?.displayName)
                  ?.join(', ')}
              </span>
            </Modal>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Events;
