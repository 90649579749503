import axios from 'axios';
import { TOKEN } from './constants';

const api = axios?.create({
  baseURL: process.env.REACT_APP_SERVER_REST_URL,
});

api?.interceptors?.request?.use(
  (config) => {
    // eslint-disable-next-line no-undef
    const token = localStorage?.getItem(TOKEN);
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise?.reject(error),
);

export default api;
