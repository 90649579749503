import React from 'react';

function LogicwindSignature({ profile, name, number, designation, website }) {
  return (
    <table
      width="100%"
      border="0"
      cellSpacing="0"
      cellPadding="0"
      style={{ borderCollapse: 'collapse' }}
    >
      <tbody>
        <tr>
          <td width="340">
            <table
              width="340"
              bgcolor="#F2F4F7"
              border="0"
              cellSpacing="0"
              cellPadding="0"
              style={{
                borderCollapse: 'collapse',
                backgroundColor: '#F2F4F7',
                borderRadius: '8px',
              }}
            >
              <tbody>
                <tr>
                  <td
                    width="122px"
                    valign="middle"
                    style={{
                      padding: '16px 0 16px 16px',
                      verticalAlign: 'middle',
                    }}
                  >
                    <img
                      alt="profile-img"
                      src={profile}
                      style={{ display: 'block', borderRadius: '50%' }}
                      height="90"
                      width="90"
                    />
                  </td>
                  <td style={{ padding: '16px 8px 16px 8px' }}>
                    <img
                      style={{ height: '90px', width: '2px' }}
                      src="https://lw-hub-dev.s3.ap-south-1.amazonaws.com/assets/lw-devider-signature.png"
                      alt="signature-img"
                    />
                  </td>
                  <td aria-label="information">
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ borderCollapse: 'collapse' }}
                    >
                      <tbody>
                        <tr>
                          <td
                            width="100%"
                            style={{
                              padding: '16px 15px 18px 0px',
                              fontFamily: 'Arial, sans-serif',
                              fontSize: '17px',
                              msoLineHeightRule: 'exactly',
                              lineHeight: '19px',
                              color: '#303542',
                              fontWeight: 'bold',
                            }}
                          >
                            <table
                              width="100%"
                              border="0"
                              cellSpacing="0"
                              cellPadding="0"
                              style={{ borderCollapse: 'collapse' }}
                            >
                              <tr>
                                <td style={{ width: '100%' }}>
                                  <div style={{ width: '100%' }}>
                                    <div
                                      nowrap
                                      style={{
                                        whiteSpace: 'nowrap',
                                        fontSize: '15px',
                                        lineHeight: '15px',
                                        letterSpacing: '0.6px',
                                        marginBottom: '6px',
                                        color: '#000000',
                                        fontFamily:
                                          "'Inter', 'Arial, Helvetica,Times New Roman, sans-serif'",
                                      }}
                                    >
                                      {name}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: '12px',
                                        color: '#000000',
                                        fontWeight: 500,
                                        letterSpacing: '0.5px',
                                        lineHeight: '13px',
                                        fontFamily:
                                          "'Inter', 'Arial, Helvetica,Times New Roman, sans-serif'",
                                      }}
                                    >
                                      {designation}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: 'right',
                                    verticalAlign: 'top',
                                  }}
                                >
                                  <div style={{ textAlign: 'right' }}>
                                    <img
                                      height="15px"
                                      width="68px"
                                      src="https://lw-hub-dev.s3.ap-south-1.amazonaws.com/assets/logicwind-lw.png"
                                      alt="company-logo"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="100%"
                            style={{
                              padding: '0 15px 16px 0px',
                              fontFamily: 'Arial, sans-serif',
                              fontSize: '17px',
                              msoLineHeightRule: 'exactly',
                              lineHeight: '19px',
                              color: '#303542',
                              fontWeight: 'bold',
                            }}
                          >
                            <table
                              width="100%"
                              border="0"
                              cellSpacing="0"
                              cellPadding="0"
                              style={{ borderCollapse: 'collapse' }}
                            >
                              <tr>
                                <td>
                                  <div
                                    nowrap
                                    style={{
                                      whiteSpace: 'nowrap',
                                      fontSize: '12px',
                                      lineHeight: '14px',
                                      fontWeight: 500,
                                      marginBottom: '4px',
                                      color: '#000000',
                                      fontFamily:
                                        "'Inter', 'Arial, Helvetica,Times New Roman, sans-serif'",
                                    }}
                                  >
                                    <img
                                      height="14px"
                                      width="14px"
                                      style={{
                                        verticalAlign: 'middle',
                                        marginRight: '4px',
                                      }}
                                      src="https://lw-hub-dev.s3.ap-south-1.amazonaws.com/assets/phone.png"
                                      alt="phone-img"
                                    />{' '}
                                    {number}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div
                                    nowrap
                                    style={{
                                      whiteSpace: 'nowrap',
                                      fontSize: '12px',
                                      lineHeight: '14px',
                                      fontWeight: 500,
                                      color: '#000000',
                                      fontFamily:
                                        "'Inter', 'Arial, Helvetica,Times New Roman, sans-serif'",
                                    }}
                                  >
                                    <img
                                      height="14px"
                                      width="14px"
                                      style={{
                                        verticalAlign: 'middle',
                                        marginRight: '4px',
                                      }}
                                      src="https://lw-hub-dev.s3.ap-south-1.amazonaws.com/assets/globe.png"
                                      alt="globe-img"
                                    />{' '}
                                    <a
                                      href={`https://${website}`}
                                      target="_blank"
                                      style={{
                                        color: '#000000',
                                        textDecoration: 'none',
                                        fontWeight: '500',
                                        fontFamily:
                                          "'Inter', 'Arial, Helvetica,Times New Roman, sans-serif'",
                                      }}
                                      rel="noreferrer"
                                    >
                                      {website}
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr />
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
export default LogicwindSignature;
