import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_ATTENDEE = gql`
  mutation CreateAttendee($input: CreateAttendeeInput!) {
    createAttendee(input: $input) {
      message
    }
  }
`;

export const UPDATE_ATTENDEE = gql`
  mutation UpdateAttendee($input: UpdateAttendeeInput!) {
    updateAttendee(input: $input) {
      message
    }
  }
`;

export const REMOVE_ATTENDEE = gql`
  mutation RemoveAttendee($input: RemoveAttendeeType!) {
    removeAttendee(input: $input) {
      message
    }
  }
`;
