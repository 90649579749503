import { InfoCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Form, InputNumber, Modal, Popover, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  CALENDAR_VIEW_MODAL,
  MODULES_KEY,
  PERMISSION_OBJECT,
} from '../../../common/constants';
import VerifyPermissions from '../../../components/VerifyPermissions';
import { UPDATE_CONFIG } from '../graphql/Mutations';

const AlertConfigModal = ({ show, close, month, threshold, refetch }) => {
  const [form] = Form?.useForm();
  const [valueChanged, setValueChanged] = useState(false);

  const isUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.ALERT_CONFIG,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });

  const [updateConfig, { loading }] = useMutation(UPDATE_CONFIG, {
    onCompleted: () => {
      form?.resetFields();
      close();
      refetch();
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
    },
  });

  useEffect(() => {
    form?.setFieldsValue({
      month,
      threshold,
    });
  }, [month, threshold]);

  const onFinish = (values) => {
    updateConfig({
      variables: {
        input: [
          { key: CALENDAR_VIEW_MODAL.MONTHS, value: values?.month?.toString() },
          {
            key: CALENDAR_VIEW_MODAL.THRESHOLD_VALUE,
            value: values?.threshold?.toString(),
          },
        ],
      },
    });
  };

  const onCancel = () => {
    form?.resetFields();
    close();
  };

  return (
    <Modal
      title="Add Config"
      open={show}
      onCancel={onCancel}
      footer={null}
      centered
      destroyOnClose
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        onFieldsChange={() => setValueChanged(true)}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              label={
                <span>
                  No. of months&nbsp;
                  <Popover
                    placement="right"
                    overlayClassName="alert-config-description"
                    content={
                      <span>
                        Configure date range by selecting number of months to
                        apply from current date.
                      </span>
                    }
                  >
                    <InfoCircleOutlined />
                  </Popover>
                </span>
              }
              name="month"
              rules={[
                { required: true, message: 'Please input the no. of months!' },
              ]}
            >
              <InputNumber
                className="input-number"
                min={1}
                disabled={!isUpdatePermission}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <span>
                  Threshold&nbsp;
                  <Popover
                    placement="right"
                    overlayClassName="alert-config-description"
                    content={
                      <span>
                        Select a numeric value to set the total threshold for
                        the WFH+ Leaves module.
                      </span>
                    }
                  >
                    <InfoCircleOutlined />
                  </Popover>
                </span>
              }
              name="threshold"
              rules={[
                { required: true, message: 'Please input the threshold!' },
              ]}
            >
              <InputNumber
                className="input-number"
                style={{ width: '100%' }}
                min={1}
                disabled={!isUpdatePermission}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button"
                disabled={!valueChanged || loading}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AlertConfigModal;
