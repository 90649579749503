import { FilterFilled, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Card, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  BACKEND_DATE_FORMAT,
  DATE_TIME_FORMAT,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import VerifyPermissions from '../../components/VerifyPermissions';
import { ASSET_BOOKINGS } from './graphql/Queries';

const { RangePicker } = DatePicker;
function Booking() {
  const history = useHistory();
  const [initialFilter, setInitialFilter] = useState({
    skip: 0,
    currentPage: 1,
    pageSize: LIMIT,
    searchString: '',
  });
  const [searchAsset, setSearchAsset] = useState('');
  const isCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.ASSETS_LISTING,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });

  const [executeQuery, { loading, data }] = useLazyQuery(ASSET_BOOKINGS, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  useEffect(() => {
    executeQuery({
      variables: {
        filters: {
          skip: initialFilter?.skip,
          limit: LIMIT,
          searchString: initialFilter?.searchString,
        },
      },
    });
  }, []);

  const handlePagination = (page, pageSize) => {
    setInitialFilter({
      ...initialFilter,
      currentPage: page,
      pageSize,
    });
    executeQuery({
      variables: {
        filters: {
          skip: (page - 1) * pageSize,
          limit: pageSize,
          searchString: initialFilter?.searchString,
        },
      },
    });
  };

  const handleSearch = debounce((value) => {
    setInitialFilter({
      ...initialFilter,
      skip: 0,
      currentPage: 1,
      searchString: value?.trim(),
    });

    executeQuery({
      variables: {
        filters: {
          skip: initialFilter?.skip,
          limit: initialFilter?.pageSize,
          searchString: value?.trim(),
        },
      },
    });
  }, 500);

  const handleAssetSearch = debounce((value) => {
    setInitialFilter({
      ...initialFilter,
      skip: 0,
      currentPage: 1,
    });
    setSearchAsset(value?.trim());
    executeQuery({
      variables: {
        filters: {
          skip: initialFilter?.skip,
          limit: initialFilter?.pageSize,
          searchString: value?.trim(),
        },
      },
    });
  }, 700);

  const getAssetFilterProps = () => ({
    filterSearch: true,
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <SearchComponent getData={handleAssetSearch} name="Asset" />
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterFilled className={searchAsset ? 'filter-filled-data' : ''} />
    ),
  });

  const onRangeChange = (dates) => {
    executeQuery({
      variables: {
        filters: {
          skip: initialFilter?.skip,
          limit: LIMIT,
          searchString: '',
          startDate: dayjs(dates?.[0])?.format(BACKEND_DATE_FORMAT),
          endDate: dayjs(dates?.[1])?.format(BACKEND_DATE_FORMAT),
        },
      },
    });
  };

  const disabledDate = (current) => {
    const currentDate = new Date();
    return current && current?.valueOf() > currentDate?.getTime();
  };
  const columns = [
    {
      title: 'Sr.No',
      align: 'center',
      width: 50,
      render: (_, __, index) => (
        <>
          {(initialFilter?.currentPage - 1) * initialFilter?.pageSize +
            index +
            1}
        </>
      ),
    },
    {
      title: 'Asset ID',
      dataIndex: 'assetId',
      key: 'assetId',
      align: 'center',
      render: (_, record) => record?.asset?.assetId || '-',
    },
    {
      title: 'Asset Name',
      dataIndex: 'assetName',
      key: 'assetName',
      align: 'center',
      render: (_, record) => record?.asset?.assetName || '-',
      ...getAssetFilterProps(),
    },
    {
      title: 'Booked by',
      dataIndex: 'displayName',
      key: 'displayName',
      align: 'center',
      render: (_, record) => record?.user?.displayName || '-',
    },
    {
      title: 'Clock IN',
      dataIndex: 'clockIn',
      key: 'clockIn',
      align: 'center',
      render: (_, record) =>
        record?.assignedAt
          ? dayjs(record?.assignedAt)?.format(DATE_TIME_FORMAT)
          : '-',
    },
    {
      title: 'Clock Out',
      dataIndex: 'clockOut',
      key: 'clockOut',
      align: 'center',
      render: (_, record) =>
        record?.submittedAt
          ? dayjs(record?.submittedAt)?.format(DATE_TIME_FORMAT)
          : '-',
    },
  ];

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Mobile Booking and Management Tracker"
        extra={
          <>
            <div className="booking-filter">
              {isCreatePermission && (
                <Button
                  key="1"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    history?.push({
                      pathname: `${ROUTES_MODULES_KEY?.ASSETS_LISTING}/add`,
                    });
                  }}
                >
                  Add New Device
                </Button>
              )}
              <SearchComponent
                getData={handleSearch}
                name="By Asset Name, Employee Name"
              />
              <RangePicker
                disabledDate={disabledDate}
                onChange={onRangeChange}
              />
            </div>
          </>
        }
      >
        <div className="card-body-wrapper">
          <CommonTable
            size="small"
            className="booking-table"
            columns={columns}
            dataSource={data?.assetBookings?.assetBookings}
            loadingData={loading}
            rowKey={(obj) => obj?.id}
            bordered
            paginationConfig={{
              current: initialFilter?.currentPage,
              total: data?.assetBookings?.count,
              pageSize: initialFilter?.pageSize,
              onChange: handlePagination,
            }}
          />
        </div>
      </Card>
    </div>
  );
}

export default Booking;
