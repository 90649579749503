import { gql } from '@apollo/client';

export const USERS = gql`
  query users($filters: AdminSearchInput) {
    users(filters: $filters) {
      count
      users {
        billable
        id
        displayName
        email
        jobTitle
        department {
          name
        }
        isActive
        isIntern
      }
    }
  }
`;

export const GET_ATTENDEES = gql`
  query Attendees($input: ListInput!) {
    attendees(input: $input) {
      total
      data {
        id
        eventId
        typeId
        userId
        status
        createdAt
        guests {
          id
          attendeeId
          firstName
          lastName
          email
          phoneNo
          city
          state
          company
          jobTitle
          masterProgram
        }
        attendeeType {
          id
          key
          value
        }
        user {
          id
          displayName
          firstName
          lastName
        }
        event {
          id
          name
          status
          startDate
          endDate
        }
        noOfGuests
        deletedAt
      }
    }
  }
`;

export const GET_ATTENDEE_TYPES = gql`
  query Data {
    attendeeTypes {
      data {
        id
        key
        value
        isDisabled
      }
    }
  }
`;

export const GET_SINGLE_ATTENDEE = gql`
  query AttendeeDetails($attendeeDetailsId: ID!) {
    attendeeDetails(id: $attendeeDetailsId) {
      createdAt
      id
      eventId
      status
      typeId
      user {
        id
        firstName
        lastName
        displayName
      }
      attendeeType {
        id
        key
        value
      }
      guests {
        firstName
        lastName
        email
        phoneNo
      }
    }
  }
`;
