import { useMutation } from '@apollo/client';
import { Form, Input, Modal, Select } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { SEND_NOTIFICATION } from '../graphql/Mutation';

const NotificationModal = ({ showModal, setShowModal, data, refetch }) => {
  const { TextArea } = Input;
  const { Option } = Select;
  const [form] = Form.useForm();
  const handleCancel = () => {
    setShowModal(false);
  };

  const trimFormValues = (val, key) => {
    const newValue = val.target.value.trim();
    form.setFieldsValue({ [key]: newValue });
  };

  const [sendNotification, { loading }] = useMutation(SEND_NOTIFICATION);

  const onFinish = async (formValues) => {
    const response = await sendNotification({
      variables: {
        data: {
          ...formValues,
        },
        sendNotificationId: data?.id,
      },
    });
    if (response?.data?.sendNotification) {
      form.resetFields();
      setShowModal(false);
      refetch();
    }
  };

  const handleOK = () => {
    form.submit();
  };

  return (
    <Modal
      open={showModal}
      onCancel={handleCancel}
      title="Send Notification"
      onOk={handleOK}
      confirmLoading={loading}
      okText="Send Now"
    >
      <h4 className="mb-8">{data?.name}</h4>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ sendTo: 'all' }}
      >
        <Form.Item
          name="title"
          rules={[
            {
              validator(rule, value, callback) {
                const newValue = value && value.trim();
                if (newValue) {
                  if (isEmpty(newValue) || newValue === '') {
                    callback('Required');
                  }
                  if (newValue.length > 50) {
                    callback('Max length is 50 characters.');
                  }
                } else {
                  callback('Required');
                }
                callback();
              },
            },
          ]}
        >
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item
          name="description"
          rules={[
            {
              validator(rule, value, callback) {
                const newValue = value && value.trim();
                if (newValue) {
                  if (isEmpty(newValue) || newValue === '') {
                    callback('Required');
                  }
                  if (newValue.length > 150) {
                    callback('Max length is 150 characters.');
                  }
                } else {
                  callback('Required');
                }
                callback();
              },
            },
          ]}
        >
          <TextArea
            placeholder="Description"
            rows={4}
            onBlur={(val) => trimFormValues(val, 'description')}
          />
        </Form.Item>
        <Form.Item name="sendTo">
          <Select placeholder="Select User" allowClear>
            <Option key="all" value="all">
              All User
            </Option>
            <Option key="ATTENDING" value="ATTENDING">
              Attendee
            </Option>
            <Option key="NOT_ATTENDING" value="NOT_ATTENDING">
              Non-Attendee
            </Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NotificationModal;
