import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const LOG_ENTRIES = gql`
  query eventLogsEntries($filters: LogEntryFilter!) {
    eventLogsEntries(filters: $filters) {
      count
      logEntries {
        id
        userId
        actionBy {
          displayName
          firstName
          lastName
        }
        moduleName
        actionType
        recordId
        description
        oldData
        newData
        createdAt
        updatedAt
      }
    }
  }
`;
