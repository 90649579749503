import { gql } from '@apollo/client';

export const INCIDENTS_LISTING = gql`
  query IncidentReportsAdmin($filters: IncidentReportsFilterInput!) {
    incidentReportsAdmin(filters: $filters) {
      data {
        mom
        scheduledOn

        id
        raisedByUser {
          id
          displayName
          firstName
          lastName
          profileImage
          dateOfBirth
          dateOfJoining
          userAnniversaryCount
          jobTitle
          department {
            id
            name
          }
          managerId
        }
        reportingForUser {
          id
          displayName
          firstName
          lastName
          profileImage
          dateOfBirth
          dateOfJoining
          userAnniversaryCount
          jobTitle
          managerId
          department {
            id
            name
          }
        }
        date
        reasonForRejection
        reasonForResolving
        description
        severity
        projectName
        status
        conclusion
        createdAt
        updatedAt
        isDiscussionRequired
        incidentMaster {
          id
          name
          description
          status
        }
      }
      count
    }
  }
`;

export const DESIGNATIONS = gql`
  query designations($searchString: String, $filters: DesignationFiltersInput) {
    designations(searchString: $searchString, filters: $filters) {
      count
      designations {
        jobTitle
      }
    }
  }
`;

export const USERS = gql`
  query users($filters: AdminSearchInput) {
    users(filters: $filters) {
      count
      users {
        firstName
        lastName
        displayName
        id
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  query searchUsers($searchString: String, $idsToExclude: [ID]) {
    searchUsers(searchString: $searchString, idsToExclude: $idsToExclude) {
      id
      firstName
      lastName
      displayName
    }
  }
`;
