import { gql } from '@apollo/client';

export const RESOURCE_ALLOCATION = gql`
  query ResourceAllocations($filters: ResourceAllocationFilterInput!) {
    resourceAllocations(filters: $filters) {
      hoursAllocation {
        hours
        id
        projectId
        projectName
      }
      id
      leaves
      plannedHours
      totalHours
      user {
        displayName
        jobTitle
        departmentName
        id
      }
      weekNumber
    }
  }
`;

export const ACTIVE_PROJECTS = gql`
  query ProjectsQuarterView($filters: ProjectsFilterInput) {
    projectsQuarterView(filters: $filters) {
      count
      projects {
        id
        name
      }
    }
  }
`;

export const PROJECTS = gql`
  query QuarterlyAllocations($filters: QuarterlyAllocationFilter!) {
    quarterlyAllocations(filters: $filters) {
      projects {
        id
        name
        tags
        userQuarterlyResourceAllocation {
          id
          firstName
          lastName
          displayName
          totalHours
          quarterViewId
          projectQuarterlyResourceAllocation {
            id
            hours
            date
            quarterViewId
          }
        }
      }
    }
  }
`;

export const QUARTER_RESOURCE_LISTING = gql`
  query QuarterlyDistributions($filters: QuarterlyAllocationFilter!) {
    quarterlyDistributions(filters: $filters) {
      projects {
        id
        name
        tags
        userQuarterlyResourceAllocation {
          id
          firstName
          lastName
          displayName
          totalHours
          quarterViewId
          quarterlyDistributions {
            id
            hours
            totalHours
            startDate
            endDate
            quarterViewId
            projectId
            userId
          }
        }
      }
    }
  }
`;
