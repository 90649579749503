import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const MONTHLY_INSTANCES = gql`
  query monthlyInstances {
    monthlyInstances {
      count
      data {
        id
        level {
          id
          name
          description
        }
        lateComing
        workFromHome
        hoursAdjustment
        isReferral
      }
    }
  }
`;
