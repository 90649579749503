import { Button, Result } from 'antd';
import React from 'react';
import { ROUTES } from './common/constants';
import VerifyPermissions from './components/VerifyPermissions';
import history from './historyData';

const AccessControl = ({
  children,
  modulekey,
  allowedPermissions,
  showNoAccess = false,
}) => {
  const hasPermission = VerifyPermissions({
    modulekey,
    allowedPermissions,
  });
  if (!hasPermission) {
    if (showNoAccess) {
      return (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button type="primary" onClick={() => history?.push(ROUTES?.MAIN)}>
              Back Home
            </Button>
          }
        />
      );
    }
    return;
  }

  return <>{children}</>;
};

export default AccessControl;
