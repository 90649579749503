import { Tooltip } from 'antd';
import React from 'react';

const PlannedCellRenderer = ({ data }) => {
  if (data?.totalHours && data?.plannedHours !== data?.totalHours) {
    return (
      <>
        {data?.plannedHours}
        <Tooltip
          title={
            <div>
              Planned Hours
              <br />
              {data?.plannedHours > data?.totalHours ? 'Exceed' : 'Below'} Total
              Hours
            </div>
          }
          placement="top"
        >
          <span className="planned-hours-tooltips" />
        </Tooltip>
      </>
    );
  }
  return data?.plannedHours;
};

export default PlannedCellRenderer;
