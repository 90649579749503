import { ExportOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, DatePicker, Result, Space } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  BILLING,
  BILLING_TYPE,
  HOUR_LOGS_DATE_TIME_FORMAT,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
} from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import CommonTable from '../../components/CommonTable';
import VerifyPermissions from '../../components/VerifyPermissions';
import { PROJECT_MEMBERS } from '../projects/graphql/Queries';
import { UPDATE_BILLABLE } from './graphql/Mutations';
import { GET_TRACKED_HOUR_LOGS, PROJECTS } from './graphql/Queries';

const { RangePicker } = DatePicker;

function HourLogSummary() {
  const [paginationFilter, setPaginationFilter] = useState({
    currentPage: 1,
    pageSize: LIMIT,
  });
  const [userSearch, setUserSearch] = useState('');
  const [projectSearch, setProjectSearch] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [userId, setUserId] = useState(null);
  const [projectId, setProjectId] = useState('');
  const [isUserSelectDisabled, setIsUserSelectDisabled] = useState(true);
  const [status, setStatus] = useState({
    billable: null,
    taskBillable: null,
  });
  const isUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.HOURS_LOG_HISTORY,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [filters, setFilters] = useState({
    skip: 0,
    limit: LIMIT,
  });

  const [executeQuery, { data, loading }] = useLazyQuery(
    GET_TRACKED_HOUR_LOGS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [updateBillable] = useMutation(UPDATE_BILLABLE, {
    fetchPolicy: 'network-only',
    onCompleted() {
      executeQuery({
        variables: {
          input: {
            filters: {
              ...filters,
            },
            userId,
            projectId,
            startDate: dateRange?.startDate,
            endDate: dateRange?.endDate,
            billable: status?.billable,
            taskBillable: status?.taskBillable,
          },
        },
      });
      setSelectedRowKeys([]);
    },
    onError() {},
  });

  const handleBillable = (billable) => {
    updateBillable({
      variables: {
        input: {
          billable: billable === BILLING_TYPE?.BILLABLE,
          taskIds: selectedRowKeys,
          userId,
          projectId,
          startDate: dateRange?.startDate,
          endDate: dateRange?.endDate,
        },
      },
    });
  };

  useEffect(() => {
    if (userId && projectId && dateRange?.startDate) {
      executeQuery({
        variables: {
          input: {
            filters: {
              ...filters,
            },
            userId,
            projectId,
            startDate: dateRange?.startDate,
            endDate: dateRange?.endDate,
            billable: status?.billable,
            taskBillable: status?.taskBillable,
          },
        },
      });
    }
  }, [filters, userId, projectId, dateRange, status]);

  useEffect(() => {
    if (userId) {
      setUserSearch('');
    }
    if (projectId) {
      setProjectSearch('');
    }
  }, [userId, projectId]);

  const userSelection = (value) => {
    setUserId(value);
    setPaginationFilter({
      ...paginationFilter,
      currentPage: 1,
    });
    setFilters({
      ...filters,
      skip: 0,
    });
  };

  const projectSelection = (value) => {
    setProjectId(value);
    setUserId(null);
    setIsUserSelectDisabled(!value);
    setPaginationFilter({
      ...paginationFilter,
      currentPage: 1,
    });
    setFilters({
      ...filters,
      skip: 0,
    });
  };

  const handleUserSearch = debounce((value) => {
    setUserSearch(value?.toString());
  }, 500);

  const handleProjectSearch = debounce((value) => {
    setProjectSearch(value?.toString());
  }, 500);

  const onChange = (pagination, filter) => {
    setPaginationFilter({
      ...paginationFilter,
      currentPage: pagination?.current,
      pageSize: pagination?.pageSize,
    });
    setFilters({
      skip: paginationFilter?.currentPage
        ? (pagination?.current - 1) * pagination?.pageSize
        : 0,
      limit: paginationFilter?.currentPage ? pagination?.pageSize : 50,
    });
    setStatus({
      ...status,
      billable: !filter?.billable ? null : filter?.billable?.[0],
      taskBillable: !filter?.taskBillable ? null : filter?.taskBillable?.[0],
    });
  };

  const onRangeChange = (dates) => {
    if (dates) {
      setDateRange({
        startDate: dayjs(dates?.[0])
          .startOf('day')
          .format(HOUR_LOGS_DATE_TIME_FORMAT),
        endDate: dayjs(dates?.[1])
          .endOf('day')
          .format(HOUR_LOGS_DATE_TIME_FORMAT),
      });
      setPaginationFilter({
        ...paginationFilter,
        currentPage: 1,
      });
      setFilters({
        ...filters,
        skip: 0,
      });
    }
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: 'Task List',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '30%',
      render: (value, record) => (
        <>
          <p className="name-column">
            {`${record?.space?.title}>${record?.folder?.title}>${record?.list?.title}`}
          </p>
          <Space>
            {record?.taskName}
            <div
              className="pointer export-icon"
              // eslint-disable-next-line no-undef
              onClick={() => window.open(record?.taskUrl, '_blank')}
            >
              <ExportOutlined />
            </div>
          </Space>
        </>
      ),
    },
    {
      title: 'Time Tracked',
      dataIndex: 'timeTracked',
      align: 'center',
      key: 'timeTracked',
    },
    {
      title: 'Task Billing Status',
      dataIndex: 'taskBillable',
      key: 'taskBillable',
      align: 'center',
      filters: BILLING,
      filterMultiple: false,
      render: (value) => (value ? 'Billable' : 'Non-Billable'),
    },
    {
      title: 'Billing Status',
      dataIndex: 'billable',
      key: 'billable',
      align: 'center',
      filters: BILLING,
      filterMultiple: false,
      render: (value) => (value ? 'Billable' : 'Non-Billable'),
    },
  ];

  return (
    <>
      <div className="table-card-page hour-logs-history">
        <Card
          title={
            <>
              Hour Logs Summary
              <Space className="hour-logs-header">
                <div className="hour-logs-filters">
                  <CommonSelect
                    placeholder="Select Project"
                    showSearch
                    query={PROJECTS}
                    onChange={projectSelection}
                    onSearch={handleProjectSearch}
                    responsePath="projectsHoursLogHistory.projects"
                    valuePath="id"
                    labelPath="name"
                    fetchPolicy="network-only"
                    variables={{
                      filters: { searchString: projectSearch },
                    }}
                    useEffectDeps={[projectSearch]}
                    virtual={false}
                  />
                  <RangePicker onChange={onRangeChange} />
                  <CommonSelect
                    placeholder="Select User"
                    showSearch
                    query={PROJECT_MEMBERS}
                    onChange={userSelection}
                    onSearch={handleUserSearch}
                    responsePath="projectMembers.data"
                    valuePath="user.id"
                    labelPath="user.displayName"
                    fetchPolicy="network-only"
                    useEffectDeps={[dateRange, projectId, userSearch]}
                    isDataDependent={[projectId, dateRange]}
                    conditionToCheckBeforeQuery={projectId?.length > 0}
                    variables={{
                      where: {
                        id: projectId,
                      },
                      filters: {
                        startDate: dateRange?.startDate,
                        endDate: dateRange?.endDate,
                      },
                    }}
                    disabled={isUserSelectDisabled || !dateRange?.startDate}
                    virtual={false}
                    value={userId}
                  />
                </div>
                <div className="hour-logs-buttons">
                  {isUpdatePermission && (
                    <>
                      <Button
                        type="primary"
                        onClick={() => handleBillable(BILLING_TYPE?.BILLABLE)}
                        disabled={!selectedRowKeys?.length}
                      >
                        Mark As Billable
                      </Button>
                      <Button
                        onClick={() =>
                          handleBillable(BILLING_TYPE?.NON_BILLABLE)
                        }
                        disabled={!selectedRowKeys?.length}
                      >
                        Mark As Non-Billable
                      </Button>
                    </>
                  )}
                </div>
              </Space>
            </>
          }
          className="ant-body-scroll"
        >
          {userId && projectId && dateRange?.startDate ? (
            <CommonTable
              size="small"
              className="hours-log-history-table"
              columns={columns}
              dataSource={data?.getTrackedHourLogsSummary?.data}
              loadingData={loading}
              onChange={onChange}
              rowKey={(record) => record?.taskId}
              rowSelection={
                isUpdatePermission && {
                  ...rowSelection,
                }
              }
              paginationConfig={{
                total: data?.getTrackedHourLogsSummary?.count,
                current: paginationFilter?.currentPage,
                pageSize: paginationFilter?.pageSize,
              }}
              bordered
            />
          ) : (
            <Result
              className="no-filters-selected"
              title="Please select employee name, project name and date range for
            which you need to see record."
              extra={<p>Note: All three filters are compulsory to select.</p>}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default HourLogSummary;
