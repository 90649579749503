import { message } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import client from '../apollo';
import { GET_SIGNED_URL } from '../components/graphql/Mutation';
import api from './api';
import { DATE_TIME_FORMAT, LEAVE_HALF_TYPE, REGEX } from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isPortalIdExists = (portalId) => !!injectUsingPortal(portalId);

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isDocumentIdExist = (portalId) =>
  !!getElementFromDocumentId(portalId);
// Check for document Id's exists end

export const formatDate = (dateTime, format = DATE_TIME_FORMAT) => {
  if (dateTime && moment && format) {
    return moment(dateTime)?.format(format);
  }

  return dateTime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid E-mail!');
      }
      return Promise?.resolve();
    },
  }),
  name: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (Number.isInteger(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    },
  }),
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

// Note : Function to upload on s3 bucket
export async function fileUpload(signedUrl, image, onUploadProgress) {
  try {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr?.open('PUT', signedUrl);
      xhr?.setRequestHeader('Content-Type', image?.type);
      xhr?.setRequestHeader('x-amz-acl', 'public-read');
      xhr?.addEventListener('readystatechange', function () {
        if (this?.readyState === 4) {
          resolve(xhr?.response);
        }
      });
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math?.ceil((e?.loaded / e?.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr?.send(image);
    });
  } catch (error) {
    message?.error(error?.message);
  }
}

export const getSignedUrl = async (fileObj) => {
  const fileName = fileObj?.name;

  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'write',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key,
      },
    },
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const uploadImage = async (signedRequest, fileObj) => {
  await api(signedRequest, {
    method: 'PUT',
    data: fileObj?.originFileObj || fileObj,
    headers: {
      'Content-Type': fileObj?.type,
    },
  });
};

export const fetchImage = async (fileObj) => {
  const fileName = fileObj?.name;
  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'read',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key,
      },
    },
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const getBase64 = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader.readAsDataURL(img);
  reader.addEventListener('load', () => callback(reader.result));
};

export const getTimeFromMins = (mins) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message?.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file?.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message?.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const handleCsvDownload = (data, name = 'data.csv') => {
  // eslint-disable-next-line no-undef
  const a = document.createElement('a');
  a.href = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(data)}`;
  a.target = '_Blank';
  a.download = name;
  // eslint-disable-next-line no-undef
  document.body.appendChild(a);
  a.click();
  // eslint-disable-next-line no-undef
  document.body.removeChild(a);
};

export const handleDownloadExcelSheet = (data, filename) => {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  // eslint-disable-next-line no-undef
  const url = window.URL.createObjectURL(blob);
  // eslint-disable-next-line no-undef
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  // eslint-disable-next-line no-undef
  document.body.appendChild(a);
  a.click();
  // eslint-disable-next-line no-undef
  window.URL.revokeObjectURL(url);
};

export const combineDateTimeFormat = (date, time) => {
  const newDate = dayjs(date)
    .set('hour', dayjs(time).hour())
    .set('minute', dayjs(time).minute())
    .set('second', dayjs(time).second());
  return newDate;
};

export const getLeaveTypeLabel = (item) => {
  const fromDate = item?.start_date;
  const toDate = item?.end_date;
  const isSameDay = dayjs(fromDate)?.isSame(toDate, 'day');

  const firstHalfLeave = LEAVE_HALF_TYPE?.[0]?.value;
  const secondHalfLeave = LEAVE_HALF_TYPE?.[1]?.value;
  const firstHalfLabel = LEAVE_HALF_TYPE?.[0]?.label;
  const secondHalfLabel = LEAVE_HALF_TYPE?.[1]?.label;

  const leaveTypeLabel = [];
  if (isSameDay && item?.from_leave_type === item?.to_leave_type) {
    leaveTypeLabel.push(
      ` (${
        item?.from_leave_type === firstHalfLeave
          ? firstHalfLabel
          : secondHalfLabel
      })`,
    );
  } else if (
    !isSameDay &&
    item?.from_leave_type === firstHalfLeave &&
    item?.to_leave_type === firstHalfLeave
  ) {
    leaveTypeLabel.push('', ` (${firstHalfLabel})`);
  } else if (
    !isSameDay &&
    item?.from_leave_type === secondHalfLeave &&
    item?.to_leave_type === secondHalfLeave
  ) {
    leaveTypeLabel.push(` (${secondHalfLabel})`);
  } else if (
    !isSameDay &&
    item?.from_leave_type === secondHalfLeave &&
    item?.to_leave_type === firstHalfLeave
  ) {
    leaveTypeLabel.push(
      ` (${item?.from_leave_type === secondHalfLeave && secondHalfLabel})`,
      ` (${item?.to_leave_type === firstHalfLeave && firstHalfLabel})`,
    );
  }

  return leaveTypeLabel;
};
