import { Button, DatePicker, Form, Input, Modal } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';

const { RangePicker } = DatePicker;

const ResourceModal = ({
  resourceModalVisible,
  handleResourceOk,
  handleResourceCancel,
  onResourceFinish,
  resourceForm,
  resourceCreate,
  items,
  itemClickInfo,
}) => {
  useEffect(() => {
    if (!resourceCreate) {
      const initialValue = items?.find((item) => item?.id === itemClickInfo);
      resourceForm?.setFieldsValue({
        title: initialValue?.title?.toString(),
        resourceTime: [
          dayjs(initialValue?.start_time),
          dayjs(initialValue?.end_time),
        ],
      });
    }
  }, [items, itemClickInfo]);

  const disabledDate = (current) =>
    current && current < dayjs()?.startOf('day');
  return (
    <Modal
      title={resourceCreate ? 'Create Resource' : 'Update Resource'}
      open={resourceModalVisible}
      onOk={handleResourceOk}
      onCancel={handleResourceCancel}
      maskClosable={false}
      className="custom-modal-footer"
      footer={[
        <Button key="submit" type="primary" onClick={handleResourceOk}>
          {resourceCreate ? 'Create' : 'Update'}
        </Button>,
        !resourceCreate && (
          <Button
            key="delete"
            onClick={() => handleResourceOk('delete')}
            type="primary"
            danger
          >
            Delete
          </Button>
        ),
      ]}
    >
      <Form form={resourceForm} onFinish={onResourceFinish}>
        <Form.Item
          name="title"
          label="Add Hours"
          rules={[
            {
              required: true,
              message: 'Please Enter Hours!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="resourceTime"
          label="Select Range"
          rules={[
            {
              required: true,
              message: 'Please Choose Start And End Time!',
            },
          ]}
        >
          <RangePicker disabledDate={disabledDate} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ResourceModal;
