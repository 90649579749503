import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Card, DatePicker, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { capitalize, debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  BACKEND_MONTH_FORMAT,
  LEAVE_FORMAT,
  LEAVE_HALF_TYPE,
  LEAVE_LABEL_FORMAT,
  LEAVE_STATUS,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROLES_SORT_FIELD,
  SORT_ORDER,
  WEEK_FORMAT,
} from '../../common/constants';
import CommonSelect from '../../components/CommonSelect';
import CommonTable from '../../components/CommonTable';
import VerifyPermissions from '../../components/VerifyPermissions';
import LeaveDrawer from './components/LeaveDrawer';
import LeaveModal from './components/LeaveModal';
import { DESIGNATIONS, LEAVE_LISTING } from './graphql/Queries';

const initialPagination = {
  current: 1,
  pageSize: LIMIT,
  total: 0,
};

const initialFilter = {
  skip: 0,
  searchStrings: [],
  sortOrder: SORT_ORDER?.[0]?.value,
  sortField: ROLES_SORT_FIELD?.[0]?.value,
  month: dayjs()?.format(BACKEND_MONTH_FORMAT),
};

function Leave() {
  const [paginationProp, setPaginationProp] = useState(initialPagination);
  const [filterProp, setFilterProp] = useState(initialFilter);
  const [modalVisible, setModalVisible] = useState(false);
  const [leaveRecord, setLeaveRecord] = useState([]);
  const [openLeaveDrawer, setOpenLeaveDrawer] = useState(false);
  const [designationSearch, setDesignationSearch] = useState('');

  const isLeaveCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.LEAVES,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });
  const isLeaveUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.LEAVES,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });

  const [executeQuery, { loading, data, refetch }] = useLazyQuery(
    LEAVE_LISTING,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          total: res?.internOnLeaves?.count,
        };
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );
  useEffect(() => {
    executeQuery({
      variables: {
        filters: {
          ...filterProp,
          limit: paginationProp?.pageSize,
        },
      },
    });
  }, []);

  const handleDesignationSearch = debounce((value) => {
    setDesignationSearch(value);
  }, 500);

  const handleSearch = debounce((value) => {
    const { pageSize } = paginationProp;
    const newInitialFilter = {
      ...filterProp,
      skip: 0,
      searchStrings:
        value?.length > 0 ? value?.toString()?.trim()?.split(',') : [],
    };
    setPaginationProp({ ...paginationProp, current: 1 });
    setFilterProp(newInitialFilter);
    setDesignationSearch('');
    executeQuery({
      variables: {
        filters: {
          ...newInitialFilter,
          skip: 0,
          limit: pageSize,
          searchStrings:
            value?.length > 0 ? value?.toString()?.trim()?.split(',') : [],
        },
      },
    });
  }, 500);

  const onChange = (pagination) => {
    const { current, pageSize } = pagination;
    const filter = {
      skip: current ? (current - 1) * pageSize : 0,
    };
    setFilterProp({ ...filterProp, ...filter });
    setPaginationProp({ ...paginationProp, current, pageSize });
    executeQuery({
      variables: {
        filters: {
          ...filterProp,
          skip: filter?.skip,
          limit: pageSize,
        },
      },
    });
  };

  const handleMonth = (value) => {
    setPaginationProp({ ...paginationProp, current: 1 });
    setFilterProp({
      ...filterProp,
      skip: 0,
      month: value
        ? dayjs(value)?.format(BACKEND_MONTH_FORMAT)
        : dayjs()?.format(BACKEND_MONTH_FORMAT),
    });
    executeQuery({
      variables: {
        filters: {
          ...filterProp,
          skip: 0,
          month: value
            ? dayjs(value)?.format(BACKEND_MONTH_FORMAT)
            : dayjs()?.format(BACKEND_MONTH_FORMAT),
          limit: paginationProp?.pageSize,
        },
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      render: (value, record) => (
        <div>
          {record?.user?.displayName}
          <p className="name-column">
            [{`${record?.user?.department?.name}>${record?.user?.jobTitle}`}]
          </p>
        </div>
      ),
    },
    {
      title: 'Leave Date',
      width: 200,
      render: (value, record) => {
        const isSameDay = dayjs(record?.startDate)?.isSame(
          record?.endDate,
          'day',
        );
        const startDateLeaveFormat = dayjs(record?.startDate)?.format(
          LEAVE_FORMAT,
        );
        const endDateLeaveFormat = dayjs(record?.endDate)?.format(LEAVE_FORMAT);
        const weekFormat = dayjs(record?.startDate)?.format(WEEK_FORMAT);
        const firstHalfLeave = LEAVE_HALF_TYPE?.[0]?.value;
        const secondHalfLeave = LEAVE_HALF_TYPE?.[1]?.value;
        const firstHalfLabel = LEAVE_HALF_TYPE?.[0]?.label;
        const secondHalfLabel = LEAVE_HALF_TYPE?.[1]?.label;
        const durationText =
          record?.duration <= 1
            ? `${record?.duration} Day`
            : `${record?.duration} Days`;
        let output = '';
        if (isSameDay) {
          output = startDateLeaveFormat;
        } else {
          output = `${weekFormat} - ${endDateLeaveFormat}`;
        }
        let leaveTypeLabel = '';
        if (isSameDay && record?.fromLeaveType === record?.toLeaveType) {
          leaveTypeLabel = ` (${
            record?.fromLeaveType === firstHalfLeave
              ? firstHalfLabel
              : secondHalfLabel
          })`;
        } else if (
          !isSameDay &&
          record?.fromLeaveType === firstHalfLeave &&
          record?.toLeaveType === firstHalfLeave
        ) {
          leaveTypeLabel = ` (${dayjs(record?.endDate)?.format(
            LEAVE_LABEL_FORMAT,
          )} ${secondHalfLabel})`;
        } else if (
          !isSameDay &&
          record?.fromLeaveType === secondHalfLeave &&
          record?.toLeaveType === secondHalfLeave
        ) {
          leaveTypeLabel = ` (${dayjs(record?.startDate)?.format(
            LEAVE_LABEL_FORMAT,
          )} ${firstHalfLabel})`;
        } else if (
          !isSameDay &&
          record?.fromLeaveType === secondHalfLeave &&
          record?.toLeaveType === firstHalfLeave
        ) {
          leaveTypeLabel = ` (${dayjs(record?.startDate)?.format(
            LEAVE_LABEL_FORMAT,
          )} ${secondHalfLabel} And ${dayjs(record?.endDate)?.format(
            LEAVE_LABEL_FORMAT,
          )} ${firstHalfLabel})`;
        }
        return (
          <div>
            {output}
            <p>
              <span className="name-column">{durationText}</span>
              <span className="name-column">{leaveTypeLabel}</span>
            </p>
          </div>
        );
      },
    },
    {
      title: 'Leave Type',
      render: (value, record) => (
        <div>
          {capitalize(record?.type)}
          <p className="name-column">
            {`Requested on ${dayjs(record?.refData?.requestedOn)?.format(
              LEAVE_FORMAT,
            )}`}
          </p>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value, record) => (
        <div>
          {capitalize(value)}
          {record?.leaveStatusHistory
            ?.filter((item) => item?.status === record?.status)
            ?.map((item) => (
              <p key={item} className="name-column">
                {`by ${
                  item?.actionByUser?.displayName ||
                  item?.actionByUser?.firstName
                }`}
              </p>
            ))}
        </div>
      ),
    },
    {
      title: 'Requested By',
      render: (value, record) => (
        <div>
          {capitalize(record?.creator?.firstName || record?.user?.displayName)}
        </div>
      ),
    },
    {
      title: 'Action Taken On',
      dataIndex: 'createdAt',
      render: (value) => <div>{dayjs(value)?.format(LEAVE_FORMAT)}</div>,
    },
    {
      title: 'Leave Note',
      dataIndex: 'reason',
      key: 'reason',
      width: 220,
    },
    {
      title: 'Reject/Cancellation Reason',
      width: 220,
      render: (value, record) => (
        <div>
          {record?.leaveStatusHistory
            ?.filter(
              (item) =>
                item?.status === LEAVE_STATUS?.[2]?.value ||
                item?.status === LEAVE_STATUS?.[3]?.value,
            )
            ?.map((item) => item?.reason)}
        </div>
      ),
    },
    ...(isLeaveUpdatePermission
      ? [
          {
            title: 'Action',
            render: (_, record) => (
              <Space size="middle">
                <Tooltip placement="bottom" title={<span>Edit Leave</span>}>
                  <Button
                    type="text"
                    onClick={() => {
                      setOpenLeaveDrawer(true);
                      setLeaveRecord(record);
                    }}
                    disabled={record?.status !== LEAVE_STATUS?.[0]?.value}
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
                <Tooltip placement="bottom" title={<span>Cancel Leave</span>}>
                  <Button
                    danger
                    disabled={[
                      LEAVE_STATUS?.[2]?.value,
                      LEAVE_STATUS?.[3]?.value,
                    ]?.includes(record?.status)}
                    type="text"
                    onClick={() => {
                      setModalVisible(true);
                      setLeaveRecord(record);
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </Tooltip>
              </Space>
            ),
          },
        ]
      : []),
  ];

  const renderMaxTag = (omittedValues) => {
    if (omittedValues?.length === 0) {
      return null;
    }
    return <span>+ {omittedValues?.length} more...</span>;
  };

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Leaves"
        extra={
          <div className="project-filter">
            {isLeaveCreatePermission && (
              <Button
                key="1"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setOpenLeaveDrawer(true);
                  setLeaveRecord(null);
                }}
              >
                Request Leave
              </Button>
            )}
            <DatePicker
              defaultValue={dayjs()}
              format="YYYY-MM"
              className="statistic-month-picker"
              picker="month"
              onChange={handleMonth}
            />
            <span className="user-select-tags">
              <CommonSelect
                mode="tags"
                placeholder="Search Name, Department, Designation"
                showSearch
                query={DESIGNATIONS}
                onChange={handleSearch}
                onSearch={handleDesignationSearch}
                variables={{ searchString: designationSearch }}
                useEffectDeps={[designationSearch]}
                responsePath="designations.designations"
                valuePath="jobTitle"
                labelPath="jobTitle"
                fetchPolicy="network-only"
                onClear={() =>
                  setFilterProp({
                    ...filterProp,
                    searchStrings: [],
                  })
                }
                value={filterProp?.searchStrings}
                maxTagCount={3}
                maxTagPlaceholder={renderMaxTag}
                virtual={false}
              />
            </span>
          </div>
        }
      >
        <div className="card-body-wrapper">
          <CommonTable
            size="small"
            className="intern-leave-table"
            bordered
            columns={columns}
            loadingData={loading}
            dataSource={data?.internOnLeaves?.data}
            rowKey={(obj) => obj?.id}
            onChange={onChange}
            paginationConfig={paginationProp}
          />
        </div>
        {openLeaveDrawer && (
          <LeaveDrawer
            openLeaveDrawer={openLeaveDrawer}
            setOpenLeaveDrawer={setOpenLeaveDrawer}
            leaveRecord={leaveRecord}
            refetch={refetch}
          />
        )}
        {modalVisible && (
          <LeaveModal
            visible={modalVisible}
            setModalVisible={setModalVisible}
            leaveRecord={leaveRecord}
            refetch={refetch}
          />
        )}
      </Card>
    </div>
  );
}

export default Leave;
