import { gql } from '@apollo/client';

export const PROJECTS = gql`
  query projects($filters: ProjectsFilterInput) {
    projects(filters: $filters) {
      count
      projects {
        id
        name
        clientName
        integrations {
          referenceId
          referenceName
        }
        tags
        projectExcel {
          title
          excelSheetUrl
        }
        createdOn
        creator {
          firstName
          lastName
        }
        status
        endDate
        description
      }
    }
  }
`;

export const PROJECT = gql`
  query project($id: ID!) {
    project(id: $id) {
      id
      name
      clientName
      integrations {
        referenceId
        referenceName
      }
      projectExcel {
        title
        excelSheetUrl
      }
      tags
      createdOn
      creator {
        firstName
        lastName
      }
      status
      endDate
      description
    }
  }
`;

export const USERS_WITH_RESOURCE_ALLOCATION_TAG = gql`
  query usersWithResourceAllocationTagAdmin(
    $filters: usersWithResourceAllocationTagFilter
  ) {
    usersWithResourceAllocationTagAdmin(filters: $filters) {
      count
      data {
        displayName
        id
      }
    }
  }
`;

export const PROJECT_MEMBERS = gql`
  query projectMembers(
    $where: ProjectUniqueInput!
    $filters: ProjectMembersFilter
  ) {
    projectMembers(where: $where, filters: $filters) {
      count
      data {
        billable
        type
        id
        user {
          id
          displayName
          department {
            id
            name
          }
        }
        hours
      }
    }
  }
`;

export const CHECK_AUTH_FOR_CHAT = gql`
  query checkAuthForChat($input: UniqueIdInput) {
    checkAuthForChat(input: $input) {
      isAuthenticatedForChat
    }
  }
`;
