import { gql } from '@apollo/client';

export const GET_TRACKED_HOUR_LOGS = gql`
  query GetTrackedHourLogsSummary($input: GetTrackedHourLogsSummaryInput!) {
    getTrackedHourLogsSummary(input: $input) {
      count
      data {
        id
        list {
          id
          title
        }
        space {
          id
          title
        }
        folder {
          id
          title
        }
        billable
        taskBillable
        timeTracked
        date
        taskUrl
        taskName
        taskId
      }
    }
  }
`;

export const PROJECTS = gql`
  query projectsHoursLogHistory($filters: ProjectsFilterInput) {
    projectsHoursLogHistory(filters: $filters) {
      count
      projects {
        id
        name
        clientName
        integrations {
          referenceId
          referenceName
        }
        tags
        createdOn
        creator {
          firstName
          lastName
        }
        status
        endDate
        description
      }
    }
  }
`;
