import { gql } from '@apollo/client';

export const CREATE_ASSET = gql`
  mutation CreateAsset($input: CreateAssetInput!) {
    createAsset(input: $input) {
      message
    }
  }
`;

export const UPDATE_ASSET = gql`
  mutation UpdateAsset($id: ID!, $input: UpdateAssetInput) {
    updateAsset(id: $id, input: $input) {
      message
    }
  }
`;

export const DELETE_ASSET = gql`
  mutation DeleteAsset($id: ID!) {
    deleteAsset(id: $id) {
      message
    }
  }
`;

export const NOTIFY_USER = gql`
  mutation NotifyAssetBookedUser($id: ID!) {
    notifyAssetBookedUser(id: $id) {
      message
    }
  }
`;
