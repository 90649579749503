import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  DEFAULT_DATE_FORMAT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES,
} from '../../../common/constants';
import { getLeaveTypeLabel } from '../../../common/utils';
import VerifyPermissions from '../../../components/VerifyPermissions';

const NameCellRenderer = ({
  data,
  pathName,
  weekPickerValue,
  startDate,
  endDate,
}) => {
  const isReadPermission = VerifyPermissions({
    modulekey: MODULES_KEY?.USERS,
    allowedPermissions: [PERMISSION_OBJECT?.read],
  });
  return isReadPermission ? (
    <Link
      to={{
        pathname: `${ROUTES?.SETTINGS}${ROUTES?.USERS}/${data?.user?.id}`,
        state: {
          initialDateFilter: {
            pathname: pathName,
            weekPickerValue,
            startDate,
            endDate,
          },
        },
      }}
    >
      <Tooltip
        title={data?.leaves?.map((item, index, array) => {
          const fromDate = item?.start_date;
          const toDate = item?.end_date;
          const isLastItem = index === array?.length - 1;
          const leaveTypeLabel = getLeaveTypeLabel(item);
          return (
            <div
              key={item?.id}
              className={`${isLastItem ? '' : 'record-item'}`}
            >
              {dayjs(fromDate).isSame(dayjs(toDate)) ? (
                <p className="m-0 p-0">
                  Date: {dayjs(toDate)?.format(DEFAULT_DATE_FORMAT)}
                  {leaveTypeLabel?.[0]}
                </p>
              ) : (
                <>
                  <p className="m-0 p-0">
                    From: {dayjs(fromDate)?.format(DEFAULT_DATE_FORMAT)}
                    {leaveTypeLabel?.[0]}
                  </p>
                  <p className="m-0 p-0">
                    To: {dayjs(toDate)?.format(DEFAULT_DATE_FORMAT)}
                    {leaveTypeLabel?.[1]}
                  </p>
                </>
              )}
            </div>
          );
        })}
        placement="top"
        className="name-column-displayName"
      >
        {data?.user?.displayName}
        <p className="name-column">
          [{`${data?.user?.departmentName}>${data?.user?.jobTitle}`}]
        </p>
      </Tooltip>
    </Link>
  ) : (
    <Tooltip
      title={data?.leaves?.map((item, index, array) => {
        const fromDate = item?.start_date;
        const toDate = item?.end_date;
        const isLastItem = index === array?.length - 1;
        const leaveTypeLabel = getLeaveTypeLabel(item);
        return (
          <div key={item?.id} className={`${isLastItem ? '' : 'record-item'}`}>
            {dayjs(fromDate).isSame(dayjs(toDate)) ? (
              <p className="m-0 p-0">
                Date: {dayjs(toDate)?.format(DEFAULT_DATE_FORMAT)}
                {leaveTypeLabel?.[0]}
              </p>
            ) : (
              <>
                <p className="m-0 p-0">
                  From: {dayjs(fromDate)?.format(DEFAULT_DATE_FORMAT)}
                  {leaveTypeLabel?.[0]}
                </p>
                <p className="m-0 p-0">
                  To: {dayjs(toDate)?.format(DEFAULT_DATE_FORMAT)}
                  {leaveTypeLabel?.[1]}
                </p>
              </>
            )}
          </div>
        );
      })}
      placement="top"
      className="name-column-displayName"
    >
      {data?.user?.displayName}
      <p className="name-column">
        [{`${data?.user?.departmentName}>${data?.user?.jobTitle}`}]
      </p>
    </Tooltip>
  );
};

export default NameCellRenderer;
