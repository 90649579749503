import { Tooltip } from 'antd';
import React from 'react';
import {
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES,
} from '../../../common/constants';
import VerifyPermissions from '../../../components/VerifyPermissions';
import history from '../../../historyData';

const HeaderComponent = ({
  headerName,
  field,
  pathName,
  weekPickerValue,
  startDate,
  endDate,
}) => {
  const isReadPermission = VerifyPermissions({
    modulekey: MODULES_KEY?.PROJECTS,
    allowedPermissions: [PERMISSION_OBJECT?.read],
  });

  return (
    <Tooltip title={headerName} placement="top">
      <div
        onClick={() => {
          if (isReadPermission) {
            history?.push({
              pathname: `${ROUTES?.SETTINGS}${ROUTES?.PROJECTS}/${field}`,
              state: {
                initialDateFilter: {
                  pathname: pathName,
                  weekPickerValue,
                  startDate,
                  endDate,
                },
              },
            });
          }
        }}
        className="pointer"
      >
        {headerName}
      </div>
    </Tooltip>
  );
};

export default HeaderComponent;
