import { gql } from '@apollo/client';

export const RESOURCE_ALLOCATION = gql`
  query ResourceAllocations($filters: ResourceAllocationFilterInput!) {
    resourceAllocations(filters: $filters) {
      hoursAllocation {
        hours
        id
        projectId
        projectName
      }
      previousWeekAllocatedHours {
        id
        projectId
        projectName
        previousWeeklyHours
      }
      id
      leaves
      plannedHours
      totalHours
      user {
        displayName
        jobTitle
        departmentName
        id
      }
      weekNumber
    }
  }
`;

export const ACTIVE_PROJECTS = gql`
  query activeProjects($filters: ProjectListing) {
    activeProjects(filters: $filters) {
      id
      name
      tags
    }
  }
`;

export const PROJECTS = gql`
  query projectsResourceAllocation($filters: ProjectsFilterInput) {
    projectsResourceAllocation(filters: $filters) {
      count
      projects {
        id
        name
        clientName
        integrations {
          referenceId
          referenceName
        }
        tags
        createdOn
        creator {
          firstName
          lastName
        }
        status
        endDate
        description
      }
    }
  }
`;
