import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import Error404 from '../../Error404';
import {
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import Projects from './Projects';
import ViewProject from './ViewProject';
import './project.less';

function ProjectWrapper() {
  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES_MODULES_KEY?.PROJECTS}`}
        render={(props) => (
          <AccessControl
            modulekey={MODULES_KEY?.PROJECTS}
            allowedPermissions={[PERMISSION_OBJECT?.read]}
            showNoAccess
          >
            <Projects {...props} />
          </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.SETTINGS}${ROUTES?.VIEW_PROJECT}`}
        render={(props) => (
          <AccessControl
            modulekey={MODULES_KEY?.PROJECTS}
            allowedPermissions={[PERMISSION_OBJECT?.read]}
            showNoAccess
          >
            <ViewProject {...props} />
          </AccessControl>
        )}
      />
      <Route path="*" component={Error404} />
    </Switch>
  );
}

export default ProjectWrapper;
