import {
  ArrowLeftOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import { debounce, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../AppContext';
import {
  COUNTRY_OF_ORIGIN,
  DEVICE_OS,
  DEVICE_TYPE,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import LoaderComponent from '../../components/LoaderComponent';

import { CREATE_ASSET, UPDATE_ASSET } from './graphql/Mutations';
import { ASSET, USERS } from './graphql/Queries';

const { required, email } = formValidatorRules;

const ListingForm = (props) => {
  const {
    location: { assetListingFilter, assetPaginationFilter },
  } = props;
  const { dispatch } = useContext(AppContext);
  const { assetById } = useParams();
  const history = useHistory();
  const [form] = Form?.useForm();
  const checkPreAssigned = Form?.useWatch('preAssigned', form);
  const checkOsType = Form?.useWatch('os', form);
  const [searchStrings, setSearchStrings] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [validationTriggered, setValidationTriggered] = useState(false);

  const [executeQuery, { loading, data }] = useLazyQuery(ASSET, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const [updateAsset] = useMutation(UPDATE_ASSET, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      history?.push({
        pathname: `${ROUTES_MODULES_KEY?.ASSETS_LISTING}`,
      });
    },
    onError: () => {},
  });

  const [createAsset] = useMutation(CREATE_ASSET, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      history?.push({
        pathname: `${ROUTES_MODULES_KEY?.ASSETS_LISTING}`,
      });
    },
    onError: () => {},
  });

  useEffect(() => {
    if (assetById) {
      executeQuery({
        variables: {
          id: assetById,
        },
      });
    }
  }, [assetById]);

  useEffect(() => {
    if (assetById) {
      form?.setFieldsValue({
        assetId: data?.asset?.assetId,
        isActive: data?.asset?.isActive,
        assetName: data?.asset?.assetName,
        assetType: data?.asset?.assetType,
        os: data?.asset?.os,
        osVersion: data?.asset?.osVersion,
        manufacturerSoftware: data?.asset?.manufacturerSoftware,
        manufacturerSoftwareVersion: data?.asset?.manufacturerSoftwareVersion,
        deviceCompany: data?.asset?.deviceCompany,
        udid: data?.asset?.udid,
        countryOfOrigin: data?.asset?.countryOfOrigin,
        modelNumber: data?.asset?.modelNumber,
        preAssigned: data?.asset?.preAssigned,
        rooted: data?.asset?.rooted,
        preAssignedTo: data?.asset?.preAssigned
          ? data?.asset?.user?.displayName
          : undefined,
      });
      const initialAppleIds = data?.asset?.appleId || [];
      const initialGoogleIds = data?.asset?.googleAccount || [];
      form?.setFields([
        {
          name: ['appleIds'],
          value: initialAppleIds?.map((appleId, index) => ({
            appleIds: appleId,
            key: index,
          })),
        },
        {
          name: ['googleIds'],
          value: initialGoogleIds?.map((googleId, index) => ({
            googleIds: googleId,
            key: index,
          })),
        },
      ]);
    }
    if (!assetById) {
      form?.setFieldsValue({
        assetId: uuidv4()?.slice(0, 8)?.toUpperCase(),
        isActive: true,
        preAssigned: true,
        rooted: true,
        countryOfOrigin: COUNTRY_OF_ORIGIN?.[0]?.value,
        appleIds: [''],
        googleIds: [''],
      });
    }
  }, [assetById, data]);

  const handleSearch = debounce((value) => {
    setSearchStrings(value?.toLowerCase());
  }, 500);

  const handleUserSelect = () => setSearchStrings([]);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleSwitchChange = (value) => {
    if (!value) {
      Modal?.confirm({
        icon: <ExclamationCircleOutlined />,
        title: 'Confirm De-activation Of Device',
        content:
          'Are you sure you want to mark this device as inactive? If you confirm this, none of the employees would be shown this device to use for testing purpose.',
        centered: true,
        className: 'custom-modal-button',
        onCancel: () => {
          form?.setFieldsValue({ isActive: true });
        },
      });
    } else {
      form?.setFieldsValue({ isActive: value });
    }
  };

  const handleBack = () => {
    history?.push({
      pathname: `${ROUTES_MODULES_KEY?.ASSETS_LISTING}`,
      assetListingFilter,
      assetPaginationFilter,
    });
  };

  const onFinish = async (values) => {
    const googleIds = map(values?.googleIds, 'googleIds').filter(
      (res) => !!res,
    );
    const appleIds = map(values?.appleIds, 'appleIds').filter((res) => !!res);

    const assetInput = {
      ...values,
      assetId: undefined,
      udid: values?.udid || '',
      manufacturerSoftware:
        values?.os !== DEVICE_OS?.[1]?.value
          ? ''
          : values?.manufacturerSoftware,
      manufacturerSoftwareVersion:
        values?.os !== DEVICE_OS?.[1]?.value
          ? ''
          : values?.manufacturerSoftwareVersion,
      // eslint-disable-next-line no-nested-ternary
      preAssignedTo: !values?.preAssigned
        ? null
        : values?.preAssignedTo === data?.asset?.user?.displayName
        ? data?.asset?.user?.id
        : values?.preAssignedTo,
      googleIds,
      appleIds,
    };
    try {
      if (assetById) {
        await updateAsset({
          variables: {
            id: assetById,
            input: assetInput,
          },
        });
      } else {
        await createAsset({
          variables: {
            input: { ...assetInput, assetId: values?.assetId },
          },
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('Error:', error);
    }
  };

  return (
    <Card
      title={
        <div className="d-flex align-center">
          <Button
            type="text"
            shape="square"
            className="asset-back-btn"
            onClick={handleBack}
            icon={<ArrowLeftOutlined />}
          />
          {assetById ? 'Edit Assets' : 'Add Assets'}
        </div>
      }
    >
      {loading ? (
        <LoaderComponent />
      ) : (
        <Form
          name="Listing-form"
          layout="vertical"
          className="Listing-form"
          form={form}
          onFinish={(values) => {
            dispatch({ type: 'SET_SHOW_PROMPT', data: false });
            onFinish(values);
          }}
          onFinishFailed={onFinishFailed}
          onFieldsChange={() => {
            setBtnDisable(false);
            dispatch({
              type: 'SET_SHOW_PROMPT',
              data: true,
            });
          }}
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        >
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={12}>
              <Form.Item
                name="assetId"
                label="Asset ID"
                rules={[{ required, message: 'Please Enter Asset ID!' }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="isActive"
                label="Asset Status"
                valuePropName="checked"
              >
                <Switch
                  onChange={handleSwitchChange}
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="assetName"
                label="Device Name"
                rules={[
                  {
                    required,
                    message: 'Please Enter Device Name!',
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="assetType"
                label="Choose Device Type"
                rules={[{ required, message: 'Please Choose Device Type!' }]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select Device"
                  filterOption={(inputValue, option) =>
                    option?.value
                      ?.toLowerCase()
                      ?.includes(inputValue?.toLowerCase())
                  }
                  options={DEVICE_TYPE}
                  virtual={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="os"
                label="Choose Operating System"
                rules={[
                  { required, message: 'Please Choose Operating System!' },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select Operating System"
                  filterOption={(inputValue, option) =>
                    option?.value
                      ?.toLowerCase()
                      ?.includes(inputValue?.toLowerCase())
                  }
                  options={DEVICE_OS}
                  virtual={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="osVersion"
                label="OS Version"
                rules={[
                  {
                    required,
                    message: 'Please Enter OS Version!',
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {checkOsType === DEVICE_OS?.[1]?.value && (
              <>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="manufacturerSoftware"
                    label="Manufacturer Software"
                    rules={[
                      {
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    name="manufacturerSoftwareVersion"
                    label="Manufacturer Software Version"
                    rules={[
                      {
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </>
            )}
            <Divider />
            <Col xs={24} lg={12}>
              <Form.Item
                name="deviceCompany"
                label="Device Company"
                rules={[
                  {
                    required,
                    message: 'Please Enter Device Company!',
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="udid"
                label="UDID"
                rules={[
                  {
                    required: checkOsType === 'IOS',
                    message: 'Please Enter UDID!',
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="countryOfOrigin"
                label="Choose Country of Origin"
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select Country"
                  filterOption={(inputValue, option) =>
                    option?.value
                      ?.toLowerCase()
                      ?.includes(inputValue?.toLowerCase())
                  }
                  options={COUNTRY_OF_ORIGIN}
                  virtual={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="modelNumber"
                label="Model Number"
                rules={[
                  {
                    required,
                    message: 'Please Enter Model Number!',
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Divider />
            <Col xs={24} lg={12}>
              {[
                {
                  name: 'preAssigned',
                  label: 'Is it Pre-Assigned?',
                },
                {
                  name: 'rooted',
                  label: 'Is it Rooted/Jail-Break?',
                },
              ].map((field) => (
                <Form.Item
                  key={field?.name}
                  name={field?.name}
                  label={field?.label}
                  className="inline-form-item"
                >
                  <Radio.Group>
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              ))}
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="preAssignedTo"
                label="Please mention name of the employee to whom it is pre-assigned"
                rules={[
                  {
                    required: checkPreAssigned,
                    message: 'Please Choose Employee Name!',
                  },
                ]}
                dependencies={['preAssigned']}
              >
                <CommonSelect
                  placeholder="Select User"
                  showSearch
                  query={USERS}
                  onSearch={handleSearch}
                  onChange={handleUserSelect}
                  responsePath="usersAdminAssetsListing.users"
                  valuePath="id"
                  labelPath="displayName"
                  fetchPolicy="network-only"
                  value={searchStrings}
                  variables={{ filters: { searchStrings } }}
                  useEffectDeps={[searchStrings]}
                  virtual={false}
                  disabled={!checkPreAssigned}
                />
              </Form.Item>
            </Col>
            <Divider />
            <Col xs={24} lg={12}>
              <Descriptions title="Apple ID">
                <Descriptions.Item>
                  <Space direction="vertical">
                    <Form.List name="appleIds">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} align="baseline">
                              <Form.Item
                                {...restField}
                                name={[name, 'appleIds']}
                                rules={[email]}
                              >
                                <Input placeholder="Enter Apple Id" />
                              </Form.Item>
                              <DeleteOutlined
                                className="listing-form-icon"
                                onClick={() => remove(name)}
                              />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                            >
                              Add New
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Space>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col xs={24} lg={12}>
              <Descriptions title="Google Account">
                <Descriptions.Item>
                  <Space direction="vertical">
                    <Form.List name="googleIds">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space key={key} align="baseline">
                              <Form.Item
                                {...restField}
                                name={[name, 'googleIds']}
                                rules={[email]}
                              >
                                <Input placeholder="Enter Gmail Account" />
                              </Form.Item>
                              <DeleteOutlined
                                className="listing-form-icon"
                                onClick={() => remove(name)}
                              />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                            >
                              Add New
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Space>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Divider />
            <Col xs={24} lg={24}>
              <Form.Item className="mb-0">
                <div className="Listing-form-button">
                  <Button htmlType="button" onClick={handleBack}>
                    Cancel
                  </Button>
                  <Button
                    className="save-btn"
                    type="primary"
                    htmlType="submit"
                    disabled={btnDisable}
                  >
                    {assetById ? 'Update Asset' : 'Create Asset'}
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default ListingForm;
