import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
} from 'antd';
import dayjs from 'dayjs';
import { capitalize, debounce, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  CURRENT_STATUS,
  DATE_TIME_FORMAT,
  DEFAULT_DATE_FORMAT,
  INCIDENT_STATUS,
  MODULES_KEY,
  PERMISSION_OBJECT,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CommonSelect from '../../../components/CommonSelect';
import VerifyPermissions from '../../../components/VerifyPermissions';
import { INCIDENT_MASTERS } from '../../incidentMaster/graphql/Queries';

const DescriptionIncident = ({ incident }) => (
  <Descriptions column={1} className="incident-descriptions">
    {[
      {
        key: 'reportedBy',
        label: 'Reported By',
        value:
          incident?.raisedByUser?.displayName ||
          incident?.raisedByUser?.firstName,
      },
      {
        key: 'reportingOn',
        label: 'Reporting On',
        value:
          incident?.reportingForUser?.displayName ||
          incident?.reportingForUser?.firstName,
      },
      {
        key: 'taskProject',
        label: 'Task/Project',
        value: capitalize(incident?.projectName),
      },
      {
        key: 'incidentDescription',
        label: 'Incident Description',
        value: incident?.description || 'No description available',
      },
      {
        key: 'severity',
        label: 'Severity',
        value: capitalize(incident?.severity),
      },
      {
        key: 'reportedDate',
        label: 'Reported Date',
        value: dayjs(incident?.createdAt).format(DATE_TIME_FORMAT),
      },
      {
        key: 'incidentDate',
        label: 'Incident Date',
        value: dayjs(incident?.date).format(DEFAULT_DATE_FORMAT),
      },
      {
        key: 'lastUpdated',
        label: 'Last Updated',
        value: dayjs(incident?.updatedAt).format(DATE_TIME_FORMAT),
      },
      {
        key: 'incidentStatus',
        label: 'Incident Status',
        value: capitalize(incident?.status),
      },
      {
        key: 'conclusion',
        label: 'Conclusion',
        value: capitalize(incident?.conclusion),
      },
      {
        key: 'isDiscussionRequired',
        label: 'Is Discussions Required',
        value: incident?.isDiscussionRequired ? 'Yes' : 'No',
      },
      {
        key: 'scheduleOn',
        label: 'Schedule On',
        value:
          (incident?.isDiscussionRequired &&
            incident?.scheduledOn &&
            dayjs(incident?.scheduledOn).format(DATE_TIME_FORMAT)) ||
          'N/A',
      },
      {
        key: 'category',
        label: 'Category',
        value: capitalize(incident?.incidentMaster?.name) || 'N/A',
      },
      {
        key: 'mom',
        label: 'MOM',
        value: incident?.mom || 'N/A',
      },
    ].map((item) => (
      <Descriptions.Item
        key={item?.key}
        label={<span className="incident-label">{item?.label}</span>}
      >
        {item?.value}
      </Descriptions.Item>
    ))}
  </Descriptions>
);

const { required } = formValidatorRules;
const { Option } = Select;

const IncidentModal = ({ visible, onCancel, onSubmit, incident, mode }) => {
  const [form] = Form?.useForm();
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [userSearch, setUserSearch] = useState();
  const [flag, setFlag] = useState(false);

  const isUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.INCIDENT_REPORT,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });

  useEffect(() => {
    if (mode && incident) {
      setSelectedStatus(incident?.status);
      setFlag(incident?.isDiscussionRequired);

      form.setFieldsValue({
        ...incident,
        incidentMasterId: incident?.incidentMaster?.id,
        scheduledOn: incident?.scheduledOn
          ? dayjs(incident?.scheduledOn)
          : null,
      });
      setIsValueChanged(false);
    }
  }, [visible, incident, mode, form]);

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const validateDescription = (status) => {
    if (
      [INCIDENT_STATUS.RESOLVED, INCIDENT_STATUS.REJECTED]?.includes(status)
    ) {
      return {
        required: true,
        message: 'Description must be at least 100 characters',
      };
    }
    return {};
  };

  const handleUserSearch = debounce((value) => {
    setUserSearch(value);
  }, 500);

  const footerContent = mode
    ? [
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <>
          {isUpdatePermission && (
            <Button
              key="submit"
              type="primary"
              onClick={() => form?.submit()}
              disabled={!isValueChanged}
            >
              Save
            </Button>
          )}
        </>,
      ]
    : [
        <Button key="cancel" onClick={onCancel}>
          Ok
        </Button>,
      ];

  return (
    <Modal
      title={
        <span className="modal-title">
          {mode ? 'Action Taken' : 'View Incident Details'}
        </span>
      }
      open={visible}
      onCancel={onCancel}
      onOk={() => mode && form?.submit()}
      footer={footerContent}
      destroyOnClose
      width={isUpdatePermission ? '100%' : 550}
      className="incident-report-modal"
    >
      {isUpdatePermission ? (
        <Row gutter={[32, 32]}>
          <Col span={12} className="description-col">
            <DescriptionIncident incident={incident} />
          </Col>
          <Col span={12}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onSubmit}
              onFieldsChange={() => setIsValueChanged(true)}
            >
              <Form.Item
                name="status"
                label={
                  <span className="form-label-highlight">Incident Status</span>
                }
              >
                <Select onChange={handleStatusChange}>
                  {map(INCIDENT_STATUS, (status) => (
                    <Option key={status} value={status} label={status}>
                      {capitalize(status)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Row gutter={[32, 32]}>
                <Col span={12}>
                  <Form.Item
                    name="isDiscussionRequired"
                    label={
                      <span className="form-label-highlight">
                        Is Discussion Required
                      </span>
                    }
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={(val) => setFlag(val)}
                    />
                  </Form.Item>
                </Col>
                {flag && (
                  <Col span={12}>
                    <Form.Item
                      name="scheduledOn"
                      label={
                        <span className="form-label-highlight">
                          Scheduled on
                        </span>
                      }
                      rules={[
                        { required, message: 'Please Select Date And Time' },
                      ]}
                    >
                      <DatePicker showTime />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Form.Item
                name="incidentMasterId"
                label={<span className="form-label-highlight">Category</span>}
              >
                <CommonSelect
                  placeholder="Select Master"
                  showSearch
                  query={INCIDENT_MASTERS}
                  onSearch={handleUserSearch}
                  responsePath="incidentMasters.data"
                  valuePath="id"
                  labelPath="name"
                  fetchPolicy="network-only"
                  variables={{
                    filters: {
                      search: userSearch,
                      statusFilter: CURRENT_STATUS.ACTIVE,
                    },
                  }}
                  useEffectDeps={[userSearch]}
                  virtual={false}
                />
              </Form.Item>
              <Form.Item
                className="incident-descriptions"
                name="conclusion"
                label={<span className="form-label-highlight">Conclusion</span>}
                rules={[validateDescription(selectedStatus)]}
              >
                <Input.TextArea showCount maxLength={5000} rows={4} />
              </Form.Item>
              <Form.Item
                name="mom"
                label={<span className="form-label-highlight">MOM</span>}
              >
                <Input.TextArea showCount maxLength={10000} rows={4} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ) : (
        <DescriptionIncident incident={incident} />
      )}
    </Modal>
  );
};

export default IncidentModal;
