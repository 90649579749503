import { gql } from '@apollo/client';

export const ADMIN_UPDATE = gql`
  mutation adminUpdate($input: AdminUpdateInput) {
    adminUpdate(input: $input) {
      message
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      message
    }
  }
`;

export const ADD_USER_TO_CHAT_SPACES = gql`
  mutation addUserToChatSpaces($where: UserWhereUniqueInput!) {
    addUserToChatSpaces(where: $where) {
      message
    }
  }
`;

export const ADD_USER_TO_MORNING_CALL = gql`
  mutation AddUserToMorningCall($where: UserWhereUniqueInput!) {
    addUserToMorningCall(where: $where) {
      message
    }
  }
`;
