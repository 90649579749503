import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { GeustLoginLogo, Logo } from '../../../assets/svg';
import { ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import { GET_OTP } from './graphql/Mutations';
import './guest-login.less';

const { required, email } = formValidatorRules;

const GeustLogin = () => {
  const [form] = Form?.useForm();
  const history = useHistory();

  const [getEmailOtp] = useMutation(GET_OTP, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const onFinish = async (values) => {
    try {
      await getEmailOtp({
        variables: {
          input: {
            email: values?.email?.trim()?.toLowerCase(),
          },
        },
        onCompleted: () => {
          form?.resetFields();
          history?.push({
            pathname: `${ROUTES?.GUEST}${ROUTES?.VERIFY_OTP}`,
            state: {
              email: values?.email?.trim()?.toLowerCase(),
            },
          });
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('Error', error?.message);
    }
  };
  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <div className="text-center guest-logo mb-24">
            <Space align="center">
              <GeustLoginLogo />
              <Logo />
            </Space>
          </div>
          <div className="login-details-heading">Get started</div>
          <div className="login-details-text">
            You will receive 6 digit OTP in your inbox for verification
          </div>
          <Form
            name="Login"
            onFinish={onFinish}
            size="large"
            form={form}
            layout="vertical"
          >
            <Form.Item
              name="email"
              rules={[{ required, message: 'Please Enter Email!' }, email]}
            >
              <Input
                className="w-100"
                prefix={<UserOutlined />}
                placeholder="Enter Email"
              />
            </Form.Item>
            <Button type="primary" className="full-width" htmlType="submit">
              Send OTP
            </Button>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default GeustLogin;
