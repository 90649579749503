import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  NotificationOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Modal,
  Popconfirm,
  Select,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DEVICE_LISTING,
  LIMIT,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES_MODULES_KEY,
  SORT_ORDER,
} from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import VerifyPermissions from '../../components/VerifyPermissions';
import { DELETE_ASSET, NOTIFY_USER } from './graphql/Mutations';
import { ASSETS } from './graphql/Queries';

const initialListingFilter = {
  skip: 0,
  searchString: '',
  filterAsset: DEVICE_LISTING?.[0]?.value,
  sortOrder: SORT_ORDER?.[0]?.value,
};

const initialPaginationFilter = {
  current: 1,
  pageSize: LIMIT,
  total: 0,
};

function Listing(props) {
  const {
    location: {
      assetListingFilter: stateListingFilter,
      assetPaginationFilter: statePaginationFilter,
    },
  } = props;
  const history = useHistory();
  const [assetListingFilter, setAssetListingFilter] = useState(
    stateListingFilter || initialListingFilter,
  );
  const [assetPaginationFilter, setAssetPaginationFilter] = useState(
    statePaginationFilter || initialPaginationFilter,
  );
  const [assetId, setAssetId] = useState();
  const isCreatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.ASSETS_LISTING,
    allowedPermissions: [PERMISSION_OBJECT?.create],
  });
  const isUpdatePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.ASSETS_LISTING,
    allowedPermissions: [PERMISSION_OBJECT?.update],
  });
  const isDeletePermission = VerifyPermissions({
    modulekey: MODULES_KEY?.ASSETS_LISTING,
    allowedPermissions: [PERMISSION_OBJECT?.delete],
  });

  const [executeQuery, { loading, data }] = useLazyQuery(ASSETS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...assetPaginationFilter,
        total: res?.assets?.count,
      };
      setAssetPaginationFilter(pagination);
    },
    onError: () => {},
  });

  const [notifyAssetBookedUser] = useMutation(NOTIFY_USER, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const [deleteAsset] = useMutation(DELETE_ASSET, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      executeQuery({
        variables: {
          filters: {
            ...assetListingFilter,
            limit: assetPaginationFilter?.pageSize,
          },
        },
      });
    },
    onError: () => {},
  });

  useEffect(() => {
    executeQuery({
      variables: {
        filters: {
          ...assetListingFilter,
          limit: assetPaginationFilter?.pageSize,
        },
      },
    });
  }, []);

  const handlePagination = (pagination) => {
    const { current, pageSize } = pagination;
    const filter = {
      skip: current ? (current - 1) * pageSize : 0,
    };
    setAssetListingFilter({ ...assetListingFilter, ...filter });
    setAssetPaginationFilter({
      ...assetPaginationFilter,
      current,
      pageSize,
    });
    executeQuery({
      variables: {
        filters: {
          ...assetListingFilter,
          skip: filter?.skip,
          limit: pageSize,
        },
      },
    });
  };

  const handleSearch = debounce((value) => {
    setAssetListingFilter({
      ...assetListingFilter,
      skip: 0,
      searchString: value?.trim(),
    });
    setAssetPaginationFilter({
      ...assetPaginationFilter,
      current: 1,
    });

    executeQuery({
      variables: {
        filters: {
          ...assetListingFilter,
          skip: 0,
          limit: assetPaginationFilter?.pageSize,
          searchString: value?.trim(),
        },
      },
    });
  }, 500);

  const handleNotifyUser = (deviceId) => {
    notifyAssetBookedUser({
      variables: {
        id: deviceId,
      },
    });
  };

  const handleNotification = (deviceId) => {
    Modal?.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Notify Employee',
      content:
        'A push notification will be sent to the employee who has booked this device; thereby giving a gentle reminder to submit the device back.',
      centered: true,
      className: 'custom-modal-button',
      onOk: () => {
        handleNotifyUser(deviceId);
      },
    });
  };
  const handleDeleteAsset = (record) => {
    setAssetId(record?.id);
  };

  const confirm = () => {
    deleteAsset({
      variables: {
        id: assetId,
      },
    });
  };

  const handleChange = (value) => {
    setAssetListingFilter({
      ...assetListingFilter,
      skip: 0,
      filterAsset: value?.trim(),
    });
    setAssetPaginationFilter({
      ...assetPaginationFilter,
      current: 1,
    });

    executeQuery({
      variables: {
        filters: {
          ...assetListingFilter,
          skip: 0,
          limit: assetPaginationFilter?.pageSize,
          filterAsset: value?.trim(),
        },
      },
    });
  };

  const columns = [
    {
      title: 'Sr.No',
      align: 'center',
      width: 50,
      render: (_, __, index) => (
        <>
          {(assetPaginationFilter?.current - 1) *
            assetPaginationFilter?.pageSize +
            index +
            1}
        </>
      ),
    },
    {
      title: 'Asset ID',
      dataIndex: 'assetId',
      key: 'assetId',
      align: 'center',
    },
    {
      title: 'Asset Name',
      dataIndex: 'assetName',
      key: 'assetName',
      align: 'center',
    },
    {
      title: 'Type',
      dataIndex: 'assetType',
      key: 'assetType',
      align: 'center',
    },
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
      align: 'center',
    },
    {
      title: 'Version',
      dataIndex: 'osVersion',
      key: 'osVersion',
      align: 'center',
    },
    {
      title: 'Man Soft.',
      dataIndex: 'manufacturerSoftware',
      key: 'manufacturerSoftware',
      align: 'center',
    },
    {
      title: 'Man Soft. V',
      dataIndex: 'manufacturerSoftwareVersion',
      key: 'manufacturerSoftwareVersion',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      className: 'link-custom-width',
      render: (_, record) => (
        <Tag
          className="status-tags"
          color={record?.isActive ? 'success' : 'error'}
        >
          {record?.isActive ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Availability',
      dataIndex: 'availability',
      key: 'availability',
      align: 'center',
      className: 'link-custom-width',
      render: (_, record) => (
        <>
          <Tag
            className="availability-tags"
            color={record?.availability ? 'success' : 'error'}
          >
            {record?.availability ? 'Yes' : 'No'}
          </Tag>
        </>
      ),
    },
    ...(isUpdatePermission
      ? [
          {
            title: 'Notify Employee',
            dataIndex: 'notify',
            key: 'notify',
            align: 'center',
            render: (_, record) => (
              <>
                {!record?.availability &&
                  record?.isActive &&
                  isUpdatePermission && (
                    <Button
                      key="notification"
                      className={
                        record?.preAssigned
                          ? 'pre-assigned-btn'
                          : 'notify-user-btn'
                      }
                      type="primary"
                      icon={<NotificationOutlined />}
                      onClick={() => handleNotification(record?.id)}
                    >
                      <span>{record?.assetTracker?.user.displayName}</span>
                    </Button>
                  )}
              </>
            ),
          },
        ]
      : []),
    ...(isUpdatePermission || isDeletePermission
      ? [
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (_, record) => (
              <Space size="middle">
                {isUpdatePermission && (
                  <Tooltip placement="bottom" title={<span>Edit Asset</span>}>
                    <Button
                      type="text"
                      onClick={() => {
                        history?.push({
                          pathname: `${ROUTES_MODULES_KEY?.ASSETS_LISTING}/edit/${record?.id}`,
                          assetListingFilter,
                          assetPaginationFilter,
                        });
                      }}
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                )}
                {isDeletePermission && (
                  <Popconfirm
                    title="Delete Asset"
                    description="Are you sure to delete this Asset?"
                    placement="topLeft"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip
                      placement="bottom"
                      title={<span>Delete Asset</span>}
                    >
                      <Button
                        danger
                        type="text"
                        onClick={() => {
                          handleDeleteAsset(record);
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                )}
              </Space>
            ),
          },
        ]
      : []),
  ];

  const handleBeforeUnload = () => {
    history.push({
      pathname: history.location.pathname,
      state: null,
    });
  };
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className="table-card-page">
      <Card
        className="ant-body-scroll"
        title="Mobile Assets and Management Tracker"
        extra={
          <>
            <div className="listing-filter">
              {isCreatePermission && (
                <Button
                  key="1"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    history?.push({
                      pathname: `${ROUTES_MODULES_KEY?.ASSETS_LISTING}/add`,
                      assetListingFilter,
                      assetPaginationFilter,
                    });
                  }}
                >
                  Add New Device
                </Button>
              )}
              <Select
                defaultValue={assetListingFilter?.filterAsset}
                options={DEVICE_LISTING}
                onChange={handleChange}
                showSearch
              />
              <SearchComponent
                getData={handleSearch}
                name="input text"
                defaultValue={assetListingFilter?.searchString}
              />
            </div>
          </>
        }
      >
        <div className="card-body-wrapper">
          <CommonTable
            size="small"
            className="listing-table"
            columns={columns}
            dataSource={data?.assets?.assets}
            loadingData={loading}
            rowKey={(obj) => obj?.id}
            bordered
            onChange={handlePagination}
            paginationConfig={assetPaginationFilter}
          />
        </div>
      </Card>
    </div>
  );
}

export default Listing;
