import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_MONTHLY_INSTANCES = gql`
  mutation updateMonthlyInstances($data: [UpdateMonthlyInstancesInput]) {
    updateMonthlyInstances(data: $data) {
      message
    }
  }
`;
