import { useMutation } from '@apollo/client';
import { Button, Card, Form, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { GeustLoginLogo, Logo } from '../../../assets/svg';
import { ROUTES } from '../../../common/constants';
import { GET_OTP, VERIFY_EMAIL_BY_OTP } from './graphql/Mutations';
import './guest-login.less';

const GeustOtpVerify = () => {
  const [form] = Form?.useForm();
  const history = useHistory();
  const [otp, setOtp] = useState(null);
  const { dispatch } = useContext(AppContext);
  const sharedEmailId = history?.location?.state?.email;

  const [verifyEmailByOtp] = useMutation(VERIFY_EMAIL_BY_OTP, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch({
        type: 'SET_CLIENTD_ID',
        data: data?.verifyEmailByOtp?.clientId,
      });
      form?.resetFields();
      history?.replace(ROUTES?.RESUME);
    },
    onError: () => {},
  });

  const [getEmailOtp] = useMutation(GET_OTP, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  useEffect(() => {
    if (!sharedEmailId) {
      history?.replace(`${ROUTES?.GUEST}${ROUTES?.LOGIN}`);
    }
  }, [sharedEmailId]);

  const handleOnChange = (value) => {
    setOtp(value);
  };

  const resendOtp = async () => {
    await getEmailOtp({
      variables: {
        input: {
          email: sharedEmailId,
        },
      },
    });
  };

  const onFinish = async (values) => {
    try {
      await verifyEmailByOtp({
        variables: {
          input: {
            email: sharedEmailId,
            otp: values?.otp?.trim(),
          },
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('Error', error?.message);
    }
  };
  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <div className="text-center guest-logo mb-16">
            <Space align="center">
              <GeustLoginLogo />
              <Logo />
            </Space>
          </div>
          <div className="login-details-heading text-center">
            Verify Your Email
          </div>
          <div className="login-details-text text-center">
            Enter OTP sent to {sharedEmailId}
          </div>
          <Form
            name="Login"
            onFinish={onFinish}
            size="large"
            form={form}
            layout="vertical"
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please Enter OTP',
                },
              ]}
              name="otp"
              className="phone-input-item otp-component"
            >
              <OTPInput
                value={otp}
                onChange={handleOnChange}
                numInputs={6}
                inputStyle="otp-input"
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus
                inputType="tel"
              />
            </Form.Item>
            <div className="resend-text">
              Didn’t recieve OTP ?
              <span
                className="otp-resend-link pointer"
                onClick={() => {
                  resendOtp();
                }}
              >
                {' '}
                Resend
              </span>
            </div>
            <Button type="primary" className="full-width" htmlType="submit">
              Sign In
            </Button>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default GeustOtpVerify;
