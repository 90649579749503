import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import { findIndex, includes, some } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Router, Switch } from 'react-router-dom';
import { AppContext } from './AppContext';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  MODULES_KEY,
  ROLE_KEYS,
  ROUTES,
  ROUTES_MODULES_KEY,
  SESSION_EXPIRED,
} from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import history from './historyData';
import EmployeeResume from './modules/auth/geustLogin/EmployeeResume';
import GuestLogin from './modules/auth/geustLogin/GuestLogin';
import GuestOtpVerify from './modules/auth/geustLogin/GuestOtpVerify';
import Login from './modules/auth/login/Login';
import RefreshToken from './modules/auth/login/RefreshToken';
import { GET_CURRENT_USER } from './modules/auth/login/graphql/Queries';

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const resourceAllocationRoutes = [
  ROUTES_MODULES_KEY.RESOURCE_ALLOCATION,
  ROUTES_MODULES_KEY.RESOURCE_ALLOCATION_OLD,
  ROUTES_MODULES_KEY.RESOURCE_ALLOCATION_TESTING,
];

const Routes = () => {
  const { initializeAuth, getToken, getRefreshToken } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const path = history?.location?.pathname;
  const idToken = getToken();
  const refreshToken = getRefreshToken();

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const { user } = res;
      initializeAuth(
        user,
        idToken,
        refreshToken,
        user?.permissionRole?.permissions,
      );
      if ([ROLE_KEYS.USER, ROLE_KEYS.INTERN]?.includes(user?.role)) {
        const pathIndex = findIndex(user?.permissionRole?.permissions, {
          moduleName: Object.keys(ROUTES_MODULES_KEY).find((key) =>
            path?.includes(ROUTES_MODULES_KEY[key]),
          ),
        });
        if (
          pathIndex !== -1 &&
          user?.permissionRole?.permissions[pathIndex]?.access?.read
        ) {
          history?.replace(ROUTES_MODULES_KEY[pathIndex]);
        } else {
          const hasResourceAllocationAccess = some(
            user?.permissionRole?.permissions,
            {
              moduleName: MODULES_KEY?.RESOURCE_ALLOCATION,
              access: { read: true },
            },
          );
          const index = findIndex(user?.permissionRole?.permissions, {
            access: { read: true },
          });
          if (
            hasResourceAllocationAccess &&
            includes(resourceAllocationRoutes, path)
          ) {
            history?.replace(path);
          } else if (index !== -1) {
            const moduleName =
              user?.permissionRole?.permissions[index]?.moduleName;
            history?.replace(ROUTES_MODULES_KEY[moduleName]);
          } else {
            history?.replace(ROUTES?.PROJECT_RESOURCE_SHEET);
          }
        }
      }
      setLoading(false);
    },
    onError: (error) => {
      if (error?.graphQLErrors?.[0]?.extensions?.code === SESSION_EXPIRED) {
        history?.replace({
          pathname: ROUTES?.AUTHENTICATION,
          state: { from: path },
        });
      } else {
        history?.push(ROUTES?.LOGOUT);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (path === ROUTES?.LOGOUT || idToken) {
      getCurrentUser();
    } else {
      setLoading(false);
    }

    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router history={history}>
        {maintenance === 'true' ? (
          <MaintenancePage />
        ) : (
          <Switch>
            <PublicRoute
              exact
              path={`${ROUTES?.AUTH}${ROUTES?.LOGIN}`}
              component={Login}
              redirectPath={path}
            />
            <PublicRoute
              exact
              path={`${ROUTES?.GUEST}${ROUTES?.LOGIN}`}
              component={GuestLogin}
            />
            <PublicRoute
              exact
              path={`${ROUTES?.GUEST}${ROUTES?.VERIFY_OTP}`}
              component={GuestOtpVerify}
            />
            <PublicRoute
              exact
              path={ROUTES?.RESUME}
              component={EmployeeResume}
            />
            <PrivateRoute
              exact
              path={ROUTES?.AUTHENTICATION}
              component={RefreshToken}
            />
            <PrivateRoute path={ROUTES?.MAIN} component={App} />
          </Switch>
        )}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default Routes;
