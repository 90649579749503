import { gql } from '@apollo/client';

export const CREATE_METABASE_REPORT = gql`
  mutation CreateMetabaseReport($data: CreateMetabaseReportInput!) {
    createMetabaseReport(data: $data) {
      message
    }
  }
`;

export const UPDATE_METABASE_REPORT = gql`
  mutation UpdateMetabaseReport(
    $where: MetabaseReportUniqueInput!
    $data: UpdateMetabaseReportInput!
  ) {
    updateMetabaseReport(where: $where, data: $data) {
      message
      data {
        id
        title
        url
        permissions
        createdAt
        updatedAt
      }
    }
  }
`;
