import { Input } from 'antd';
import React from 'react';

const CommonInput = (props) => {
  const { type, ...rest } = props;

  if (type === 'password') {
    return <Input.Password {...rest} />;
  }

  if (type === 'textArea') {
    return <Input.TextArea {...rest} />;
  }

  return <Input {...rest} />;
};

export default CommonInput;
