import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import Error404 from '../../Error404';
import { MODULES_KEY, PERMISSION_OBJECT, ROUTES } from '../../common/constants';
import CreateIncidentReport from './CreateIncidentReport';
import IncidentReport from './IncidentReport';
import './incidentReport.less';

const IncidentReportWrapper = () => (
  <Switch>
    <Route
      exact
      path={ROUTES?.INCIDENT_REPORT}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.INCIDENT_REPORT}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <IncidentReport {...props} />
        </AccessControl>
      )}
    />
    <Route
      exact
      path={`${ROUTES?.INCIDENT_REPORT}/create`}
      render={() => (
        <AccessControl
          modulekey={MODULES_KEY?.INCIDENT_REPORT}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <CreateIncidentReport />
        </AccessControl>
      )}
    />
    <Route path="*" component={Error404} />
  </Switch>
);

export default IncidentReportWrapper;
