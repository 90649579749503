import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_EVENT = gql`
  mutation CreateEvent($data: EventInput!) {
    createEvent(data: $data) {
      message
      data {
        id
        name
        categoryId
        typeId
        startDate
        endDate
        locationName
        streetAddress
        suiteNo
        city
        state
        zipcode
        headerImage
        notes
        description
        contacts
        noOfGuests
        maxAttendees
        isDonationFee
        donationFee
        isEntryFee
        entryFee
        registrationStartDate
        registrationCloseDate
        feedbackStartDate
        feedbackCloseDate
        availabilityDate
        isTableSponsored
        pricePerTable
        seatsPerTable
        ministries
        status
        folderId
        additionalPhotos
        constantContactFormId
        isVirtualEvent
        virtualEventUrl
        constantContactRefId
        paymentUrl
        donationUrl
        allowSpouse
        noOfWaitlist
        isVisible
        pdfUrls
        constantContactFormUrl
        constantContactTitle
        pdfDetails
        isSyncProgress
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($data: EventInput!, $updateEventId: ID!) {
    updateEvent(data: $data, id: $updateEventId) {
      message
      status
    }
  }
`;

export const DUPLICATE_EVENT = gql`
  mutation DuplicateEvent($data: EventInput!, $duplicateEventId: ID!) {
    duplicateEvent(data: $data, id: $duplicateEventId) {
      message
      data {
        id
        name
        categoryId
        typeId
        startDate
        endDate
        locationName
        streetAddress
        suiteNo
        city
        state
        zipcode
        headerImage
        notes
        description
        contacts
        noOfGuests
        maxAttendees
        isDonationFee
        donationFee
        isEntryFee
        entryFee
        registrationStartDate
        registrationCloseDate
        feedbackStartDate
        feedbackCloseDate
        availabilityDate
        isTableSponsored
        pricePerTable
        seatsPerTable
        status
        folderId
        additionalPhotos
        constantContactFormId
        isVirtualEvent
        virtualEventUrl
        constantContactRefId
        paymentUrl
        donationUrl
        allowSpouse
        noOfWaitlist
        isVisible
        pdfUrls
        constantContactFormUrl
        constantContactTitle
        pdfDetails
        isSyncProgress
      }
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation RemoveEvent($removeEventId: ID!) {
    removeEvent(id: $removeEventId) {
      message
      status
    }
  }
`;

export const SEND_NOTIFICATION = gql`
  mutation SendNotification(
    $sendNotificationId: ID!
    $data: NotificationInput!
  ) {
    sendNotification(id: $sendNotificationId, data: $data) {
      message
      status
    }
  }
`;

export const PUBLISH_EVENT = gql`
  mutation PublishEvent($publishEventId: ID!) {
    publishEvent(id: $publishEventId) {
      message
      status
    }
  }
`;
