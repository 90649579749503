import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import {
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import Logs from './Logs';

const LogsWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES_MODULES_KEY?.LOGS}`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.LOGS}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <Logs {...props} />
        </AccessControl>
      )}
    />
  </Switch>
);

export default LogsWrapper;
