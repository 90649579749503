import { FilterFilled } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Card, DatePicker, Descriptions, Select, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { ACTIONS, LIMIT, MODULE_NAMES } from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import LogDescription from './components/LogDescription';
import { LOG_ENTRIES } from './graphql/Queries';
import './logs.less';

const { RangePicker } = DatePicker;

function Logs() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(LIMIT);

  const [filters, setFilters] = useState({
    skip: 0,
    limit: LIMIT,
    startDate: '',
    endDate: '',
    sortOrder: 'DESC',
    moduleName: null,
    actionType: null,
  });

  const [executeQuery, { data }] = useLazyQuery(LOG_ENTRIES, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    executeQuery({
      variables: {
        filters,
      },
    });
  }, [filters]);

  const onChange = (pagination) => {
    setCurrentPage(pagination?.current);
    setPageSize(pagination?.pageSize);
    setFilters({
      ...filters,
      skip: currentPage ? (pagination?.current - 1) * pagination?.pageSize : 0,
      limit: currentPage ? pagination?.pageSize : 50,
    });
  };

  const onRangeChange = (dates) => {
    if (dates) {
      setFilters({
        ...filters,
        startDate: dayjs(dates[0])?.format('YYYY-MM-DD'),
        endDate: dayjs(dates[1])?.format('YYYY-MM-DD'),
      });
    }
  };

  const handleModuleNameFilter = (value) => {
    setCurrentPage(1);
    setFilters({
      ...filters,
      skip: 0,
      moduleName: value,
    });
  };
  const handleActionFilter = (value) => {
    setCurrentPage(1);
    setFilters({
      ...filters,
      skip: 0,
      actionType: value,
    });
  };

  const getModuleFilterProps = () => ({
    filterSearch: true,
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <Select
            showSearch
            allowClear
            placeholder="Select Module"
            onChange={handleModuleNameFilter}
            filterOption={(inputValue, option) =>
              option?.value?.toLowerCase()?.includes(inputValue?.toLowerCase())
            }
            options={MODULE_NAMES}
            virtual={false}
          />
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterFilled
        className={filters?.moduleName ? 'filter-filled-data' : ''}
      />
    ),
  });

  const getActionFilterProps = () => ({
    filterSearch: true,
    filterDropdown: () => (
      <div className="columnFilter" onKeyDown={(e) => e?.stopPropagation()}>
        <Space direction="vertical">
          <Select
            showSearch
            allowClear
            placeholder="Select Action"
            onChange={handleActionFilter}
            filterOption={(inputValue, option) =>
              option?.value?.toLowerCase()?.includes(inputValue?.toLowerCase())
            }
            options={ACTIONS}
            virtual={false}
          />
        </Space>
      </div>
    ),
    filterIcon: () => (
      <FilterFilled
        className={filters?.actionType ? 'filter-filled-data' : ''}
      />
    ),
  });

  const columns = [
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sortDirections: ['descend'],
      sorter: true,
      render: (value) => dayjs(value)?.format('hh:mm:ss DD/MM/YYYY'),
    },
    {
      title: 'Action By',
      dataIndex: 'actionBy',
      key: 'actionBy',
      render: (value) => value?.displayName || value?.firstName,
    },
    {
      title: 'Module',
      dataIndex: 'moduleName',
      key: 'moduleName',
      ...getModuleFilterProps(),
    },
    {
      title: 'Action',
      dataIndex: 'actionType',
      key: 'actionType',
      ...getActionFilterProps(),
    },
  ];

  return (
    <>
      <div className="table-card-page">
        <Card
          title="Logs"
          className="ant-body-scroll"
          extra={
            <div className="project-filter-right">
              <div className="project-filter">
                <RangePicker onChange={onRangeChange} />
              </div>
            </div>
          }
        >
          <CommonTable
            size="small"
            columns={columns}
            dataSource={data?.eventLogsEntries?.logEntries}
            rowKey={(record) => record?.id}
            className="expandable-row-table logs-table"
            bordered
            onChange={onChange}
            paginationConfig={{
              current: currentPage,
              total: data?.eventLogsEntries?.count,
              pageSize,
            }}
            expandable={{
              expandedRowRender: (record) => (
                <div className="expandable-description">
                  <Descriptions bordered>
                    <Descriptions.Item span={8} label="Description:">
                      {record?.description || '-'}
                    </Descriptions.Item>
                    {Object?.keys(record?.newData)?.length && (
                      <Descriptions.Item span={2} label="New Data:">
                        <div>
                          {Object?.keys(record?.newData)?.map((item, index) => (
                            <div className="d-flex" key={`record-${index + 1}`}>
                              {item} :
                              <LogDescription
                                data={record?.newData?.[item]}
                                item={item}
                              />
                            </div>
                          ))}
                        </div>
                      </Descriptions.Item>
                    )}
                    {Object?.keys(record?.oldData)?.length && (
                      <Descriptions.Item span={2} label="Old Data:">
                        <div>
                          {Object?.keys(record?.oldData)?.map((item, index) => (
                            <div className="d-flex" key={`record-${index + 1}`}>
                              {item} :
                              <LogDescription
                                data={record?.oldData?.[item]}
                                item={item}
                              />
                            </div>
                          ))}
                        </div>
                      </Descriptions.Item>
                    )}
                  </Descriptions>
                </div>
              ),
              expandRowByClick: true,
            }}
          />
        </Card>
      </div>
    </>
  );
}

export default Logs;
