import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import MetaReports from './MetaReports';
import './metaReports.less';

const MetaReportsWrapper = () => (
  <Switch>
    <Route exact path={ROUTES?.META_REPORTS} component={MetaReports} />
  </Switch>
);

export default MetaReportsWrapper;
