import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessControl from '../../AccessControl';
import Error404 from '../../Error404';
import {
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES_MODULES_KEY,
} from '../../common/constants';
import Configuration from './Configuration';
import './configuration.less';

const ConfigurationWrapper = () => (
  <Switch>
    <Route
      exact
      path={`${ROUTES_MODULES_KEY?.CONFIGURATIONS}`}
      render={(props) => (
        <AccessControl
          modulekey={MODULES_KEY?.CONFIGURATIONS}
          allowedPermissions={[PERMISSION_OBJECT?.read]}
          showNoAccess
        >
          <Configuration {...props} />
        </AccessControl>
      )}
    />
    <Route path="*" component={Error404} />
  </Switch>
);

export default ConfigurationWrapper;
